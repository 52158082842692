import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { MoneyInput } from 'client/components/MoneyInput/MoneyInput';
import { getUnitPriceDeltaUnitTitle } from 'client/libraries/util/promotionTextHelpers';
import { Modal } from 'client/components/Modal/Modal';
import { FieldWrapper } from 'client/components/Form';
import { Radio } from 'client/components/v3/Form/Radio';
import type { UnitPriceDelta } from 'shared/models/swagger';
import styles from 'client/pages/v3/Product/ProductPromotions/ProductPromotions.module.css';

import { FloatInput } from './FloatInput';

type Props = {
  type: 'CHARGE' | 'DISCOUNT';
  currencyCode: string;
  unitPriceMethod: 'PER_BOOKING' | 'PER_PARTICIPANT' | 'ANY';
  guestTypeKeyCandidates: string[];
  label: string;
  value: UnitPriceDelta[];
  onChange: (arg0: UnitPriceDelta[]) => void;
};
export const UnitPriceDeltaListInput = ({
  type,
  currencyCode,
  unitPriceMethod,
  guestTypeKeyCandidates,
  label,
  value,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const [fixedOrPercent, setFixedOrPercent] = React.useState<
    'PERCENT' | 'FIXED'
  >('PERCENT');
  React.useEffect(() => {
    const nonZeroFixedValue = value.some(
      (unitPriceDelta) =>
        unitPriceDelta.delta_fixed_gross || unitPriceDelta.delta_fixed_net
    );
    const nonZeroPercentValue = value.some(
      (unitPriceDelta) => unitPriceDelta.delta_percent
    );

    // Don't overwrite "fixedOrPercent" when the both are zero value
    if (nonZeroPercentValue || nonZeroFixedValue) {
      setFixedOrPercent(nonZeroPercentValue ? 'PERCENT' : 'FIXED');
    }
  }, [value]);
  let unitPriceDeltaList = value;

  if (unitPriceMethod === 'PER_BOOKING' || unitPriceMethod === 'ANY') {
    if (
      !unitPriceDeltaList.find((u) => u.unit_price_method === unitPriceMethod)
    ) {
      unitPriceDeltaList = [
        {
          unit_price_method: unitPriceMethod,
          delta_type: type,
          delta_fixed_gross: '',
          delta_fixed_net: '',
          delta_percent: 0,
        },
      ];
    }
  } else {
    unitPriceDeltaList = guestTypeKeyCandidates.map((guestTypeKey) => {
      const existingDelta = unitPriceDeltaList.find(
        (u) =>
          u.unit_price_method === 'PER_PARTICIPANT' &&
          u.guest_type_key === guestTypeKey
      );

      if (existingDelta) {
        return existingDelta;
      } else {
        return {
          unit_price_method: 'PER_PARTICIPANT',
          guest_type_key: guestTypeKey,
          delta_type: type,
          delta_fixed_gross: '',
          delta_fixed_net: '',
          delta_percent: 0,
        };
      }
    });
  }

  return (
    <>
      {unitPriceMethod !== 'ANY' ? (
        <div className={styles['p-downloadModal__item']}>
          <div className={styles['p-downloadModal__item__ttl']}>{label}</div>
          <div className={styles['p-downloadModal__item__body']}>
            <div className={styles['p-downloadModal__item__body__check']}>
              <div>
                <Radio
                  name="delta-type-radio-group"
                  label={
                    type === 'CHARGE'
                      ? t('Percent Charge')
                      : t('Percent Discount')
                  }
                  checked={fixedOrPercent === 'PERCENT'}
                  onChange={() => {
                    setFixedOrPercent('PERCENT');
                    // zero-out fixed fields
                    onChange(
                      unitPriceDeltaList.map((u) => ({
                        ...u,
                        delta_fixed_gross: '',
                        delta_fixed_net: '',
                      }))
                    );
                  }}
                  size="sm"
                />
              </div>
              <div>
                <Radio
                  name="delta-type-radio-group"
                  label={
                    type === 'CHARGE' ? t('Fixed Charge') : t('Fixed Discount')
                  }
                  checked={fixedOrPercent === 'FIXED'}
                  onChange={() => {
                    setFixedOrPercent('FIXED');
                    // zero-out percent field
                    onChange(
                      unitPriceDeltaList.map((u) => ({
                        ...u,
                        delta_percent: 0,
                      }))
                    );
                  }}
                  size="sm"
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {unitPriceDeltaList.map((u: UnitPriceDelta, idx) => {
        const unitTitle = getUnitPriceDeltaUnitTitle(u, t);
        return (
          <>
            {fixedOrPercent === 'PERCENT' ? (
              <Modal.Box>
                <FloatInput
                  label={
                    unitTitle +
                    (type === 'CHARGE' ? t('(Gross/Net)') : t('(Gross/Net)'))
                  }
                  value={u.delta_percent || 0}
                  onChange={(newPercent) => {
                    if (0 <= newPercent && newPercent <= 100) {
                      onChange(
                        unitPriceDeltaList.map((u2, idx2) =>
                          idx === idx2
                            ? { ...u, delta_percent: newPercent }
                            : u2
                        )
                      );
                    }
                  }}
                />
              </Modal.Box>
            ) : (
              <Modal.Box column="two">
                <FieldWrapper
                  label={
                    unitTitle +
                    (type === 'CHARGE' ? t('(Gross)') : t('(Gross)'))
                  }
                >
                  <MoneyInput
                    currencyCode={currencyCode}
                    moneyAmount={u.delta_fixed_gross || ''}
                    onChange={(newValue) =>
                      onChange(
                        unitPriceDeltaList.map((u2, idx2) =>
                          idx === idx2
                            ? { ...u, delta_fixed_gross: newValue }
                            : u2
                        )
                      )
                    }
                  />
                </FieldWrapper>

                <FieldWrapper
                  label={
                    unitTitle + (type === 'CHARGE' ? t('(Net)') : t('(Net)'))
                  }
                >
                  <MoneyInput
                    currencyCode={currencyCode}
                    moneyAmount={u.delta_fixed_net || ''}
                    onChange={(newValue) =>
                      onChange(
                        unitPriceDeltaList.map((u2, idx2) =>
                          idx === idx2
                            ? { ...u, delta_fixed_net: newValue }
                            : u2
                        )
                      )
                    }
                  />
                </FieldWrapper>
              </Modal.Box>
            )}
          </>
        );
      })}
    </>
  );
};
