import * as React from 'react';

import * as Swagger from 'shared/models/swagger';

import { PageViewEvent } from './PageViewEvent';
import { ConversionEvent } from './ConversionEvent';
import { ScrollEvent } from './ScrollEvent';
import { CheckAvailabilityEvent } from './CheckAvailabilityEvent';
import { AddToCartEvent } from './AddToCartEvent';
import { ETicketViewEvent } from './ETicketViewEvent';
import { ETicketRedeemEvent } from './ETicketRedeemEvent';
import { ETicketFootprintEvent } from './ETicketFootprintEvent';
import { WebsitePopupViewEvent } from './WebsitePopupViewEvent';
import { WebsitePopupClickEvent } from './WebsitePopupClickEvent';
import { EmailOpenEvent } from './EmailOpenEvent';
import { EmailClickEvent } from './EmailClickEvent';
import { PushNotificationViewEvent } from './PushNotificationViewEvent';
import { PushNotificationClickEvent } from './PushNotificationClickEvent';
import { LocationEvent } from './LocationEvent';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const Event = ({ event, durationInSeconds }: Props) => {
  switch (event.event_type) {
    case 'page-view':
    case 'pin-view':
    case 'pin-details':
      return (
        <PageViewEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'location':
      return (
        <LocationEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'reservation-thank-you':
      return (
        <ConversionEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'check-availability':
      return (
        <CheckAvailabilityEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'begin-checkout':
      return (
        <AddToCartEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'eticket-view':
      return (
        <ETicketViewEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'eticket-redeem':
      return (
        <ETicketRedeemEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'eticket-footprint':
      return (
        <ETicketFootprintEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'website-popup-view':
      return (
        <WebsitePopupViewEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'website-popup-click':
      return (
        <WebsitePopupClickEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'email-open':
      return (
        <EmailOpenEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'email-click':
      return (
        <EmailClickEvent event={event} durationInSeconds={durationInSeconds} />
      );
    case 'push-notification-view':
      return (
        <PushNotificationViewEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    case 'push-notification-click':
      return (
        <PushNotificationClickEvent
          event={event}
          durationInSeconds={durationInSeconds}
        />
      );
    default:
      if (event.event_type?.startsWith('scroll')) {
        return (
          <ScrollEvent event={event} durationInSeconds={durationInSeconds} />
        );
      }
      return null;
  }
};
