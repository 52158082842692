import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import type { Moment } from 'moment-timezone';
import _ from 'lodash';

import { Select, Input, DateInput, Button } from 'client/components/Form';
import { ModalLoader } from 'client/components/ModalLoader';
import { TimePicker } from 'client/components/TimePicker/TimePicker';
import { Box } from 'client/components/Box/Box';
import {
  getDisplayProductName,
  getVerboseDisplayProductName,
} from 'client/libraries/util/getDisplayProductName';
import type {
  Product,
  ProductInstance,
  ProductSummary,
} from 'shared/models/swagger';
import styles from 'client/components/CustomCalendar/CustomCalendar.module.css';
import baseStyles from 'client/base.module.css';
import stopIcon from 'client/images/ic_stop.svg';
import rqIcon from 'client/images/ic_rq.svg';

export type CustomCalendarEvent = {
  id: string;
  time: string;
  title: string;
  resource: ProductInstance;
  style: Record<string, any>;
};
type Props = {
  defaultDate?: Moment;
  productSelectorIsSearchable?: boolean;
  productSelectorWithDisabled?: boolean;
  title?: string;
  onClose: (arg0: void) => void;
  events: null | CustomCalendarEvent[];
  timezone: string;
  locale: string;
  product: null | Product;
  onRangeChange: (arg0: Record<string, any>) => void;
  onEventClick?: (arg0: ProductInstance) => void;
  loading?: boolean;
  showProductSelector?: boolean;
  products?: ProductSummary[];
  onChangeProduct?: (arg0: string) => void;
};
export const CustomCalendar = ({
  defaultDate,
  productSelectorIsSearchable,
  productSelectorWithDisabled,
  title,
  onClose,
  events,
  timezone,
  locale,
  product,
  onRangeChange,
  onEventClick,
  loading,
  showProductSelector,
  products,
  onChangeProduct,
}: Props) => {
  const [productId, setProductId] = React.useState<string>(
    product ? product.id : ''
  );
  const [disableProductInput, setDisableProductInput] =
    React.useState<boolean>(true);
  const [movedMonth, setMovedMonth] = React.useState<number>(() => {
    // If defaultDate props is provided, display calendar range based on the value.
    // If defaultDate props is not provided, display calendar range based on product's today date.
    let val: number;

    if (!defaultDate) {
      val = 0;
    } else {
      const today = moment.tz(timezone).locale(locale);
      const target = moment(defaultDate.format());
      const yToday = today.get('year');
      const yTarget = target.get('year');
      // At Moment.js, get('month') start from 0 (Jan) ~ 11 (Dec).
      const mToday = today.get('month');
      const mTarget = target.get('month');

      if (yTarget < yToday) {
        // Target date has passed.
        val = 0;
      } else {
        // Target year is greater or equal to current year.
        val = 12 * (yTarget - yToday) + mTarget - mToday;
      }
    }

    return val;
  });
  const [openDate, setOpenDate] = React.useState<number>(-1);
  const numOfList = 3;
  const { t } = useTranslation();
  const rootEl = document.getElementById('root');

  const [freeFormatProductName, setFreeFormatProductName] =
    React.useState<string>('');
  const [freeFormatStartDate, setFreeFormatStartDate] =
    React.useState<string>('');
  const [freeFormatStartTime, setFreeFormatStartTime] =
    React.useState<string>('00:00');

  const getDaysOfTheMonth = (
    month: number,
    year: number,
    timezone: string
  ): Moment[] => {
    const date = moment.tz(
      {
        year: year,
        month: month,
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      },
      timezone
    );
    let days = [];
    const before = moment(date);

    for (let i = before.day(); i > 0; i--) {
      days.push(moment(before.add('-1', 'day')));
    }

    days = days.reverse();

    while (date.month() === month) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    while (date.day() !== 0) {
      days.push(moment(date));
      date.add('1', 'day');
    }

    return days;
  };

  React.useEffect(() => {
    const today = moment.tz(timezone).locale(locale);
    const target = today.add(`${movedMonth}`, 'month');
    onRangeChange({
      start: moment(target).startOf('month').add(-1, 'weeks'),
      end: moment(target).endOf('month').add(1, 'weeks'),
    });
  }, [movedMonth]);
  // Enable Product input when `products` is ready, if it has disabled.
  React.useEffect(() => {
    if (products && products.length > 0) {
      setDisableProductInput(false);
    }
  }, [products]);
  const today = moment.tz(timezone).locale(locale);
  const target = moment(today).add(`${movedMonth}`, 'month');
  const days = getDaysOfTheMonth(target.month(), target.year(), timezone);
  // Set modal height as dynamic to narrow down its height,
  // when the product selector is showing but calendar is not ready to display yet (no product is being selected from the product drop-down menu).
  const isDynamicHeight =
    productSelectorWithDisabled && !productId ? true : false;

  const getProductOptions = () => {
    let options: { text: string; value: string; key: string }[] = [];

    if (products) {
      options = [
        ...products.map((p) => ({
          value: p.id ?? '',
          key: p.id ?? '',
          text: getVerboseDisplayProductName(p),
        })),
      ];
    }

    options.push({
      text: t('Create a free format reservation'),
      value: 'FREE_FORMAT',
      key: 'FREE_FORMAT',
    });
    return options;
  };

  const productName = getDisplayProductName(product);

  return ReactDOM.createPortal(
    <div className={clsx(styles['c-modalCalendar'])}>
      <div
        className={clsx(
          styles['c-modalCalendar__frame'],
          showProductSelector ? styles['product_selector'] : null
        )}
        style={
          isDynamicHeight
            ? {
                height: 'auto',
                minHeight: 'auto',
              }
            : {}
        }
      >
        <div className={clsx(styles['c-modalCalendar__frame__header'])}>
          <p className={clsx(styles['c-modalCalendar__frame__header__ttl'])}>
            {showProductSelector
              ? title
                ? title
                : t('Select new product, date and time')
              : productName}
          </p>
          <a
            className={clsx(styles['c-modalCalendar__frame__header__close'])}
            onClick={onClose as any}
          ></a>
        </div>
        <div className={clsx(styles['c-modalCalendar__frame__body'])}>
          {showProductSelector && (
            <>
              <Select
                label={t('Product')}
                placeholder={
                  productSelectorIsSearchable ? t('Select a product') : ''
                }
                disabled={
                  productSelectorWithDisabled ? disableProductInput : false
                }
                value={productId}
                options={getProductOptions()}
                onChange={(e, { value }) => {
                  setProductId(value);

                  if (onChangeProduct) {
                    onChangeProduct(value);
                  }
                }}
                search={productSelectorIsSearchable ?? false}
                allowFreeInput={productSelectorIsSearchable ?? false}
              />
              {productId && productId !== 'FREE_FORMAT' && (
                <div className={baseStyles['base-form-box__header']}>
                  {t('Availability')}
                </div>
              )}
            </>
          )}

          {/* Calendar range selection start */}
          {productId && productId !== 'FREE_FORMAT' && (
            <div className={clsx(styles['c-modalCalendar__frame__body__top'])}>
              <div className={clsx(styles['c-modalCalendar__datepick'])}>
                <a
                  className={clsx(
                    styles['c-modalCalendar__datepick__btn'],
                    styles['prev']
                  )}
                  onClick={() => {
                    setMovedMonth(movedMonth - 1);
                  }}
                ></a>
                <p className={clsx(styles['c-modalCalendar__datepick__main'])}>
                  {target.format('YYYY/MM')}
                </p>
                <a
                  className={clsx(
                    styles['c-modalCalendar__datepick__btn'],
                    styles['next']
                  )}
                  onClick={() => {
                    setMovedMonth(movedMonth + 1);
                  }}
                ></a>
              </div>
              <div className={clsx(styles['c-modalCalendar__info'])}>
                <a
                  className={clsx(styles['c-modalCalendar__info__today'])}
                  onClick={() => {
                    setMovedMonth(0);
                  }}
                >
                  Today
                </a>
                <p className={clsx(styles['c-modalCalendar__info__timezone'])}>
                  {t('Timezone: {{tz}}', {
                    tz: timezone,
                  })}
                </p>
              </div>
            </div>
          )}
          {/* Calendar range selection end */}

          {productId &&
          productId !== 'FREE_FORMAT' &&
          (loading || events === null) ? (
            <ModalLoader />
          ) : productId &&
            productId !== 'FREE_FORMAT' &&
            loading == false &&
            events !== null ? ( // Show calendar
            <div className={clsx(styles['c-modalCalendar__frame__body__main'])}>
              <div className={clsx(styles['c-calendar'])}>
                <table>
                  <tbody>
                    <tr>
                      <th>{t('Sun')}</th>
                      <th>{t('Mon')}</th>
                      <th>{t('Tue')}</th>
                      <th>{t('Wed')}</th>
                      <th>{t('Thu')}</th>
                      <th>{t('Fri')}</th>
                      <th>{t('Sat')}</th>
                    </tr>

                    {_.chunk(days, 7).map((week, widx) => {
                      return (
                        <tr key={widx}>
                          {week.map((day, didx) => {
                            const eventOfTheDay = events.filter((e) => {
                              const startTime = moment(
                                e.resource.start_date_time_utc
                              ).tz(timezone);

                              if (
                                day.year() === startTime.year() &&
                                day.month() === startTime.month() &&
                                day.date() === startTime.date()
                              ) {
                                return true;
                              }

                              return false;
                            });
                            return (
                              <td
                                key={didx}
                                className={clsx(
                                  target.month() === day.month()
                                    ? ''
                                    : styles['disabled']
                                )}
                              >
                                <p
                                  className={clsx(
                                    styles['c-calendar__day'],
                                    day.year() === today.year() &&
                                      day.month() === today.month() &&
                                      day.date() === today.date()
                                      ? styles['today']
                                      : ''
                                  )}
                                  style={{
                                    margin: '0 auto',
                                  }}
                                >
                                  {day.date()}
                                </p>
                                <ul
                                  className={clsx(styles['c-calendar__scheds'])}
                                >
                                  {eventOfTheDay
                                    .filter((e, sid) => {
                                      return sid < numOfList;
                                    })
                                    .map((e, sidx) => {
                                      return (
                                        <li
                                          className={clsx(
                                            styles[
                                              e.style
                                                ?.backgroundColor as keyof typeof styles
                                            ],
                                            styles[
                                              `front__${e.style?.frontColor}` as keyof typeof styles
                                            ]
                                          )}
                                          key={sidx}
                                          onClick={() => {
                                            onEventClick &&
                                              onEventClick(e.resource);
                                          }}
                                        >
                                          <p>{e.time}</p>
                                          {e.style?.iconType &&
                                            e.style.iconType !== 'NO_ICON' &&
                                            (e.style?.iconType === 'STOP' ? (
                                              <img src={stopIcon} />
                                            ) : (
                                              <img src={rqIcon} />
                                            ))}
                                          <b>{e.title}</b>
                                        </li>
                                      );
                                    })}
                                  {eventOfTheDay.length > numOfList && (
                                    <li
                                      className={clsx(
                                        styles['more'],
                                        openDate === widx * 7 + didx
                                          ? styles['selected']
                                          : ''
                                      )}
                                      onClick={() => {
                                        setOpenDate(widx * 7 + didx);
                                      }}
                                    >
                                      <p>
                                        {t('More {{count}}', {
                                          count: String(
                                            eventOfTheDay.length - numOfList
                                          ),
                                        })}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                                {eventOfTheDay.length >= numOfList && (
                                  <div
                                    className={clsx(
                                      styles['c-calendar__allScheds'],
                                      openDate === widx * 7 + didx
                                        ? styles['is-open']
                                        : ''
                                    )}
                                  >
                                    <div
                                      className={clsx(
                                        styles['c-calendar__allScheds__header']
                                      )}
                                    >
                                      <p
                                        className={clsx(
                                          styles[
                                            'c-calendar__allScheds__header__ttl'
                                          ]
                                        )}
                                      >
                                        {' '}
                                        {day.format('l')}
                                      </p>
                                      <a
                                        className={clsx(
                                          styles[
                                            'c-calendar__allScheds__header__close'
                                          ]
                                        )}
                                        onClick={() => {
                                          setOpenDate(-1);
                                        }}
                                      ></a>
                                    </div>
                                    <div
                                      className={clsx(
                                        styles['c-calendar__allScheds__body']
                                      )}
                                    >
                                      <ul>
                                        {eventOfTheDay.map((e, sidx) => {
                                          return (
                                            <li
                                              className={clsx(
                                                styles[
                                                  e.style
                                                    ?.backgroundColor as keyof typeof styles
                                                ]
                                              )}
                                              key={sidx}
                                              onClick={() => {
                                                onEventClick &&
                                                  onEventClick(e.resource);
                                              }}
                                            >
                                              <p>{e.time}:</p>
                                              {e.style?.iconType &&
                                                e.style.iconType !==
                                                  'NO_ICON' &&
                                                (e.style?.iconType ===
                                                'STOP' ? (
                                                  <img src={stopIcon} />
                                                ) : (
                                                  <img src={rqIcon} />
                                                ))}
                                              <p>
                                                <b>{e.title}</b>
                                              </p>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div> // Hide calendar
          ) : (
            <></>
          )}
          {productId && productId === 'FREE_FORMAT' && (
            <>
              <Input
                label={t('Product Name for Free Format Reservation')}
                value={freeFormatProductName}
                onChange={(e, { value }) => setFreeFormatProductName(value)}
                required={true}
              />
              <Box display={'flex'} mb={3}>
                <div style={{ width: '30%' }}>
                  <div className={baseStyles['base-form-box__header']}>
                    {t('Participation Date')}
                  </div>
                  <DateInput
                    value={freeFormatStartDate}
                    onChange={(e: any) => {
                      setFreeFormatStartDate(e.target.value);
                    }}
                    required={true}
                  />
                </div>
                <div style={{ width: '30%' }}>
                  <div className={baseStyles['base-form-box__header']}>
                    {t('Start Time')}
                  </div>
                  <TimePicker
                    allowEmpty={false}
                    value={moment(freeFormatStartTime, 'HH:mm')}
                    onChange={(newMoment) => {
                      if (!newMoment) {
                        return;
                      }

                      setFreeFormatStartTime(newMoment.format('HH:mm'));
                    }}
                  />
                </div>
              </Box>
              <div>
                <Link
                  to={{
                    pathname: `/freeformat/book`,
                    state: {
                      productName: freeFormatProductName,
                      participationDate: freeFormatStartDate,
                      startTime: freeFormatStartTime,
                    },
                  }}
                >
                  <Button
                    style="green"
                    size="middle"
                    disabled={
                      freeFormatProductName == '' || freeFormatStartDate == ''
                    }
                    onClick={() => onClose?.()}
                  >
                    {t('Create new reservation')}
                  </Button>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={clsx(styles['c-modalCalendar__overlay'])}
        onClick={onClose as any}
      ></div>
    </div>,
    rootEl as any
  );
};
