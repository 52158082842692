import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import { fetchProducts } from 'client/actions/products';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import {
  uppercaseIsoToLowercaseIso,
  getLanguageName,
} from 'client/libraries/i18n';
import { ProductGroupCalendarList } from 'client/pages/v3/BookingWidget/WebSiteIntegration/Tab/EmbedCalendar/ProductGroupCalendarList';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';
//import { BookingWidgetSingleProductBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetSingleProductBookNowButton';
//import { BookingWidgetMultiProductBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetMultiProductBookNowButton';
//import { BookingWidgetProductGroupBookNowButton } from 'client/pages/BookingWidget/BookingWidgetWebSiteIntegration/BookNowButton/BookingWidgetProductGroupBookNowButton';

import { BookingWidgetEmbeddedSingleProductCalendar } from './EmbedCalendar/BookingWidgetEmbeddedSingleProductCalendar';
import { BookingWidgetEmbeddedMultiProductCalendar } from './EmbedCalendar/BookingWidgetEmbeddedMultiProductCalendar';
import { BookingWidgetEmbeddedProductGroupCalendar } from './EmbedCalendar/BookingWidgetEmbeddedProductGroupCalendar';

export const EmbedCalendarTab = () => {
  const [selectedProductId, setSelectedProductId] = React.useState<string>('');
  const [widgetLanguage, setWidgetLanguage] = React.useState<string>('ja-JP');
  const [calendarType, setCalendarType] = React.useState<
    | 'SINGLE_PRODUCT_CALENDAR'
    | 'MULTI_PRODUCT_CALENDAR'
    | 'PRODUCT_GROUP_CALENDAR'
  >('SINGLE_PRODUCT_CALENDAR');
  const [embedType, setEmbedType] = React.useState<
    'CALENDAR' | 'BOOK_NOW_BUTTON'
  >('CALENDAR');
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const productOptions = useSelector(productOptionsSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const organization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (organization) {
      dispatch(fetchProducts());
    }
  }, [locale, organization]);

  const languageOptions = (
    (organization && organization.supported_languages) ||
    []
  ).map((language) => ({
    text: getLanguageName(uppercaseIsoToLowercaseIso[language], t),
    value: uppercaseIsoToLowercaseIso[language],
    key: uppercaseIsoToLowercaseIso[language],
  }));

  const bookingWidgetApiKey =
    (organization && organization.booking_widget_api_key) ?? '';

  if (!bookingWidgetApiKey) {
    return (
      <Message warning>
        {t(
          'Booking widget not currently configured. Please contact sales@nutmeglabs.com if interested.'
        )}
      </Message>
    );
  }

  return (
    <div
      className={clsx(
        styles['p-inquiry-tabs__tab-panel'],
        styles['components_is-active__B15DT']
      )}
      style={{ display: 'block' }}
    >
      <div className={styles['p-inquiry-tabs__content']}>
        <ul className={styles['p-inquiry-list']}>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Select language')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                {languageOptions.length > 0 ? (
                  <SingleDropdown
                    selectedOption={widgetLanguage}
                    options={languageOptions}
                    onChange={(value) => {
                      setWidgetLanguage(value);
                    }}
                  />
                ) : (
                  <Message warning>
                    {t(
                      'No supported languages are configured for your organization. Please contact sales@nutmeglabs.com.'
                    )}
                  </Message>
                )}
              </div>
            </div>
          </li>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Calendar Type')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                <SingleDropdown
                  selectedOption={calendarType}
                  options={[
                    {
                      value: 'SINGLE_PRODUCT_CALENDAR',
                      text: t('Single Product Calendar'),
                    },
                    {
                      value: 'MULTI_PRODUCT_CALENDAR',
                      text: t('All Products Calendar'),
                    },
                    {
                      value: 'PRODUCT_GROUP_CALENDAR',
                      text: t('Product Group'),
                    },
                  ]}
                  onChange={(value) => {
                    setCalendarType(value as any);
                  }}
                />
              </div>
              {calendarType === 'PRODUCT_GROUP_CALENDAR' && (
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <ProductGroupCalendarList />
                </div>
              )}
              {calendarType === 'SINGLE_PRODUCT_CALENDAR' && (
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <SingleDropdown
                    label={t('Select a product')}
                    selectedOption={selectedProductId}
                    options={productOptions}
                    onChange={(value) => {
                      setSelectedProductId(value);
                    }}
                  />
                </div>
              )}
            </div>
          </li>
          <li className={styles['p-inquiry-list__item']}>
            <div className={styles['p-inquiry-list__item__ttl']}>
              <div className={styles['p-inquiry-list__item__ttl__txt']}>
                <div>{t('Embed Type')}</div>
              </div>
            </div>
            <div className={styles['p-inquiry-list__item__body']}>
              <div className={styles['p-inquiry-list__item__body__item']}>
                <SingleDropdown
                  selectedOption={embedType}
                  options={[
                    {
                      value: 'CALENDAR',
                      text: t('Calendar'),
                    },
                    {
                      value: 'BOOK_NOW_BUTTON',
                      text: t('"Book Now" Button'),
                    },
                  ]}
                  onChange={(value) => {
                    setEmbedType(value as any);
                  }}
                />
              </div>
              {calendarType === 'SINGLE_PRODUCT_CALENDAR' &&
                embedType === 'CALENDAR' &&
                selectedProductId && (
                  <BookingWidgetEmbeddedSingleProductCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    selectedProductId={selectedProductId}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              {calendarType === 'MULTI_PRODUCT_CALENDAR' &&
                embedType === 'CALENDAR' && (
                  <BookingWidgetEmbeddedMultiProductCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              {calendarType === 'PRODUCT_GROUP_CALENDAR' &&
                embedType === 'CALENDAR' && (
                  <BookingWidgetEmbeddedProductGroupCalendar
                    bookingWidgetApiKey={bookingWidgetApiKey}
                    widgetLanguage={widgetLanguage}
                  />
                )}
              <div className={styles['p-inquiry-list__item__body__item']}>
                <div className={styles['p-bookingWidgetCustomize-box']}>
                  <div className={styles['p-bookingWidgetCustomize-box__list']}>
                    <div
                      className={
                        styles['p-bookingWidgetCustomize-box__list__item']
                      }
                    >
                      <dl
                        className={
                          styles[
                            'p-bookingWidgetCustomize-products__section__list'
                          ]
                        }
                      >
                        <dt
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__term'
                            ]
                          }
                        >
                          <span>ボタンのラベルテキスト</span>
                        </dt>
                        <dd
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__desc'
                            ]
                          }
                        >
                          {/*
                          <TextField
                          />
                          */}
                        </dd>
                      </dl>
                    </div>
                    <div
                      className={
                        styles['p-bookingWidgetCustomize-box__list__item']
                      }
                    >
                      <dl
                        className={
                          styles[
                            'p-bookingWidgetCustomize-products__section__list'
                          ]
                        }
                      >
                        <dt
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__term'
                            ]
                          }
                        >
                          <span>ラベルテキストの色</span>
                        </dt>
                        <dd
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__desc'
                            ]
                          }
                        >
                          <a
                            className={
                              styles[
                                'p-bookingWidgetCustomize-products-list__color'
                              ]
                            }
                            style={{ width: '116px' }}
                            href=""
                          >
                            <div
                              className={
                                styles[
                                  'p-bookingWidgetCustomize-products-list__color-box'
                                ]
                              }
                              style={{ backgroundColor: '#3B86C1' }}
                            ></div>
                            <span>
                              <i></i>
                              変更
                            </span>
                          </a>
                        </dd>
                      </dl>
                    </div>
                    <div
                      className={
                        styles['p-bookingWidgetCustomize-box__list__item']
                      }
                    >
                      <dl
                        className={
                          styles[
                            'p-bookingWidgetCustomize-products__section__list'
                          ]
                        }
                      >
                        <dt
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__term'
                            ]
                          }
                        >
                          <span>プレビュー</span>
                        </dt>
                        <dd
                          className={
                            styles[
                              'p-bookingWidgetCustomize-products__section__list__desc'
                            ]
                          }
                        >
                          <div
                            className={
                              styles['p-bookingWidgetCustomize-box-wh']
                            }
                          >
                            <a
                              className={clsx(
                                styles['p-previewBtn'],
                                styles['narrow']
                              )}
                              style={{
                                backgroundColor: '#3B86C1',
                                color: '#fff',
                              }}
                            >
                              予約する
                            </a>
                          </div>
                        </dd>
                      </dl>
                    </div>
                    <div
                      className={
                        styles['p-bookingWidgetCustomize-box__list__item']
                      }
                    >
                      <a
                        className={styles['c-button-bg-md-white']}
                        style={{ fontWeight: 'var(--text-semibold)' }}
                      >
                        <i
                          className={styles['c-icon-outline-general-copy-02']}
                        ></i>
                        <p>埋め込みコードをコピー</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};
