import * as React from 'react';
import { Field, useFormState, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import { SimpleDateInput } from 'client/components/v3/Form/Calendar/SimpleDateInput';
import { SingleImageInput } from 'client/components/v3/SingleImageInput/SingleImageInput';
import type { ReduxState } from 'client/reducers';
import { customPagesSelector } from 'client/reducers/customPages';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { getLanguageName } from 'client/libraries/i18n';
import {
  bookingWidgetPMPSupportedLanguagesSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { getPopupMessageFormValues } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import type { CustomizeFormValues } from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/utils';
import { SourceLanguage } from 'shared/models/swagger';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetCustomize/BookingWidgetCustomize.module.css';

const encodeCategoryNameForURI = (categoryName: string): string => {
  // We need to replace '%' with '%25' since next.js does not escape '%'
  return encodeURIComponent(categoryName.replace('%', '%25'));
};
export const PopUpMessageEditor = () => {
  const { t } = useTranslation();
  //const dispatch = useDispatch();
  const { required } = getValidators(t);
  const customPages = useSelector(customPagesSelector);
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const supportedLanguages = useSelector(
    bookingWidgetPMPSupportedLanguagesSelector
  );
  const products = useSelector((state: ReduxState) => state.products.summaries);

  const parentFormState = useFormState();
  const parentForm = useForm<CustomizeFormValues>();
  //const parentForm = useForm();

  const initialValuesFromForm = _.get(parentFormState.values, 'popupMessage');

  const languageOptions = supportedLanguages.map((lang) => ({
    value: lang,
    label: getLanguageName(lang, t),
  }));

  //const popupMessage = parentForm.getState().values?.popupMessage;

  //const parentFormSavePopupMessage = (newPopupMessage: PopupMessage) => {
  //  parentForm.change('popupMessage', newPopupMessage);
  //};

  const initialValues: CustomizeFormValues['popupMessage'] =
    initialValuesFromForm
      ? initialValuesFromForm
      : {
          key: uuidv4(),
          text: '',
          url: '',
          displayType: 'ALL_PAGES',
          displayStartDate: '',
          displayEndDate: '',
          imageUrl: '',
          title: '',
          description: '',
          buttonText: '',
          buttonDestinationType: 'CUSTOM_PAGE',
          buttonDestinationUrl: '',
          buttonDestinationPagePath: '/about',
        };
  const displayTypeOptions = [
    {
      key: 'ALL_PAGES',
      value: 'ALL_PAGES',
      label: t('All pages in booking site'),
    },
    {
      key: 'TOP_PAGE_ONLY',
      value: 'TOP_PAGE_ONLY',
      label: t('TOP page'),
    },
  ];
  const buttonDestinationTypeOptions = [
    {
      key: 'CUSTOM_PAGE',
      value: 'CUSTOM_PAGE',
      label: t('Select a page from Homepages'),
    },
    {
      key: 'PRODUCT_PAGE',
      value: 'PRODUCT_PAGE',
      label: t('Select a product'),
    },
    {
      key: 'FULL_URL',
      value: 'FULL_URL',
      label: t('Enter URL directly'),
    },
  ];
  const pmp = activeUserOrganization?.private_marketplaces?.find(
    (pmp) => pmp.language === parentFormState.values?.popupMessageLanguage
  );

  if (pmp) {
    buttonDestinationTypeOptions.push({
      key: 'PRIVATE_MARKETPLACE',
      value: 'PRIVATE_MARKETPLACE',
      label: t('Select a Private Marketplace page'),
    });
  }

  const customPageOptions = [
    {
      text: t('About Us'),
      key: '/about',
      value: '/about',
    },
    {
      text: t('FAQ'),
      key: '/faq',
      value: '/faq',
    },
    {
      text: t('COVID-19 Guidelines'),
      key: '/covid19',
      value: '/covid19',
    },
    ...customPages.map((customPage) => ({
      text: customPage.title,
      key: `/article/${customPage.path}`,
      value: `/article/${customPage.path}`,
    })),
  ];
  const productOptions = products.map((product) => ({
    key: pmp ? `/top/products/${product.id}` : `/products/${product.id}`,
    value: pmp ? `/top/products/${product.id}` : `/products/${product.id}`,
    text: product.product_name ?? '',
  }));
  const pmpOptions = [
    {
      key: '/top/popular',
      value: '/top/popular',
      text: t('Ranking'),
    },
    {
      key: '/top/recommended',
      value: '/top/recommended',
      text: t('Recommended'),
    },
  ];
  pmp?.category_pages?.forEach((categoryPage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(categoryPage.name ?? '')}`,
      text: categoryPage.display_name || categoryPage.name || '',
    })
  );
  pmp?.feature_pages?.forEach((featurePage) =>
    pmpOptions.push({
      key: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      value: `/top/${encodeCategoryNameForURI(featurePage.name ?? '')}`,
      text: featurePage.display_name || featurePage.name || '',
    })
  );

  const language = parentFormState.values?.popupMessageLanguage || 'JA_JP';
  return (
    <>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <dl
          className={styles['p-bookingWidgetCustomize-products__section__list']}
        >
          <dt
            className={
              styles['p-bookingWidgetCustomize-products__section__list__term']
            }
          >
            <span>{t('Pop-up message')}</span>
            <span>{t('Content Language')}</span>
          </dt>
          <EnumRadioButtonGroup
            name="popupMessageLanguage"
            options={languageOptions}
            defaultChecked={language}
            onChange={(value) => {
              parentForm.change(
                'popupMessage',
                getPopupMessageFormValues(
                  activeUserOrganization,
                  value as SourceLanguage
                )
              );
            }}
          />
        </dl>
      </div>
      <div
        className={
          styles['p-bookingWidgetCustomize-products__section__body__item']
        }
      >
        <div className={styles['p-bookingWidgetCustomize-box']}>
          <div className={styles['p-bookingWidgetCustomize-box__list']}>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Display Period')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field
                    name="popupMessage.displayStartDate"
                    validate={required}
                  >
                    {({ input }) => (
                      <>
                        <SimpleDateInput dateFrom={input.value} {...input} />
                      </>
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Display Period')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field name="popupMessage.displayEndDate" validate={required}>
                    {({ input }) => (
                      <>
                        <SimpleDateInput dateFrom={input.value} {...input} />
                      </>
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Target Page')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <EnumRadioButtonGroup
                    name="popupMessage.displayType"
                    options={displayTypeOptions}
                  />
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Title')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field name="popupMessage.title" validate={required}>
                    {({ input, meta: { touched, error } }) => (
                      <TextField
                        label={t('Title')}
                        error={touched && error}
                        {...input}
                      />
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Photo')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field name="popupMessage.imageUrl">
                    {({ input }) => (
                      <SingleImageInput
                        fileTypes={['image/jpeg', 'image/png']}
                        onUploadFinished={(_, objectUrl) =>
                          input.onChange(objectUrl)
                        }
                        initialValue={input.value}
                        saveFilePath={`${activeUserOrganization?.id}/tut`}
                      />
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Message')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field name="popupMessage.description">
                    {({ input, meta: { touched, error } }) => (
                      <TextField
                        error={touched && error}
                        value={input.value}
                        onChange={input.onChange}
                      />
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('Text on Button')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <Field name="popupMessage.buttonText">
                    {({ input, meta: { touched, error } }) => (
                      <TextField
                        error={touched && error}
                        placeholder={t('Text on Button')}
                        {...input}
                      />
                    )}
                  </Field>
                </dd>
              </dl>
            </div>
            <div className={styles['p-bookingWidgetCustomize-box__list__item']}>
              <dl
                className={
                  styles['p-bookingWidgetCustomize-products__section__list']
                }
              >
                <dt
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__term'
                    ]
                  }
                >
                  <span>{t('URL on button click')}</span>
                </dt>
                <dd
                  className={
                    styles[
                      'p-bookingWidgetCustomize-products__section__list__desc'
                    ]
                  }
                >
                  <EnumRadioButtonGroup
                    name="popupMessage.buttonDestinationType"
                    options={buttonDestinationTypeOptions}
                    onChange={(value) => {
                      if (value === 'FULL_URL') {
                        parentForm.change(
                          'popupMessage.buttonDestinationPagePath' as any,
                          ''
                        );
                        parentForm.change(
                          'popupMessage.buttonDestinationUrl' as any,
                          ''
                        );
                      } else if (value === 'PRIVATE_MARKETPLACE') {
                        parentForm.change(
                          'popupMessage.buttonDestinationPagePath' as any,
                          '/top/popular'
                        );
                        parentForm.change(
                          'popupMessage.buttonDestinationUrl' as any,
                          ''
                        );
                      } else if (value === 'CUSTOM_PAGE') {
                        parentForm.change(
                          'popupMessage.buttonDestinationPagePath' as any,
                          '/about'
                        );
                        parentForm.change(
                          'popupMessage.buttonDestinationUrl' as any,
                          ''
                        );
                      } else if (value === 'PRODUCT_PAGE') {
                        parentForm.change(
                          'popupMessage.buttonDestinationPagePath' as any,
                          productOptions.length > 0
                            ? productOptions[0].value
                            : ''
                        );
                        parentForm.change(
                          'popupMessage.buttonDestinationUrl' as any,
                          ''
                        );
                      }
                    }}
                  />
                  {initialValues?.buttonDestinationType === 'CUSTOM_PAGE' && (
                    <Field name="popupMessage.buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <SingleDropdown
                          options={customPageOptions}
                          error={touched && error}
                          selectedOption={input.value}
                          onChange={(value) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  )}
                  {initialValues?.buttonDestinationType ===
                    'PRIVATE_MARKETPLACE' && (
                    <Field name="popupMessage.buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <SingleDropdown
                          options={pmpOptions}
                          error={touched && error}
                          selectedOption={input.value}
                          onChange={(value) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  )}
                  {initialValues?.buttonDestinationType === 'PRODUCT_PAGE' && (
                    <Field name="popupMessage.buttonDestinationPagePath">
                      {({ input, meta: { touched, error } }) => (
                        <SingleDropdown
                          options={productOptions}
                          error={touched && error}
                          selectedOption={input.value}
                          onChange={(value) => input.onChange(value)}
                        />
                      )}
                    </Field>
                  )}
                  {initialValues?.buttonDestinationType === 'FULL_URL' && (
                    <Field
                      name="popupMessage.buttonDestinationUrl"
                      validate={required}
                    >
                      {({ input, meta: { touched, error } }) => (
                        <TextField error={touched && error} {...input} />
                      )}
                    </Field>
                  )}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
