import { formattedAmount } from 'client/libraries/util/reservationReport';

import styles from './CustomTooltip.module.css';

export const CustomTooltip = ({ payload, dataTypeName, currencyCode }: any) => {
  return (
    <div className={styles['c-tooltip']}>
      <div className={styles['c-tooltip__header']}>
        <div className={styles['c-tooltip__header__ttl']}>{dataTypeName}</div>
      </div>
      <div className={styles['c-tooltip__body']}>
        {payload.length > 0 && (
          <>
            <div
              className={styles['c-tooltip__body__item']}
              style={{ color: payload[0].color }}
            >
              {payload[0].payload['date']}:{' '}
              {formattedAmount(payload[0].value, currencyCode)}
            </div>

            {payload[1] && (
              <div
                className={styles['c-tooltip__body__item']}
                style={{ color: payload[1].color }}
              >
                {payload[1].payload['compareDate']}:{' '}
                {formattedAmount(payload[1].value, currencyCode)}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
