import { combineReducers } from 'redux';

import {
  LOGOUT_SUCCESS,
  SET_IMPERSONATED_USER_ID,
  SET_WEB_JOURNEY_CONVERSION_PATH_CURRENT_PAGE,
  SET_WEB_JOURNEY_CONVERSION_PATH_PAGE_SIZE,
  SET_WEB_JOURNEY_CONVERSION_PATH_SELECTED_PRESET_NAME,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT,
  SET_WEB_JOURNEY_CONVERSION_PATH_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';

// Reducers
const defaultVisibleColumns = ['path', 'sessionCount', 'conversionCount'];

const visibleColumns = (state = defaultVisibleColumns, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_VISIBLE_COLUMNS:
      return action.visibleColumns;

    default:
      return state;
  }
};
const selectedPresetName = (state = 'NONE', action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_SELECTED_PRESET_NAME:
      return action.selectedPreset;

    default:
      return state;
  }
};
const currentPage = (state = 1, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_CURRENT_PAGE:
      return action.currentPage;

    default:
      return state;
  }
};

const pageSize = (state = 10, action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_PAGE_SIZE:
      return action.pageSize;

    default:
      return state;
  }
};

const sessionCountSortOrder = (state = 'DEFAULT', action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT:
      return action.sortOrder;

    default:
      return state;
  }
};

const conversionCountSortOrder = (state = 'DEFAULT', action: any) => {
  switch (action.type) {
    case SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT:
      return action.sortOrder;

    default:
      return state;
  }
};

type State = {
  visibleColumns: string[];
  selectedPresetName: string;
  currentPage: number;
  pageSize: number;
  sessionCountSortOrder: 'DEFAULT' | 'ASC' | 'DESC';
  conversionCountSortOrder: 'DEFAULT' | 'ASC' | 'DESC';
};
const reducer = combineReducers<State>({
  visibleColumns,
  selectedPresetName,
  currentPage,
  pageSize,
  sessionCountSortOrder,
  conversionCountSortOrder,
});
export const webJourneyConversionPathControls = (state: State, action: any) => {
  // Reset data to initial values when impersonating
  if (
    action.type === SET_IMPERSONATED_USER_ID ||
    action.type === LOGOUT_SUCCESS
  ) {
    return reducer(undefined, action);
  }

  return reducer(state, action);
};
