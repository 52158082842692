import * as React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';

import { currency as currencyJs } from 'shared/libraries/currency';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/Form';
import { InfoTooltipWithPortal } from 'client/components/InfoTooltipWithPortal/InfoTooltipWithPortal';
import { loggedInAccountIsNutmegAdminSelector } from 'client/reducers/user';
import { getFormattedAmount } from 'client/libraries/util/getFormattedAmount';
import { ConfirmModal } from 'client/components/ConfirmModal/ConfirmModal';
import { markSystemFeeInvoicePaid } from 'client/actions/systemFeeInvoices';
import { SystemFeeInvoice } from 'shared/models/swagger';
import styles from 'client/components/v3/Table/TableSmall.module.css';

import { SystemFeeFareAdjustmentModal } from './SystemFeeFareAdjustmentModal/SystemFeeFareAdjustmentModal';

interface Props {
  invoice: SystemFeeInvoice;
}

export const SystemFeeInvoiceSummary = ({ invoice }: Props) => {
  const { t } = useTranslation();
  const [
    showSystemFeeFareAdjustmentModal,
    setShowSystemFeeFareAdjustmentModal,
  ] = React.useState(false);
  const [showPaymentCompleteModal, setShowPaymentCompleteModal] =
    React.useState(false);
  const isNutmegAdmin = useSelector(loggedInAccountIsNutmegAdminSelector);
  const dispatch = useDispatch();

  const showPaymentCompleteButton =
    (invoice?.amount_due_by_nutmeg_final &&
      currencyJs(invoice?.amount_due_by_nutmeg_final).value > 0) ||
    (invoice?.amount_due_by_supplier_final &&
      currencyJs(invoice?.amount_due_by_supplier_final).value > 0);

  return (
    <>
      <table className={clsx(styles['c-tableSmall'], styles['row'])}>
        <thead>
          <tr>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              <div>{t('PIF')}</div>
              <div>{t('Gross')}</div>
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              <div>{t('PIF')}</div>
              <div>{t('Cancel Fee')}</div>
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              <div>
                {t('PIF')}
                {t('Booking Fee')}
              </div>
              <InfoTooltipWithPortal
                tooltipStyle={{
                  width: '210px',
                }}
                text={
                  <div>
                    <div>{t('PIF Booking Fee is total of the following:')}</div>
                    <div>{t(' - PIF Gross * booking fee rate')}</div>
                    <div>{t(' - PIF Cancel Fee * booking fee rate')}</div>
                  </div>
                }
              />
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              {t('PIF')}
              {t('Credit Card Fee')}
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              {t('POB')}
              {t('Gross')}
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              {t('POB')}
              {t('Cancel Fee')}
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              <div>
                {t('POB')}
                {t('Booking Fee')}
              </div>
              <InfoTooltipWithPortal
                tooltipStyle={{
                  width: '210px',
                }}
                text={
                  <div>
                    <div>{t('POB Booking Fee is total of the following:')}</div>
                    <div>{t(' - POB Gross * booking fee rate')}</div>
                    <div>{t(' - POB Cancel Fee * booking fee rate')}</div>
                  </div>
                }
              />
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              {t('Adjusted Amount')}
            </th>
            <th
              style={{
                width: 'calc(100% / 9)',
                fontWeight: 'var(--text-semibold) !important',
              }}
            >
              {t('Billing Amount')}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getFormattedAmount(invoice?.pif_gross_amount)}</td>
            <td>{getFormattedAmount(invoice?.pif_cancellation_fee_amount)}</td>
            <td>{getFormattedAmount(invoice?.pif_booking_fee_amount)}</td>
            <td>{getFormattedAmount(invoice?.pif_credit_card_fee_amount)}</td>
            <td>{getFormattedAmount(invoice?.pob_gross_amount)}</td>
            <td>{getFormattedAmount(invoice?.pob_cancellation_fee_amount)}</td>
            <td>{getFormattedAmount(invoice?.pob_booking_fee_amount)}</td>
            <td>{getFormattedAmount(invoice?.adjusted_amount)}</td>
            <td>
              {invoice?.amount_due_by_nutmeg_final ? (
                getFormattedAmount(invoice?.amount_due_by_nutmeg_final)
              ) : (
                <div className={clsx(styles['red'], styles['right-align'])}>
                  {getFormattedAmount(invoice?.amount_due_by_supplier_final)}
                </div>
              )}
            </td>
          </tr>
        </tbody>
      </table>
      {isNutmegAdmin && (
        <>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              style="yellow"
              size="small"
              onClick={() => setShowSystemFeeFareAdjustmentModal(true)}
            >
              {t('Fare Adjustment')}
            </Button>
            {showPaymentCompleteButton && (
              <Box ml={1}>
                <Button
                  style="yellow"
                  size="small"
                  disabled={
                    invoice?.status !== 'SYSTEM_FEE_CONFIRMED' ||
                    invoice?.payment_has_been_processed
                  }
                  onClick={() => setShowPaymentCompleteModal(true)}
                >
                  {t('Payment Complete')}
                </Button>
              </Box>
            )}
          </Box>
          {showPaymentCompleteModal && (
            <ConfirmModal
              header={t('Process Payment')}
              content={t(
                'Notification email will be sent to the supplier. Are you sure you want to complete the payment process?'
              )}
              onConfirm={async () => {
                await dispatch(markSystemFeeInvoicePaid(invoice?.id ?? ''));
              }}
              onClose={() => setShowPaymentCompleteModal(false)}
              open={showPaymentCompleteModal}
              insertRoot={true}
            />
          )}
          {showSystemFeeFareAdjustmentModal && (
            <SystemFeeFareAdjustmentModal
              systemFeeInvoice={invoice}
              onClose={() => setShowSystemFeeFareAdjustmentModal(false)}
            />
          )}
        </>
      )}
    </>
  );
};
