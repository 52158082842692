import {
  SET_WEB_JOURNEY_CONVERSION_PATH_CURRENT_PAGE,
  SET_WEB_JOURNEY_CONVERSION_PATH_PAGE_SIZE,
  SET_WEB_JOURNEY_CONVERSION_PATH_SELECTED_PRESET_NAME,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT,
  SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT,
  SET_WEB_JOURNEY_CONVERSION_PATH_VISIBLE_COLUMNS,
} from 'client/constants/ActionTypes';

export const setWebJourneyConversionPathVisibleColumns = (
  visibleColumns: string[]
) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_VISIBLE_COLUMNS,
  visibleColumns,
});

export const setWebJourneyConversionPathCurrentPage = (
  currentPage: number
) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_CURRENT_PAGE,
  currentPage,
});

export const setWebJourneyConversionPathPageSize = (pageSize: number) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_PAGE_SIZE,
  pageSize,
});

export const setWebJourneyConversionPathSelectedPresetName = (
  selectedPreset: string
) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_SELECTED_PRESET_NAME,
  selectedPreset,
});

export const setWebJourneyConversionPathSortBySessionCount = (
  sortOrder: 'DEFAULT' | 'ASC' | 'DESC'
) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_SESSION_COUNT,
  sortOrder,
});

export const setWebJourneyConversionPathSortByConversionCount = (
  sortOrder: 'DEFAULT' | 'ASC' | 'DESC'
) => ({
  type: SET_WEB_JOURNEY_CONVERSION_PATH_SORT_BY_CONVERSION_COUNT,
  sortOrder,
});
