import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router';

import { setUseNewUI } from 'client/actions/newUIControls';
import { Origin, useNewUISwitch } from 'client/hooks/useNewUISwitch';
import { Toggle } from 'client/components/v3/Form/Toggle';
import {
  activeAccountSelector,
  alternateOrganizationIsActiveSelector,
} from 'client/reducers/user';

type Props = {
  origin: Origin;
};

const v3RevampRoutes = [
  '/reservations-v3',
  '/reservations-v3/:id',
  '/reservations/dash-v3',
  '/reservations/download-v3',
  '/reservations/reports-v3',
  '/products-v3',
  '/products-v3/:id',
  '/products/:id/edit-v3',
  '/products/:id/copy-v3',
  '/products/new-v3',
  '/products/promotions-v3',
  '/availability/weekly-v3',
  '/availability/monthly-v3',
  '/manifests-v3',
  '/manifests/assignments-v3',
  '/manifests/customize-v3',
  '/manifests/resources-v3',
  '/customers/settings-v3',
  '/bookingWidget/general-v3',
  '/bookingWidget/customize-v3',
  '/bookingWidget/topPage-v3',
  '/bookingWidget/websiteintegration-v3',
  '/bookingWidget/reports-v3',
  '/bookingWidget/homepages-v3',
  '/bookingWidget/homepages-v3/editcustompage',
  '/systemfee/settings-v3',
  '/systemfee/subscriptions-v3',
  '/systemfee/reservations-v3',
  '/systemfee/reservations-v3/:id',
  '/settings-v3',
];

const v3NewRoutes = [
  '/annualpasses/dashboard',
  '/customers/dashboard',
  '/home',
  '/journeyanalytics/customers',
  '/journeyanalytics/customers/:id',
  '/journeyanalytics/dashboard',
  '/journeyanalytics/settings',
  '/journeyanalytics/conversionpaths',
  '/marketingautomation/dashboard',
  '/products/:id/translation-v3',
  '/reservations/new-v3',
  '/mobileorders/dashboard',
  '/mobileorders/orders',
  '/mobileorders/feedback',
  '/mobileorders/restaurants',
  '/mobileorders/restaurants/-/menus',
  '/mobileorders/restaurants/:restaurantId/menus/new',
  '/mobileorders/restaurants/:restaurantId/menus/:menuId',
  '/mobileorders/promotions',
  '/mobileorders/settings',
  '/sitecontrolwidgets/dashboard',
  '/sitecontrolwidgets',
  '/sitecontrolwidgets/new',
  '/sitecontrolwidgets/:id/edit',
  '/surveys/dashboard',
  '/maps/dashboard',
  '/maps/guestjourneys',
  '/maps/waittimes',
];

export const v3Routes = [...v3RevampRoutes, ...v3NewRoutes];

export const isV3NewPageRoute = (route: string) => {
  return v3NewRoutes.some((v3Route) =>
    matchPath(route, { path: v3Route, exact: true })
  );
};

export const isV3Route = (route: string) => {
  return v3Routes.some((v3Route) =>
    matchPath(route, { path: v3Route, exact: true })
  );
};

export const ToggleNewUI = ({ origin }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { shouldUseNewUI } = useNewUISwitch(origin);
  const activeAccount = useSelector(activeAccountSelector);
  const alternateOrganizationIsActive = useSelector(
    alternateOrganizationIsActiveSelector
  );

  const history = useHistory();
  const currentUrl = history.location.pathname;
  if (isV3NewPageRoute(currentUrl)) {
    return <></>;
  }

  // If current user org is agent or the active alternate org is agent, set new UI to false and hide toggle
  if (
    activeAccount?.organization_type === 'AGENT' ||
    (alternateOrganizationIsActive &&
      activeAccount?.alternate_organization_type === 'AGENT')
  ) {
    dispatch(setUseNewUI(false));
    return <></>;
  }

  return (
    <div style={{ marginBottom: '12px' }}>
      <Toggle
        label={t('Try new design')}
        onChange={() => {
          dispatch(setUseNewUI(!shouldUseNewUI));
        }}
        checked={shouldUseNewUI}
      />
    </div>
  );
};
