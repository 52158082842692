import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import { equipmentsSelector } from 'client/reducers/equipments';
import type { EquipmentInstance } from 'shared/models/swagger';
import { Modal } from 'client/components/Modal/Modal';
import {
  Checkbox,
  Button,
  TextArea,
  FieldWrapper,
} from 'client/components/Form';
import componentStyles from 'client/components/components.module.css';
import { updateEquipmentInstance } from 'client/actions/equipmentInstances';

interface Props {
  open: boolean;
  onClose: () => void;
  equipmentInstance: EquipmentInstance | undefined;
}

export const EditEquipmentInstanceChannelModal = ({
  open,
  onClose,
  equipmentInstance,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [closeAllChannels, setCloseAllChannels] = React.useState(false);
  const [internalMemo, setInternalMemo] = React.useState('');

  React.useEffect(() => {
    if (!open) {
      return;
    }
    if (!equipmentInstance) {
      return;
    }
    setCloseAllChannels(
      equipmentInstance.per_channel_info?.all_channels_are_closed || false
    );
    setInternalMemo(equipmentInstance.internal_memo?.value || '');
  }, [open, equipmentInstance]);

  const equipments = useSelector(equipmentsSelector);

  const selectedEquipment = equipments.find(
    (equipment) => equipment.id === equipmentInstance?.equipment_id
  );

  const date = equipmentInstance?.date;

  let startTime = '';

  selectedEquipment?.equipment_schedules?.forEach((schedule) => {
    schedule.start_time_mappings?.forEach((mapping) => {
      if (mapping.key === equipmentInstance?.start_time_key) {
        startTime = mapping.start_time_local || '';
      }
    });
  });

  return (
    <Modal
      title={`${t('Edit Instance')}: ${
        selectedEquipment?.title
      } - ${date} - ${startTime}`}
      open={open}
      onClose={onClose}
      insertRoot={true}
    >
      <Modal.Content>
        <div className={clsx(componentStyles['c-table-manifests'])}>
          <div className={clsx(componentStyles['c-table-manifests__tbody'])}>
            <Box mt={2}>
              <Checkbox
                label={t('Close all channels')}
                checked={closeAllChannels}
                onChange={() => {
                  setCloseAllChannels(!closeAllChannels);
                }}
              />
            </Box>
            <Box mt={2}>
              <FieldWrapper label={t('Memo')}>
                <TextArea
                  value={internalMemo}
                  onChange={(_: any, { value }) => setInternalMemo(value)}
                />
              </FieldWrapper>
            </Box>
          </div>
        </div>
      </Modal.Content>

      <Modal.Actions>
        <Button.Cancel
          onClick={() => {
            onClose();
          }}
        >
          {t('Discard')}
        </Button.Cancel>
        <Button.Submit
          onClick={async () => {
            try {
              await dispatch(
                updateEquipmentInstance({
                  ...equipmentInstance,
                  per_channel_info: {
                    all_channels_are_closed: closeAllChannels,
                  },
                  internal_memo: { value: internalMemo },
                })
              );
              onClose();
            } catch (e) {
              console.error(e);
            }
          }}
        >
          {t('Save')}
        </Button.Submit>
      </Modal.Actions>
    </Modal>
  );
};
