import { useTranslation } from 'react-i18next';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from 'client/components/Box/Box';
import {
  ColumnType,
  GenericTable,
} from 'client/components/v3/GenericTable/GenericTable';
import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { fetchConversionPaths } from 'client/actions/analyticsCustomers';
import { ReduxState } from 'client/reducers';
import { ConversionPath } from 'shared/models/swagger';
import { journeyAnalyticsSearchPresetsSelector } from 'client/reducers/supplierSettings';
import {
  setWebJourneyConversionPathCurrentPage,
  setWebJourneyConversionPathPageSize,
  setWebJourneyConversionPathSelectedPresetName,
  setWebJourneyConversionPathSortByConversionCount,
  setWebJourneyConversionPathSortBySessionCount,
  setWebJourneyConversionPathVisibleColumns,
} from 'client/actions/webJourneyConversionPathControls';

import { SearchForm } from '../JourneyAnalyticsCustomerList/SearchForm/SearchForm';

import { ColumnSettingsModal } from './ColumnSettingsModal';
import { ConversionPathView } from './ConversionPathView';

export type ColumnId = 'path' | 'sessionCount' | 'conversionCount';

type Props = {
  totalSessionCount?: number;
  totalConversionCount?: number;
  sortBySessionCount?: (sortOrder: 'ASC' | 'DESC') => void;
  sortByConversionCount?: (sortOrder: 'ASC' | 'DESC') => void;
};

export const useColumns = ({
  totalSessionCount,
  totalConversionCount,
  sortBySessionCount,
  sortByConversionCount,
}: Props): ColumnType<ConversionPath>[] => {
  const { t } = useTranslation();
  const sessionCountSortOrder = useSelector(
    (state: ReduxState) =>
      state.webJourneyConversionPathControls.sessionCountSortOrder
  );
  const conversionCountSortOrder = useSelector(
    (state: ReduxState) =>
      state.webJourneyConversionPathControls.conversionCountSortOrder
  );

  return [
    {
      Header: t('Viewed pages'),
      id: 'path',
      accessor: (conversionPath) => (
        <ConversionPathView path={conversionPath.path} />
      ),
    },
    {
      Header: t('Sessions', { context: 'conversionPath' }),
      id: 'sessionCount',
      accessor: (conversionPath) => conversionPath?.session_count || 0,
      width: 200,
      headerSubtext: `${t('Total')}: ${totalSessionCount?.toLocaleString()}`,
      onColumnSort: sortBySessionCount,
      sortOrder: sessionCountSortOrder,
    },
    {
      Header: t('Conversions', { context: 'conversionPath' }),
      id: 'conversionCount',
      accessor: (conversionPath) => conversionPath?.conversion_count || 0,
      width: 200,
      headerSubtext: `${t('Total')}: ${totalConversionCount?.toLocaleString()}`,
      onColumnSort: sortByConversionCount,
      sortOrder: conversionCountSortOrder,
    },
  ];
};

export const JourneyAnalyticsConversionPath = () => {
  const presets = useSelector(journeyAnalyticsSearchPresetsSelector);
  const [showColumnEditModal, setShowColumnEditModal] = React.useState(false);
  const visibleColumns = useSelector(
    (state: ReduxState) => state.webJourneyConversionPathControls.visibleColumns
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.webJourneyConversionPathControls.currentPage
  );
  const rowCount = useSelector(
    (state: ReduxState) => state.webJourneyConversionPathControls.pageSize
  );

  const presetsLoading = useSelector(
    (state: ReduxState) => state.supplierSettings.loading
  );
  const selectedPresetName = useSelector(
    (state: ReduxState) =>
      state.webJourneyConversionPathControls.selectedPresetName
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedPreset = presets.find(
    (preset) => preset.preset_name === selectedPresetName
  );

  React.useEffect(() => {
    if (
      selectedPresetName &&
      selectedPresetName !== 'NONE' &&
      !selectedPreset
    ) {
      return;
    }

    if (selectedPreset) {
      dispatch(fetchConversionPaths(selectedPreset.condition_groups ?? []));
    } else {
      dispatch(fetchConversionPaths([]));
    }
  }, [selectedPreset, selectedPresetName]);

  const conversionPaths = useSelector(
    (state: ReduxState) => state.analyticsCustomers.conversionPaths
  );
  const totalSessionCount = useSelector(
    (state: ReduxState) => state.analyticsCustomers.totalSessionCount
  );
  const totalConversionCount = useSelector(
    (state: ReduxState) => state.analyticsCustomers.totalConversionCount
  );
  const handleSortBySessionCount = (sortOrder: 'ASC' | 'DESC') => {
    dispatch(setWebJourneyConversionPathSortBySessionCount(sortOrder));
  };
  const handleSortByConversionCount = (sortOrder: 'ASC' | 'DESC') => {
    dispatch(setWebJourneyConversionPathSortByConversionCount(sortOrder));
  };

  const allColumns = useColumns({
    totalSessionCount,
    totalConversionCount,
    sortBySessionCount: handleSortBySessionCount,
    sortByConversionCount: handleSortByConversionCount,
  });

  const loading = useSelector(
    (state: ReduxState) => state.analyticsCustomers.loading
  );

  const columns = visibleColumns.map(
    (col) => allColumns.find((c) => c.id === col) as ColumnType<ConversionPath>
  );

  const pageItems = conversionPaths.slice(
    (currentPage - 1) * rowCount,
    currentPage * rowCount
  );

  // Reset to last page when number of data changes and current page is larger
  const totalPages = Math.ceil(conversionPaths.length / rowCount);
  if (totalPages > 0 && currentPage > totalPages) {
    dispatch(setWebJourneyConversionPathCurrentPage(totalPages));
  }

  return (
    <Box ml={-4} mr={-4} mt={-4}>
      <PageHeader title={t('Conversion Path')} />
      <Box pl={8} pr={8}>
        <SearchForm
          selectedPresetName={selectedPresetName}
          setSelectedPresetName={(presetName: string) =>
            dispatch(setWebJourneyConversionPathSelectedPresetName(presetName))
          }
        />
        <Box mt={4}>
          <GenericTable
            columns={columns}
            currentPage={currentPage}
            onCurrentPageChange={(newPage) =>
              dispatch(setWebJourneyConversionPathCurrentPage(newPage))
            }
            rowCount={rowCount}
            onRowCountChange={(newRowCount) =>
              dispatch(setWebJourneyConversionPathPageSize(newRowCount))
            }
            totalCount={conversionPaths.length}
            items={pageItems}
            loading={loading || (Boolean(selectedPresetName) && presetsLoading)}
            hideScrollButtons={true}
          />
        </Box>
      </Box>
      {showColumnEditModal && (
        <ColumnSettingsModal
          onClose={() => setShowColumnEditModal(false)}
          columns={visibleColumns as ColumnId[]}
          onColumnsChange={(newColumns: any) =>
            dispatch(setWebJourneyConversionPathVisibleColumns(newColumns))
          }
        />
      )}
    </Box>
  );
};
