import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useInView } from 'react-intersection-observer';

import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';
import { Button } from 'client/components/v3/Common/Button';

type Props = {
  title: string;
  subtitle?: string;
  id?: string;
  initialOpen?: boolean;
  children: JSX.Element | JSX.Element[];
  setCurrentSectionId?: Dispatch<SetStateAction<string>>;
};

export const CollapsibleSection = ({
  title,
  subtitle,
  id,
  initialOpen = true,
  children,
  setCurrentSectionId,
}: Props) => {
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState<boolean>(true);
  const { ref, inView } = useInView({
    threshold: 0, // Trigger intersection as soon as element starts to be visible inside the observable view
    rootMargin: '-50% 0% -50% 0%', // Make the observable view intersection line the center of viewport
  });

  useEffect(() => {
    setIsActive(initialOpen);
  }, [initialOpen]);

  useEffect(() => {
    if (inView) {
      setCurrentSectionId?.(id ?? '');
    }
  }, [inView]);

  return (
    <div id={id} className={styles['p-topPage-products__section']}>
      <div className={styles['p-topPage-products__section__header']} ref={ref}>
        <p className={styles['p-topPage-products__section__ttl']}>
          {title}
          {subtitle && <span>{subtitle}</span>}
        </p>
        <div className={styles['p-topPage-products__section__ic']}>
          <Button
            text={isActive ? t('Hide') : t('Show')}
            uiType="bg"
            size="md"
            color="tertiarygray"
            iconBeforeText={
              isActive ? (
                <i className="c-icon-outline-general-minus"></i>
              ) : (
                <i className="c-icon-solid-general-plus"></i>
              )
            }
            onClick={() => {
              setIsActive(!isActive);
            }}
          />
        </div>
      </div>

      <div
        className={clsx(
          styles['p-topPage-products__section__body'],
          isActive && styles['is-active']
        )}
      >
        {children}
      </div>
    </div>
  );
};
