import * as React from 'react';
import moment from 'moment-timezone';

import { JourneyAnalyticsSiteContext } from 'client/contexts/JourneyAnalyticsSiteContext';

import { Event } from './Event/Event';
import { SessionSelectionContext } from './SessionSelectionContext';
import { getFilteredEvents, getFilteredOnSiteEvents } from './util';
import { useSessions } from './useSessions';

export const SessionDetails = () => {
  const sessions = useSessions();
  const { site } = React.useContext(JourneyAnalyticsSiteContext);

  const { selectedSessionTimestamp } = React.useContext(
    SessionSelectionContext
  );

  const selectedSession = sessions?.find(
    (session) => session.start_date_time_utc === selectedSessionTimestamp
  );

  const filteredEvents = selectedSession
    ? site === 'ON_SITE'
      ? getFilteredOnSiteEvents(selectedSession)
      : getFilteredEvents(selectedSession)
    : [];

  return (
    <>
      {filteredEvents.map((event, idx) => {
        const durationInSeconds =
          idx < filteredEvents.length - 1
            ? moment(filteredEvents[idx + 1].event_date_time_utc).diff(
                moment(event.event_date_time_utc)
              ) / 1000
            : moment(selectedSession?.end_date_time_utc).diff(
                moment(event.event_date_time_utc)
              ) / 1000;

        return (
          <Event
            key={idx}
            event={event}
            durationInSeconds={durationInSeconds}
          />
        );
      })}
    </>
  );
};
