import * as React from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import { Badge } from 'client/components/v3/Common/Badge';
import { OperateButton } from 'client/pages/v3/Product/ProductPromotions/OperateButton';
import { TextField } from 'client/components/v3/Form/TextField';
import { fetchPromotions } from 'client/actions/promotions';
import {
  getPromotionConditionDescription,
  //getPromotionDiscountDescription,
  getPromotionTypeText,
  getPromotionStatusText,
  getUnitPriceDeltaUnitTitle,
} from 'client/libraries/util/promotionTextHelpers';
import { getDisplayProductName } from 'client/libraries/util/getDisplayProductName';
import { activeUserSelector } from 'client/reducers/user';
import { hasCustomUserRoleWritePermissions } from 'client/libraries/util/customUserPermissions';
import { fetchProducts } from 'client/actions/products';
import type { PromotionShape } from 'client/libraries/util/promotionShape';
import type { ReduxState } from 'client/reducers';
import { summariesSelector } from 'client/reducers/products';
import { CustomTable } from 'client/pages/v3/Product/ProductPromotions/CustomTable';
import type { TabType } from 'client/pages/v3/Product/ProductPromotions/utils';
import { PromotionSettingsModal } from 'client/pages/v3/Product/ProductPromotions/PromotionSettingsModal';
import {
  promotionsSelector,
  filterPromotions,
  getBadgeColorForPromotionStatus,
} from 'client/pages/v3/Product/ProductPromotions/utils';
import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/Product/ProductPromotions/ProductPromotions.module.css';
import { getDaysOfWeekText } from 'client/libraries/util/getDaysOfWeekText';

export const ProductPromotionsBody = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = React.useState('');
  const [activeTab, setActiveTab] = React.useState<TabType>('ON');
  const [showModal, setShowModal] = React.useState(false);
  const [promo, setPromo] = React.useState<PromotionShape | null>(null);
  const tableBodyRef = React.useRef<HTMLDivElement>(null);
  const apiPromotions = useSelector(promotionsSelector);
  const allProducts = useSelector(summariesSelector);
  const activeUser = useSelector(activeUserSelector);
  const promotions = _.sortBy(
    filterPromotions(apiPromotions, allProducts, filter, t),
    (p) => p.productName
  );
  const onPromotions = promotions.filter((promo) => promo.status === 'ON');
  const offPromotions = promotions.filter((promo) => promo.status === 'OFF');
  const discontinuedPromotions = promotions.filter(
    (promo) => promo.status === 'DISCONTINUED'
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchPromotions());
  }, [dispatch, invalidated]);

  const getColumns = () => {
    return [
      ...(hasCustomUserRoleWritePermissions(activeUser, 'PRODUCT.PROMOTIONS')
        ? [
            {
              Header: t('Operate'),
              id: 'delete/copy',
              Cell: (cellInfo: any) => (
                <OperateButton
                  inputPromotion={cellInfo.original}
                  tableRef={tableBodyRef}
                  setShowModal={setShowModal}
                  setPromo={setPromo}
                />
              ),
              width: 'short',
            },
          ]
        : []),
      {
        Header: t('Product'),
        id: 'productName',
        accessor: 'productName',
        Cell: (cellInfo: any) => (
          <div className={clsx(baseStyles['inline-block'])}>
            {cellInfo.value ? (
              <Link to={`/products/${cellInfo.original.productId}`}>
                {getDisplayProductName(
                  allProducts.find((p) => p.id == cellInfo.original.productId)
                )}
              </Link>
            ) : (
              t('All Products')
            )}
          </div>
        ),
        width: 'long',
      },
      {
        Header: t('Promotion Type'),
        id: 'type',
        accessor: (row: PromotionShape) => getPromotionTypeText(row.type, t),
        width: 'shortMiddle',
      },
      {
        Header: t('Status'),
        id: 'status',
        Cell: (cellInfo: any) => (
          <Badge
            label={getPromotionStatusText(cellInfo.original.status, t)}
            color={getBadgeColorForPromotionStatus(cellInfo.original.status)}
          />
        ),
        width: 'shortMiddle',
      },
      {
        Header: t('Condition'),
        id: 'condition',
        accessor: (row: PromotionShape) =>
          getPromotionConditionDescription(row, t, locale),
        width: 'middle',
      },
      {
        Header: t('Charge/Discount'),
        id: 'chargeDiscount',
        Cell: (cellInfo: any) => (
          <>
            {(cellInfo.original as PromotionShape).unitPriceDeltas.map(
              (delta) => {
                const unitTitle = getUnitPriceDeltaUnitTitle(delta, t);
                const discount = delta.delta_percent
                  ? `${delta.delta_percent}%`
                  : `${delta.delta_fixed_gross || ''}/${
                      delta.delta_fixed_net || ''
                    }`;
                return (
                  <>
                    <span
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >{`${unitTitle}: ${discount}`}</span>
                    <br />
                  </>
                );
              }
            )}
          </>
        ),
        width: 'middleLong',
      },
      {
        Header: t('Participation Date Range'),
        id: 'startDateRange',
        accessor: (row: PromotionShape) => {
          const weekdayText = getDaysOfWeekText(row.participationDaysOfWeek, t);
          return `${row.participationDateRanges
            .map((dateRange) => `${dateRange.startDate} ~ ${dateRange.endDate}`)
            .join(' ')}${weekdayText ? ` (${weekdayText})` : ''}`;
        },
        width: 'middle',
      },
      {
        Header: t('Booking Date Range'),
        id: 'bookedDateRange',
        accessor: (row: PromotionShape) =>
          row.bookedDateLocalFrom || row.bookedDateLocalTo
            ? `${row.bookedDateLocalFrom} ~ ${row.bookedDateLocalTo}`
            : '',
        width: 'middle',
      },
      {
        Header: t('Channels'),
        id: 'channels',
        Cell: (cellInfo: any) => {
          const row: PromotionShape = cellInfo.original;
          if (!row.channels || row.channels.length === 0) {
            return <>{t('All')}</>;
          }
          return (
            <>
              {row.channels.map((channel) => {
                if (channel.channel_category == 'DIRECT_ONLINE') {
                  return (
                    <>
                      {t('Direct (Online)')}
                      <br />
                    </>
                  );
                }
                if (channel.channel_category == 'DIRECT_OFFLINE') {
                  return (
                    <>
                      {t('Direct (Offline)')}
                      <br />
                    </>
                  );
                }
                return (
                  <>
                    {channel.agent_name}
                    <br />
                  </>
                );
              })}
            </>
          );
        },
        width: 'middleLong',
      },
      {
        Header: t('Promo Code'),
        id: 'promoCode',
        accessor: 'promoCode',
        width: 'middle',
      },
    ];
  };

  const columns = getColumns();

  return (
    <div className={clsx(baseStyles['l-main__body'])}>
      <section className={styles['g-section']}>
        <div className={clsx(styles['p-reservations'])}>
          <div className={styles['p-reservations__header']}>
            <ul className={styles['p-reservations__tab']}>
              <li
                className={clsx(activeTab === 'ON' ? styles['is-active'] : '')}
                onClick={() => {
                  setActiveTab('ON');
                }}
              >
                <a>{t('On')}</a>
              </li>
              <li
                className={clsx(activeTab === 'OFF' ? styles['is-active'] : '')}
                onClick={() => {
                  setActiveTab('OFF');
                }}
              >
                <a>{t('Off')}</a>
              </li>
              <li
                className={clsx(
                  activeTab === 'DISCONTINUED' ? styles['is-active'] : ''
                )}
                onClick={() => {
                  setActiveTab('DISCONTINUED');
                }}
              >
                <a>{t('Discontinued')}</a>
              </li>
            </ul>
            <div className={styles['p-reservations__actions']}>
              <TextField
                value={filter}
                onChange={(e) => setFilter(e)}
                placeholder={t('Filter')}
              />
            </div>
          </div>

          {activeTab === 'ON' && (
            <>
              {onPromotions.length === 0 ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '100px',
                    marginBottom: '100px',
                  }}
                >
                  {t('No promotions in the "{{status}}" status.', {
                    status: t('On'),
                  })}
                </div>
              ) : (
                <CustomTable
                  items={_.sortBy(onPromotions, (p) => p.productName)}
                  columns={columns}
                  useScrollButton={true}
                  usePaging={true}
                />
              )}
            </>
          )}

          {activeTab === 'OFF' && (
            <>
              {offPromotions.length === 0 ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '100px',
                    marginBottom: '100px',
                  }}
                >
                  {t('No promotions in the "{{status}}" status.', {
                    status: t('Off'),
                  })}
                </div>
              ) : (
                <CustomTable
                  items={_.sortBy(offPromotions, (p) => p.productName)}
                  columns={columns}
                  useScrollButton={true}
                  usePaging={true}
                />
              )}
            </>
          )}

          {activeTab === 'DISCONTINUED' && (
            <>
              {discontinuedPromotions.length === 0 ? (
                <div
                  style={{
                    textAlign: 'center',
                    marginTop: '100px',
                    marginBottom: '100px',
                  }}
                >
                  {t('No promotions in the "{{status}}" status.', {
                    status: t('Discontinued'),
                  })}
                </div>
              ) : (
                <CustomTable
                  items={_.sortBy(discontinuedPromotions, (d) => d.productName)}
                  columns={columns}
                  useScrollButton={true}
                  usePaging={true}
                />
              )}
            </>
          )}
        </div>
        {showModal && (
          <PromotionSettingsModal
            open={showModal}
            setShowModal={(show: boolean) => {
              setShowModal(show);
              //setShowMenu(false);
            }}
            inputPromotion={promo}
          />
        )}
      </section>
    </div>
  );
};
