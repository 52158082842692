import * as React from 'react';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { Button, FieldWrapper, Input, Select } from 'client/components/Form';
import { Box } from 'client/components/Box/Box';
import { ImageVideoAudioInput } from 'client/components/ImageVideoAudioInput/ImageVideoAudioInput';
import { Delete } from 'client/components/Icons/Delete';
import { Edit } from 'client/components/Icons/Edit';

import { FormValues } from './formValues';
import { GoogleMapView } from './GoogleMapView';
import styles from './OverlayEditor.module.css';

interface OverlayEditorProps {
  activeFloor: number;
  floorCount: number;
  onFloorChange: (floor: number) => void;
}

export const OverlayEditor: React.FC<OverlayEditorProps> = ({
  activeFloor,
  floorCount,
  onFloorChange,
}) => {
  const { t } = useTranslation();
  const form = useForm<FormValues>();
  const { values } = form.getState();

  const activeOverlay = values.overlays[activeFloor - 1];

  return (
    <>
      {values.floorCount > 1 && (
        <>
          <Box mb={2}>
            <Select
              label={t('Select Overlay Floor')}
              options={Array.from({ length: floorCount }, (_, i) => ({
                value: (i + 1).toString(),
                text: t('Floor {{number}}', { number: i + 1 }),
              }))}
              value={activeFloor.toString()}
              onChange={(e, { value }) => onFloorChange(parseInt(value, 10))}
            />
          </Box>

          <div className={styles['editing-overlay-message']}>
            {t('Now editing overlay for Floor {{number}}', {
              number: activeFloor,
            })}
          </div>
        </>
      )}

      <Field name={`overlays.${activeFloor - 1}.imageUrl`}>
        {({ input }) => (
          <Box mt={2}>
            <FieldWrapper label={t('Overlay Image URL')} />
            <ImageVideoAudioInput
              enableSvgFormat
              fileUrls={input.value ? [input.value] : []}
              onChange={(newValue) => {
                const newImageUrl = newValue[0];
                if (newValue.length > 0) {
                  input.onChange(newImageUrl);

                  // Get aspect ratio of image
                  const img = new Image();
                  img.onload = () => {
                    form.change(
                      `overlays.${activeFloor - 1}.aspectRatio.width` as any,
                      img.width
                    );
                    form.change(
                      `overlays.${activeFloor - 1}.aspectRatio.height` as any,
                      img.height
                    );
                  };
                  img.src = newImageUrl;
                } else {
                  input.onChange('');
                }
              }}
              maxFileCount={1}
              disableYoutubeVideos
            />
          </Box>
        )}
      </Field>

      <Field name={`overlays.${activeFloor - 1}`}>
        {({ input }) => (
          <Box mb={4}>
            <FieldWrapper label={t('Top Left Corner')} />
            <Box display="flex" alignItems="center">
              {values.editingMode !== 'TOP_LEFT' && (
                <div>
                  {`(${input.value.topLeft.latitude}, ${input.value.topLeft.longitude})`}
                </div>
              )}
              {values.editingMode === 'NORMAL' && (
                <Box ml={2}>
                  <Delete
                    onClick={() => {
                      form.change(
                        `overlays.${activeFloor - 1}.topLeft.latitude` as any,
                        0
                      );
                      form.change(
                        `overlays.${activeFloor - 1}.topLeft.longitude` as any,
                        0
                      );
                    }}
                  />
                  <Edit
                    onClick={() => form.change('editingMode', 'TOP_LEFT')}
                  />
                </Box>
              )}
              {values.editingMode === 'TOP_LEFT' && (
                <>
                  <div>{t('Click map to set new location')}</div>
                  <Box ml={2}>
                    <Button.Create
                      onClick={() => {
                        form.change('editingMode', 'NORMAL');
                      }}
                    >
                      {t('Done')}
                    </Button.Create>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        )}
      </Field>

      <Field name={`overlays.${activeFloor - 1}.widthInMeters`}>
        {({ input }) => (
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="flex-end"
            mb={2}
          >
            <Box mr={2}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  let newValue = input.value - 100;
                  if (newValue < 0) newValue = 0;
                  input.onChange(newValue);
                }}
                width={30}
              >
                -100
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  let newValue = input.value - 10;
                  if (newValue < 0) {
                    newValue = 0;
                  }
                  input.onChange(newValue);
                }}
                width={30}
              >
                -10
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  let newValue = input.value - 1;
                  if (newValue < 0) {
                    newValue = 0;
                  }
                  input.onChange(newValue);
                }}
                width={30}
              >
                -
              </Button>
            </Box>
            <Box width="100px">
              <Input
                label={t('Overlay Width in Meters')}
                value={input.value}
                onChange={(e, { value }) => {
                  const newValue = parseInt(value, 10);
                  if (!isNaN(newValue) && newValue >= 0) {
                    input.onChange(newValue);
                  }
                }}
              />
            </Box>
            <Box ml={1}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  input.onChange(input.value + 1);
                }}
                width={30}
              >
                +
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  input.onChange(input.value + 10);
                }}
                width={30}
              >
                +10
              </Button>
            </Box>
            <Box ml={1}>
              <Button
                size="middle"
                style="yellow"
                onClick={() => {
                  input.onChange(input.value + 100);
                }}
                width={30}
              >
                +100
              </Button>
            </Box>
          </Box>
        )}
      </Field>

      <GoogleMapView
        disableAutoBounds
        onClick={
          values.editingMode === 'TOP_LEFT'
            ? (lat, lng) => {
                form.change(
                  `overlays.${activeFloor - 1}.topLeft.latitude` as any,
                  lat
                );
                form.change(
                  `overlays.${activeFloor - 1}.topLeft.longitude` as any,
                  lng
                );
              }
            : undefined
        }
        pins={
          values.editingMode === 'TOP_LEFT'
            ? [
                {
                  lat: activeOverlay.topLeft.latitude,
                  lng: activeOverlay.topLeft.longitude,
                },
              ]
            : undefined
        }
        overlay={activeOverlay}
        showOverlay={values.showOverlayOnMap}
        heading={values.mapRotation}
        initialCenter={values.mapCenter}
        zoom={values.mapZoom}
        onZoomChange={
          values.editingMode === 'ZOOM'
            ? (zoom) => {
                form.change('mapZoom', zoom);
              }
            : undefined
        }
      />
    </>
  );
};
