import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { PageHeader } from 'client/components/v3/Page/PageHeader';
import { defaultProductCurrencySelector } from 'client/reducers/organizations';

export const SystemFeeReservationsHeader = () => {
  const { t } = useTranslation();

  const currency = useSelector(defaultProductCurrencySelector);
  return (
    <PageHeader
      title={t('Web Bookings')}
      description={t('Currency Code: {{currency}}', { currency })}
    ></PageHeader>
  );
};
