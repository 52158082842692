export interface FeatureFlags {
  enableNtaProductSettings: boolean;
  enableGoogleMyBusinessFeature: boolean;
  enableBookInDigitalGuidance: boolean;
  enableProductMinimumPrice: boolean;
  enableResourceManagementVehicle: boolean;
  enableETicketOptionQRCode: boolean;
  enableResourceManagementCalendar: boolean;
  enableSeatManagement: boolean;
  enableCrossSellUpSell: boolean;
  enableETicketRedemptionTimeRelative: boolean;
  enableInstantWin: boolean;
  enableChannelManager: boolean;
  enableNotifications: boolean;
  enableShowFieldResponsesOnFootprintedModal: boolean;
  enableUIRevamp: boolean;
  enableWaiverListEnhancement: boolean;
  enableSid: boolean;
  enableETicketBarCode: boolean;
  enablePMPCategoryHideSettings: boolean;
  enableImportedCustomerListSegment: boolean;
  enableHPFeatureImprovements: boolean;
  enableETicketCheckinEndTime: boolean;
  enableFieldResponseImageUpload: boolean;
  enableCreateReservationButtonForPassthroughSupplier: boolean;
  enableBatchEditEquipmentInstanceAvailability: boolean;
  enableFixedExpirationDateTime: boolean;
  enablePartnership: boolean;
  enableETicketRedemptionCount: boolean;
  enableUndoEqauipmentTicketPrint: boolean;
  enableCustomerLedgerAddressInputForm: boolean;
  enableEmailCustomizationForLotteryMode: boolean;
  enableUIRevampForDemo: boolean;
  enableMemberLimitedProduct: boolean;
  enableUIRevampForRelease: boolean;
  enableMaRevamp: boolean;
  enableAnnualPassDashboard: boolean;
  enableNewsletterNewDesign: boolean;
  enableConsolidatedMarketingAutomationContent: boolean;
  enablePickupLocation: boolean;
  enableMobileOrder: boolean;
  enableNewsletterCustomEditor: boolean;
  enableSiteControlWidget: boolean;
  enableOnSiteJourneyAnalytics: boolean;
  enableLineItemTable: boolean;
  enableNpsSurvey: boolean;
  enablePartnershipPackageProduct: boolean;
  enableETicketPresetRedemptionCount: boolean;
  enablePartnershipSystemFeeInvoiceTable: boolean;
  enableWaitTimePages: boolean;
  enableDynamicPackageProduct: boolean;
  enableMapCachedLinks: boolean;
  enableDigitalMapGuestJourneys: boolean;
  enableConversionPath: boolean;
  enableDigitalMapSEO: boolean;
  enableMultifloorMaps: boolean;
  enableMapHeaderMenuSettings: boolean;
  enableMapLanguageMenuSettings: boolean;
  enableMapOpenTableSettings: boolean;
  enableMapUberButtonSettings: boolean;
}

// In "development" mode, all features under development are enabled.
export const developmentFeatureFlags: FeatureFlags = {
  enableNtaProductSettings: true,
  enableGoogleMyBusinessFeature: true,
  enableBookInDigitalGuidance: true,
  enableProductMinimumPrice: true,
  enableResourceManagementVehicle: true,
  enableETicketOptionQRCode: true,
  enableResourceManagementCalendar: true,
  enableSeatManagement: true,
  enableCrossSellUpSell: true,
  enableETicketRedemptionTimeRelative: true,
  enableInstantWin: true,
  enableChannelManager: true,
  enableNotifications: true,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableUIRevamp: true,
  enableWaiverListEnhancement: true,
  enableSid: true,
  enableETicketBarCode: true,
  enablePMPCategoryHideSettings: true,
  enableImportedCustomerListSegment: true,
  enableHPFeatureImprovements: true,
  enableETicketCheckinEndTime: true,
  enableFieldResponseImageUpload: true,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enableFixedExpirationDateTime: true,
  enablePartnership: true,
  enableETicketRedemptionCount: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: true,
  enableMemberLimitedProduct: true,
  enableUIRevampForRelease: true,
  enableMaRevamp: true,
  enableAnnualPassDashboard: true,
  enableNewsletterNewDesign: true,
  enableConsolidatedMarketingAutomationContent: true,
  enablePickupLocation: true,
  enableMobileOrder: true,
  enableNewsletterCustomEditor: true,
  enableSiteControlWidget: true,
  enableOnSiteJourneyAnalytics: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enableETicketPresetRedemptionCount: true,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableWaitTimePages: true,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: true,
  enableDigitalMapGuestJourneys: true,
  enableConversionPath: true,
  enableDigitalMapSEO: true,
  enableMultifloorMaps: true,
  enableMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: true,
  enableMapOpenTableSettings: true,
  enableMapUberButtonSettings: true,
};

// In "release" mode, only features ready for production release are enabled.
export const releaseFeatureFlags: FeatureFlags = {
  enableNtaProductSettings: false,
  enableGoogleMyBusinessFeature: false,
  enableBookInDigitalGuidance: true,
  enableProductMinimumPrice: false,
  enableResourceManagementVehicle: false,
  enableETicketOptionQRCode: true,
  enableResourceManagementCalendar: false,
  enableSeatManagement: true,
  enableCrossSellUpSell: false,
  enableETicketRedemptionTimeRelative: true,
  enableInstantWin: false,
  enableChannelManager: true,
  enableNotifications: false,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableUIRevamp: false,
  enableWaiverListEnhancement: true,
  enableSid: true,
  enableETicketBarCode: false,
  enablePMPCategoryHideSettings: true,
  enableImportedCustomerListSegment: true,
  enableHPFeatureImprovements: false,
  enableETicketCheckinEndTime: true,
  enableFieldResponseImageUpload: true,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enableFixedExpirationDateTime: true,
  enablePartnership: true,
  enableETicketRedemptionCount: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: false,
  enableMemberLimitedProduct: false,
  enableUIRevampForRelease: true,
  enableMaRevamp: false,
  enableAnnualPassDashboard: true,
  enableNewsletterNewDesign: true,
  enableConsolidatedMarketingAutomationContent: false,
  enablePickupLocation: true,
  enableMobileOrder: false,
  enableNewsletterCustomEditor: true,
  enableSiteControlWidget: true,
  enableOnSiteJourneyAnalytics: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enableETicketPresetRedemptionCount: false,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableWaitTimePages: false,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: false,
  enableDigitalMapGuestJourneys: false,
  enableConversionPath: false,
  enableDigitalMapSEO: true,
  enableMultifloorMaps: false,
  enableMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: false,
  enableMapOpenTableSettings: false,
  enableMapUberButtonSettings: false,
};

// stg feature flags are not ready for release but are enabled for demo purposes in the stg environment.
// This breaks our "production is the same as staging" testing so it should be used sparingly.
export const stgDemoFeatureFlags: Partial<FeatureFlags> = {
  enableBookInDigitalGuidance: true,
  enableSeatManagement: true,
  enableCrossSellUpSell: true,
  enableETicketRedemptionTimeRelative: true,
  enableShowFieldResponsesOnFootprintedModal: true,
  enableNotifications: true,
  enableChannelManager: true,
  enableSid: true,
  enableETicketBarCode: true,
  enableImportedCustomerListSegment: true,
  enablePMPCategoryHideSettings: true,
  enableETicketCheckinEndTime: true,
  enableFieldResponseImageUpload: true,
  enableBatchEditEquipmentInstanceAvailability: true,
  enableCreateReservationButtonForPassthroughSupplier: true,
  enableFixedExpirationDateTime: true,
  enablePartnership: true,
  enableETicketRedemptionCount: true,
  enableUndoEqauipmentTicketPrint: true,
  enableCustomerLedgerAddressInputForm: true,
  enableEmailCustomizationForLotteryMode: true,
  enableUIRevampForDemo: true,
  enableMemberLimitedProduct: true,
  enableUIRevampForRelease: true,
  enableMaRevamp: true,
  enableInstantWin: true,
  enableAnnualPassDashboard: true,
  enablePickupLocation: true,
  enableMobileOrder: true,
  enableConsolidatedMarketingAutomationContent: true,
  enableLineItemTable: true,
  enableNpsSurvey: true,
  enablePartnershipPackageProduct: true,
  enableETicketPresetRedemptionCount: true,
  enablePartnershipSystemFeeInvoiceTable: true,
  enableWaitTimePages: true,
  enableDynamicPackageProduct: true,
  enableMapCachedLinks: true,
  enableDigitalMapGuestJourneys: true,
  enableDigitalMapSEO: true,
  enableMultifloorMaps: true,
  enableMapHeaderMenuSettings: true,
  enableMapLanguageMenuSettings: true,
  enableMapOpenTableSettings: true,
  enableMapUberButtonSettings: true,
};
