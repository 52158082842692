import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from 'client/components/v3/Form/Modal';
import { ToggleableDragAndDropList } from 'client/components/v3/ToggleableDragAndDropList/ToggleableDragAndDropList';
import { Button } from 'client/components/v3/Common/Button';

import { ColumnId, useColumns } from './JourneyAnalyticsConversionPath';

interface Props {
  onClose: () => void;
  columns: ColumnId[];
  onColumnsChange: (columns: ColumnId[]) => void;
}

export const ColumnSettingsModal = ({
  onClose,
  columns,
  onColumnsChange,
}: Props) => {
  const { t } = useTranslation();
  const columnCandidates = useColumns({});

  const [selectedItems, setSelectedItems] = React.useState<
    { key: ColumnId; text: string }[]
  >(
    columns.map((column) => ({
      key: column,
      text:
        columnCandidates.find((candidate) => candidate.id === column)?.Header ??
        '',
    }))
  );

  return (
    <Modal
      title={t('Edit Columns')}
      subtitle={t('Customize which columns to show and the display order')}
      open={true}
      onClose={onClose}
      rightActionChildren={
        <>
          <Button
            text={t('Cancel')}
            size="md"
            color="white"
            onClick={onClose}
          />
          <Button
            text={t('Save')}
            onClick={async () => {
              onColumnsChange([
                'path', // Default column
                ...selectedItems.map((item) => item.key),
              ]);
              onClose();
            }}
          />
        </>
      }
    >
      <ToggleableDragAndDropList
        fixedItems={[{ key: 'name', text: t('Name') }]}
        selectedItems={selectedItems}
        setSelectedItems={(selectedItems) => setSelectedItems(selectedItems)}
        candidateItems={columnCandidates.map((column) => ({
          key: column.id,
          text: column.Header,
        }))}
      />
    </Modal>
  );
};
