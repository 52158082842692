import * as React from 'react';

import { withQueryParams } from '../util';

export interface Props {
  children: React.ReactNode;
  newsletterCampaignId?: string;
  topPageUrl?: string;
  unsubscribeUrl?: string;
  logoUrl: string;
  supplierName: string;
  supplierEmail: string;
  supplierOfficeAddress: string;
  supplierOfficePhone: string;
  supplierOfficeHours: string;
  inquiryHtml?: string;
  unsubscribeText?: string;
  poweredByText: string;
  twitterId?: string;
  instagramId?: string;
  youtubeId?: string;
  facebookId?: string;
  lineId?: string;
  language?: string;
}

export const BaseTemplate = ({
  children,
  newsletterCampaignId,
  topPageUrl,
  unsubscribeUrl,
  logoUrl,
  supplierName,
  supplierEmail,
  supplierOfficeAddress,
  supplierOfficePhone,
  supplierOfficeHours,
  inquiryHtml,
  unsubscribeText,
  poweredByText,
  twitterId,
  instagramId,
  youtubeId,
  facebookId,
  lineId,
  language,
}: Props) => {
  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        fontFamily: 'MS Pゴシック,メイリオ,Meiryo,Arial',
        backgroundColor: '#ffffff',
        color: '#333333',
        lineHeight: '1.5em',
      }}
    >
      <table
        cellSpacing={0}
        cellPadding={0}
        width="100%"
        style={{ backgroundColor: '#F4F4F5' }}
      >
        <tbody>
          <tr style={{ margin: 0, padding: 0 }}>
            <td valign="top" style={{ padding: '.25em' }}>
              <table
                cellSpacing={0}
                cellPadding={0}
                style={{ margin: '1em auto', maxWidth: '600px' }}
              >
                <tbody>
                  {/* ヘッダー */}
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td style={{ padding: '0.5em' }}>
                      <div style={{ padding: '1em 0', textAlign: 'center' }}>
                        <a
                          href={withQueryParams(topPageUrl ?? '', {
                            utm_source: 'ntmg.newsletter',
                            utm_medium: 'email',
                            utm_campaign: newsletterCampaignId ?? 'todo',
                            utm_content: 'logo',
                            lng: language ?? '',
                          })}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={logoUrl}
                            alt={supplierName}
                            style={{ width: 'auto', height: '48px' }}
                          />
                        </a>
                      </div>
                    </td>
                  </tr>
                  {children}
                  {/* Space */}
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td style={{ height: '1em' }} />
                  </tr>
                  {/* Supplier info 事業者情報 */}
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td>
                      <table
                        cellSpacing={0}
                        cellPadding={0}
                        width="100%"
                        style={{
                          paddingTop: '1.5em',
                          paddingLeft: '1.5em',
                          paddingRight: '1.5em',
                          backgroundColor: '#fff',
                        }}
                      >
                        <tbody>
                          <tr style={{ margin: 0, padding: 0 }}>
                            <td
                              style={{
                                padding: '1em',
                                backgroundColor: '#F4F4F5',
                                borderRadius: '8px',
                              }}
                            >
                              <dl style={{ margin: 0, padding: 0 }}>
                                <dt
                                  style={{
                                    fontWeight: 'bold',
                                    paddingBottom: '0.5em',
                                    borderBottom: '1px solid #E4E4E7',
                                  }}
                                >
                                  {supplierName}
                                </dt>
                                <dd
                                  style={{
                                    marginTop: '0.75em',
                                    marginLeft: '0em',
                                  }}
                                >
                                  {/* TODO: email icon */}
                                  <a
                                    href={`mailto:${supplierEmail}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {supplierEmail}
                                  </a>
                                </dd>
                                <dd
                                  style={{
                                    marginTop: '0.5em',
                                    marginLeft: '0em',
                                  }}
                                >
                                  {/* TODO: address pin icon */}
                                  {supplierOfficeAddress}
                                </dd>
                                <dd
                                  style={{
                                    marginTop: '0.5em',
                                    marginLeft: '0em',
                                  }}
                                >
                                  {/* TODO: telephone icon */}
                                  <a
                                    href={`tel:${supplierOfficePhone}`}
                                    style={{ marginRight: '0.5em' }}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {supplierOfficePhone}
                                  </a>
                                  (
                                  <span style={{ marginTop: '0.25em' }}>
                                    {supplierOfficeHours})
                                  </span>
                                </dd>
                              </dl>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* Inquiry / unsubscribe info */}
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td>
                      <div
                        style={{
                          margin: 0,
                          padding: '1em',
                          backgroundColor: '#ffffff',
                        }}
                      >
                        <div
                          style={{
                            margin: '1em 0 2em 0',
                            padding: 0,
                            textAlign: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: inquiryHtml
                              ? inquiryHtml.replace(
                                  /<a(.*?)>/g,
                                  '<a$1 style="color: #008ec9; text-decoration: underline;">'
                                )
                              : '',
                          }}
                        />
                        <div style={{ textAlign: 'center' }}>
                          {twitterId && (
                            <a
                              href={`https://x.com/${twitterId}`}
                              target="_blank"
                              style={{
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .25em',
                              }}
                              rel="noreferrer"
                            >
                              <img
                                src="https://assets.ntmg.com/newsletter/ic_sns_x.png"
                                style={{ width: '100%' }}
                              />
                            </a>
                          )}
                          {instagramId && (
                            <a
                              href={`https://www.instagram.com/${instagramId}`}
                              target="_blank"
                              style={{
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .25em',
                              }}
                              rel="noreferrer"
                            >
                              <img
                                src="https://assets.ntmg.com/newsletter/ic_sns_instagram.png"
                                style={{ width: '100%' }}
                              />
                            </a>
                          )}
                          {youtubeId && (
                            <a
                              href={`https://www.youtube.com/channel/${youtubeId}`}
                              style={{
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .25em',
                              }}
                            >
                              <img
                                src="https://assets.ntmg.com/newsletter/ic_sns_youtube.png"
                                style={{ width: '100%' }}
                              />
                            </a>
                          )}
                          {facebookId && (
                            <a
                              href={`https://www.facebook.com/${facebookId}`}
                              target="_blank"
                              style={{
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .25em',
                              }}
                              rel="noreferrer"
                            >
                              <img
                                src="https://assets.ntmg.com/newsletter/ic_sns_facebook.png"
                                style={{ width: '100%' }}
                              />
                            </a>
                          )}
                          {lineId && (
                            <a
                              href={lineId}
                              target="_blank"
                              style={{
                                width: '40px',
                                height: '40px',
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                margin: '0 .25em',
                              }}
                              rel="noreferrer"
                            >
                              <img
                                src="https://assets.ntmg.com/newsletter/ic_sns_line.png"
                                style={{ width: '100%' }}
                              />
                            </a>
                          )}
                        </div>
                        <div
                          style={{
                            margin: '2em 0',
                            padding: 0,
                            textAlign: 'center',
                          }}
                        >
                          <a
                            href={unsubscribeUrl}
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: '#008ec9',
                              textDecoration: 'underline',
                            }}
                          >
                            {unsubscribeText}
                          </a>
                        </div>
                      </div>
                    </td>
                  </tr>
                  {/* フッター */}
                  <tr style={{ margin: 0, padding: 0 }}>
                    <td>
                      <div
                        style={{
                          margin: 0,
                          padding: '2em 0.5em',
                          textAlign: 'center',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '14px',
                            color: '#71717A',
                            textAlign: 'center',
                          }}
                        >
                          {poweredByText}
                        </p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
