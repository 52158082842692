import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';

import styles from './ETicketEvent.module.css';

interface Props {
  event: Exclude<
    Exclude<Swagger.AnalyticsCustomer['sessions'], undefined>[number]['events'],
    undefined
  >[number];
  durationInSeconds: number;
}

export const LocationEvent = ({ event, durationInSeconds }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['color-label']} />
        <div className={styles['timeline']}>
          <div className={styles['icon']}>
            <i className="c-icon-solid-general-eye"></i>
          </div>

          <div className={styles['duration']}>
            {formattedDuration(durationInSeconds, t)}
          </div>
        </div>
        <div className={styles['content']}>
          <div className={styles['header']}>
            <div className={styles['title']}>{t('Location')}</div>
            <div className={styles['timestamp']}>
              {moment(event.event_date_time_utc).format('H:mm:ss')}
            </div>
          </div>
          {event.latitude && event.longitude && (
            <div>
              {t('Latitude/Longitude {{latitude}}/{{longitude}}', {
                latitude: event.latitude.toFixed(6),
                longitude: event.longitude.toFixed(6),
              })}
            </div>
          )}
          <div className={styles['body']}>
            <div className={styles['body-inner']}>
              <div className={styles['description']}>
                <div className={styles['page-title']}>移動しました</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
