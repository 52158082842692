import { useTranslation } from 'react-i18next';
import { Field } from 'react-final-form';
import { useCallback } from 'react';

import { Button } from 'client/components/v3/Common/Button';
import { ListEditor } from 'client/components/v3/Form/ListEditor/ListEditor';
import { TextField } from 'client/components/v3/Form/TextField';
import { Badge } from 'client/components/v3/Common/Badge';
import { getValidators } from 'shared/libraries/validate/validator';

import styles from './ProvinceCityNameEditor.module.css';

type Props = {
  name: string;
  onDeleteButtonClick: () => void;
};

export const ProvinceCityNameEditor = ({
  name,
  onDeleteButtonClick,
}: Props) => {
  const { t } = useTranslation();
  const { required } = getValidators(t);

  const validateEmptyValue = useCallback(
    (value?: any) => {
      if (!value) return t('Required');

      return undefined;
    },
    [t]
  );

  return (
    <li
      className={styles['p-areaGroupSettingsModal__provinceCity__list__item']}
    >
      <div className={styles['p-areaGroupSettingsModal__box']}>
        <div>
          <p
            className={
              styles['p-areaGroupSettingsModal__provinceCity__list__item__ttl']
            }
            style={{ marginBottom: '8px' }}
          >
            <p>{t('State / Province / Region')}</p>
            <Badge size="sm" label={t('Required')} color="warning-contained" />
          </p>
          <div>
            <Field name={`${name}.province`} validate={required}>
              {({ input, meta: { error, touched } }) => {
                return (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                    required={true}
                    error={touched && error ? error : undefined}
                  />
                );
              }}
            </Field>
            <div className={styles['p-areaGroupSettingsModal__box']}>
              <p>{t('City / Ward / Town')}</p>
              <div>
                <ListEditor
                  name={`${name}.cities`}
                  validator={validateEmptyValue}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <Button
            size="icon"
            color="tertiarygray"
            onClick={onDeleteButtonClick}
            iconBeforeText={<i className="c-icon-outline-general-trash-03"></i>}
          />
        </div>
      </div>
    </li>
  );
};
