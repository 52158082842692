import * as React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';

import { config } from 'client/config';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { updateOrganization } from 'client/actions/organizations';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { matchesFormat } from 'shared/libraries/validate/validator';
//import baseStyles from 'client/v3-base.module.css';
import styles from 'client/pages/v3/BookingWidget/WebSiteIntegration/WebSiteIntegration.module.css';

interface FormValues {
  customDomain: string;
}

export const CustomDomainTab = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const organization = useSelector(activeUserOrganizationSelector);

  const validateFqdn = React.useCallback(
    (fqdn?: any) => {
      if (!fqdn) return undefined;

      if (!matchesFormat(fqdn, 'fqdn')) {
        return t(
          'Invalid domain. Please check to make sure your domain does not contain "http://" or spaces.'
        );
      }

      return undefined;
    },
    [t]
  );

  return (
    <>
      <div
        className={clsx(
          styles['p-inquiry-tabs__tab-panel'],
          styles['components_is-active__B15DT']
        )}
        style={{ display: 'block' }}
      >
        <div className={styles['p-inquiry-tabs__content']}>
          <ul className={styles['p-inquiry-list']}>
            <li className={styles['p-inquiry-list__item']}>
              <div className={styles['p-inquiry-list__item__ttl']}>
                <div className={styles['p-inquiry-list__item__ttl__txt']}>
                  <div>{t('Step 1:')}</div>
                </div>
              </div>
              <div className={styles['p-inquiry-list__item__body']}>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  {t(
                    'Set up an A record with your DNS provider. If using a root domain:'
                  )}
                </div>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <p>{`@    A    ${config.bookingWidgetIpAddress}`}</p>
                </div>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <div>{t('If using a subdomain:')}</div>
                </div>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <div>{`<subdomain>    A    ${config.bookingWidgetIpAddress}`}</div>
                </div>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <a
                    className="c-button-link-sm-color"
                    href="https://moz.com/learn/seo/domain"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <p style={{ fontWeight: 'var(--text-semibold)' }}>
                      {t('What are subdomains and root domains?')}
                    </p>
                    <i className="c-icon-outline-general-link-external-02"></i>
                  </a>
                </div>
              </div>
            </li>
            <li className={styles['p-inquiry-list__item']}>
              <div className={styles['p-inquiry-list__item__ttl']}>
                <div className={styles['p-inquiry-list__item__ttl__txt']}>
                  <div>{t('Step 2:')}</div>
                </div>
              </div>
              <div className={styles['p-inquiry-list__item__body']}>
                <div className={styles['p-inquiry-list__item__body__item']}>
                  <div>
                    {t(
                      'Enter your full custom domain name in the "Custom Domain" field and press "Save".'
                    )}
                  </div>
                  <div className={styles['p-inquiry-list__item__body__item']}>
                    {t(
                      'Once steps 1 and 2 are completed, your booking widget will usually be live at your custom domain within 1-3 hours.'
                    )}
                  </div>
                  <div
                    className={styles['p-inquiry-list__item__body__item']}
                    style={{ width: '100%' }}
                  >
                    <Form
                      initialValues={{
                        customDomain:
                          organization?.booking_widget_custom_domain ?? '',
                      }}
                      onSubmit={async (values: FormValues) => {
                        await dispatch(
                          updateOrganization(
                            organization?.id || '',
                            'SUPPLIER',
                            {
                              booking_widget_custom_domain:
                                values.customDomain || '',
                            }
                          )
                        );
                      }}
                    >
                      {({
                        handleSubmit,
                        submitting,
                        submitSucceeded,
                        submitError,
                        modifiedSinceLastSubmit,
                      }) => (
                        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                          <Field name="customDomain" validate={validateFqdn}>
                            {({ input, meta: { touched, error } }) => (
                              <TextField
                                label={t('Custom Domain')}
                                value={input.value}
                                onChange={input.onChange}
                                error={touched && error}
                              />
                            )}
                          </Field>
                          {submitSucceeded && !modifiedSinceLastSubmit && (
                            <Snackbar
                              color={'success'}
                              text={t('Save Successful')}
                              shouldShow={submitSucceeded}
                            />
                          )}
                          {submitError && !modifiedSinceLastSubmit && (
                            <Snackbar
                              color={'error'}
                              text={t('Save Failed')}
                              shouldShow={submitError}
                            />
                          )}

                          <div
                            className={clsx(
                              styles['p-products__fixed'],
                              styles['is-active']
                            )}
                          >
                            <div
                              className={styles['p-products__fixed__actions']}
                            >
                              <Button
                                text={t('Save')}
                                type="submit"
                                color="primary"
                                size="md"
                                loading={submitting}
                                style={{
                                  width: '60px',
                                  fontWeight: 'var(--text-semibold)',
                                }}
                              />
                            </div>
                          </div>
                        </form>
                      )}
                    </Form>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
