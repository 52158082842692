import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import styles from 'client/pages/v3/Customer/CustomerSettings/Tabs/TagSettings/TagSettings.module.css';
import baseStyles from 'client/v3-base.module.css';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Snackbar } from 'client/components/v3/Common/Snackbar';
import { Badge } from 'client/components/v3/Common/Badge';
import { v3CustomerTagColors } from 'client/libraries/util/colors';
import { Pagination } from 'client/components/v3/Pagination/Pagination';
import { ReduxState } from 'client/reducers';
import { CustomerAreaGroup } from 'shared/models/swagger';

import { ActionButton } from './ActionButton';
import { EditTagModal } from './EditTagModal';
import { getConditionDescription } from './util';
import { SwaggerAutoTag, convertSwaggerTagToFormValues } from './FormValues';

export const TagSettings = () => {
  const { t } = useTranslation();
  const [saveSucceeded, setSaveSucceeded] = useState<boolean>(false);
  const [editingTagIndex, setEditingTagIndex] = useState<number | null>(null);
  const activeOrganization = useSelector(activeUserOrganizationSelector);
  const tags = (activeOrganization?.customer_ledger_settings
    ?.customer_auto_tags ?? []) as SwaggerAutoTag[];
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );
  const areaGroups = (activeOrganization?.customer_ledger_settings
    ?.customer_area_groups ?? []) as CustomerAreaGroup[];

  // Table handler states and functions
  const [rowCount, setRowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageItems, setPageItems] = useState<SwaggerAutoTag[]>([]);
  const pageCount =
    Math.floor(tags.length / rowCount) + (tags.length % rowCount ? 1 : 0);
  const pageForwardClickHandler = () => {
    if (currentPage < pageCount) {
      setCurrentPage(currentPage + 1);
    }
  };
  const pageBackClickHandler = () => {
    if (1 < currentPage) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    if (tags.length > 0) {
      const newPageItems = tags.slice(
        (currentPage - 1) * rowCount,
        currentPage * rowCount
      );
      setPageItems(newPageItems);
    } else {
      if (pageItems.length != 0) {
        setPageItems([]);
      }
    }
  }, [rowCount, currentPage, tags]);

  const getIndexOfTag = (index: number) => {
    return (currentPage - 1) * rowCount + index;
  };

  return (
    <div className={clsx(styles['p-tagSettings'])}>
      {saveSucceeded && (
        <Snackbar
          text={t('Settings Saved')}
          color="success"
          shouldShow={saveSucceeded}
        />
      )}
      <div className={styles['p-tagSettings__header']}>
        <p className={styles['p-tagSettings__header__ttl']}>
          {t('Tag Settings')}
        </p>
        <div className={styles['p-tagSettings__header__actions']}>
          <Button
            text={t('Create Tag')}
            onClick={() => {
              setEditingTagIndex(tags.length);
            }}
          />
        </div>
      </div>
      <div className={styles['p-tagSettings__body']}>
        {/* Note: initially intended to use PagedGenericTable, but due to clash of action button menu popup and table overflow,
            I decided to create table manually instead */}
        <table
          className={clsx(tableStyles['c-tableSmall'], tableStyles['row'])}
        >
          <thead>
            <tr>
              <th className={baseStyles['u-width-288']}>{t('Tag')}</th>
              <th>{t('Conditions')}</th>
              <th className={baseStyles['u-width-64']}>{t('Action')}</th>
            </tr>
          </thead>
          {(tags.length === 0 || pageItems.length === 0) && (
            <tbody>
              <tr>
                <td colSpan={3} className={styles['noData']}>
                  {t(
                    'There are no items that match the specified search conditions'
                  )}
                </td>
              </tr>
            </tbody>
          )}
          {pageItems.length > 0 && (
            <tbody>
              {pageItems.map((tag, index) => {
                const color = v3CustomerTagColors.find(
                  (tagColor) => tagColor.value === tag.color
                );
                return (
                  <tr key={index}>
                    <td data-text={t('Tag')}>
                      <Badge
                        label={tag.name ?? ''}
                        backgroundColor={color?.backgroundColor}
                        borderColor={color?.borderColor}
                        textColor={color?.color}
                      />
                    </td>
                    <td
                      data-text={t('Conditions')}
                      dangerouslySetInnerHTML={{
                        __html: getConditionDescription(
                          convertSwaggerTagToFormValues(tag),
                          t,
                          allProducts,
                          areaGroups
                        ),
                      }}
                    ></td>
                    <td data-text={t('Action')}>
                      <ActionButton
                        tag={tag}
                        lastItemOnList={index + 1 === tags.length}
                        onEdit={() => {
                          setEditingTagIndex(getIndexOfTag(index));
                        }}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </div>
      <div className={styles['p-tagSettings__footer']}>
        <Pagination
          totalItems={tags.length}
          currentPage={currentPage}
          pageCount={pageCount}
          selectedNumberOfLinesToDisplay={rowCount}
          onChangeNumberOfLinesToDisplay={setRowCount}
          onClickPrevButton={pageBackClickHandler}
          onClickNextButton={pageForwardClickHandler}
        />
      </div>

      {editingTagIndex !== null && (
        <EditTagModal
          tagIndex={editingTagIndex}
          onClose={() => {
            setEditingTagIndex(null);
          }}
          onSubmitSuccess={setSaveSucceeded}
        />
      )}
    </div>
  );
};
