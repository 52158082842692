import * as React from 'react';
import moment from 'moment-timezone';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useSelector } from 'react-redux';

import { currency } from 'shared/libraries/currency';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { ReduxState } from 'client/reducers';
import { CustomerEventReportDataItem } from 'shared/models/swagger';
import { Loading } from 'client/components/v3/Common/Loading';

import { CustomTooltip } from './CustomTooltip';
import styles from './GraphGadget.module.css';

type GraphDataType =
  | 'SALES'
  | 'CONVERSIONS'
  | 'PAGE_VIEWS'
  | 'SESSIONS'
  | 'CONVERSION_RATE'
  | 'AVERAGE_SESSION_COUNT'
  | 'ADD_TO_CART_COUNT'
  | 'AVERAGE_LEAD_TIME';

const getDataValue = (
  item: CustomerEventReportDataItem | undefined,
  dataType: GraphDataType
): number | undefined => {
  if (!item) {
    return undefined;
  }
  switch (dataType) {
    case 'SALES':
      return currency(item?.gross_sales ?? '').value;
    case 'CONVERSIONS':
      return item?.conversion_count ?? 0;
    case 'PAGE_VIEWS':
      return item?.page_view_count ?? 0;
    case 'SESSIONS':
      return item?.session_count ?? 0;
    case 'CONVERSION_RATE':
      return item?.session_count && item?.conversion_count
        ? (100 * item?.conversion_count) / item?.session_count
        : 0;
    case 'AVERAGE_SESSION_COUNT':
      return item?.session_count && item?.unique_user_count
        ? (100 * item?.session_count) / item?.unique_user_count
        : 0;
    case 'ADD_TO_CART_COUNT':
      return item?.add_to_cart_count && item?.session_count
        ? (100 * item?.add_to_cart_count) / item?.session_count
        : 0;
    case 'AVERAGE_LEAD_TIME':
      return item?.total_lead_time_in_minutes && item?.conversion_count
        ? item.total_lead_time_in_minutes / item.conversion_count / (60 * 24)
        : 0;
  }
};

export const GraphGadget = () => {
  const { t } = useTranslation();

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const [graphDataType, setGraphDataType] =
    React.useState<GraphDataType>('SALES');

  const reportDataSets = useSelector(
    (state: ReduxState) => state.customerEvents.reportData
  );

  const loading = useSelector(
    (state: ReduxState) => state.customerEvents.loading
  );

  const data = React.useMemo(() => {
    if (!reportDataSets?.length) {
      return [];
    }
    if (reportDataSets?.length === 1) {
      return reportDataSets[0].items?.map((item) => ({
        date: moment(item.date, 'YYYY-MM-DD').locale(locale).format('l'),
        [t('Base')]: getDataValue(item, graphDataType),
      }));
    }

    return _.zip(
      reportDataSets[0].items ?? [],
      reportDataSets[1].items ?? []
    ).map((pair) => {
      return {
        date: `${moment(pair[0]?.date, 'YYYY-MM-DD')
          .locale(locale)
          .format('l')}`,
        compareDate: `${moment(pair[1]?.date, 'YYYY-MM-DD')
          .locale(locale)
          .format('l')}`,
        [t('Base')]: getDataValue(pair[0], graphDataType),
        [t('Comparison')]: getDataValue(pair[1], graphDataType),
      };
    });
  }, [graphDataType, reportDataSets, t]);

  const options = [
    { value: 'SALES', text: t('Sales') },
    { value: 'CONVERSIONS', text: t('Conversions') },
    { value: 'PAGE_VIEWS', text: t('Page Views') },
    { value: 'SESSIONS', text: t('Sessions') },
    { value: 'CONVERSION_RATE', text: t('Conversion Rate') },
    {
      value: 'AVERAGE_SESSION_COUNT',
      text: t('Average Session Count'),
    },
    { value: 'ADD_TO_CART_COUNT', text: t('Add-to-Cart Rate') },
    { value: 'AVERAGE_LEAD_TIME', text: t('Average Lead Time') },
  ];
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <div className={styles['header-inner']}>
          <div className={styles['header-text']}>
            {t('State Transition Graph')}
          </div>
          <div className={styles['header-select']}>
            <SingleDropdown
              label={t('Data Type')}
              options={options}
              selectedOption={graphDataType}
              onChange={(value) => setGraphDataType(value as GraphDataType)}
            />
          </div>
        </div>
      </div>
      <div className={styles['graph-container']}>
        {loading ? (
          <Loading size="md" />
        ) : (
          <ResponsiveContainer width="100%" height="100%" debounce={300}>
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <RechartsTooltip
                content={
                  <CustomTooltip
                    dataTypeName={
                      options.find((o) => o.value === graphDataType)?.text
                    }
                  />
                }
              />
              <Legend />
              <Line
                type="monotone"
                dataKey={t('Base')}
                stroke="#3B82F6"
                activeDot={{ r: 8 }}
              />
              {reportDataSets?.length > 1 && (
                <Line
                  type="monotone"
                  dataKey={t('Comparison')}
                  stroke="#EC4899"
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};
