import * as React from 'react';
import _ from 'lodash';
import { Field, Form, FormSpy } from 'react-final-form';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';

import {
  Button,
  FieldWrapper,
  Input,
  MultiSelect,
  Select,
  ToggleButton,
} from 'client/components/Form';
import { Tooltip } from 'client/components/v3/Common/Tooltip';
import { Box } from 'client/components/Box/Box';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { createDigitalMap, updateDigitalMap } from 'client/actions/digitalMaps';
import { Edit } from 'client/components/Icons/Edit';
import { productOptionsSelector } from 'client/reducers/products';
import { useGoogleMapsApi } from 'client/hooks/useGoogleMapsApi';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { config } from 'client/config';

import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
  calculateDefaultMapCenter,
  MenuItem,
} from './formValues';
import { OverlayEditor } from './OverlayEditor';
import { GoogleMapView } from './GoogleMapView';

export const DigitalMapBasicsEditor = () => {
  const [activeFloor, setActiveFloor] = React.useState(1);
  const [customizeMenuItems, setCustomizeMenuItems] = React.useState(false);
  const { t } = useTranslation();
  const store = useStore<ReduxState>();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoaded } = useGoogleMapsApi();

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );
  const stampRallies = useSelector(
    (state: ReduxState) => state.guidanceStampRallies.all
  );
  const productOptions = useSelector(productOptionsSelector);

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  React.useEffect(() => {
    if (
      (existingDigitalMap?.display_settings?.menu_items?.length ?? 0) > 0 &&
      ['MAP', 'MY_ACCOUNT', 'TICKETS', 'SCHEDULE', 'STAMP_RALLY'].some((item) =>
        existingDigitalMap?.display_settings?.menu_items?.includes(item as any)
      )
    ) {
      setCustomizeMenuItems(true);
    }
  }, [existingDigitalMap]);

  const menuItemOptions: { value: MenuItem; label: string }[] = [
    { value: 'MAP', label: t('Map') },
    { value: 'MY_ACCOUNT', label: t('My Account') },
    { value: 'TICKETS', label: t('Tickets') },
    { value: 'SCHEDULE', label: t('Schedule') },
    { value: 'STAMP_RALLY', label: t('Stamp Rally') },
  ];

  return (
    <Form<FormValues>
      onSubmit={async (values: FormValues) => {
        if (id) {
          await dispatch(
            updateDigitalMap(id, convertFormValuesToSwagger(values))
          );
        } else {
          await dispatch(createDigitalMap(convertFormValuesToSwagger(values)));

          const newMapId =
            store.getState().digitalMaps.lastCreatedDigitalMap?.id;
          history.push(`/maps/${newMapId}/edit`);
        }
      }}
      initialValues={initialValues}
      mutators={getArrayMutators()}
      debug={console.log}
    >
      {({
        form,
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          {submitSucceeded && !modifiedSinceLastSubmit && (
            <Message success header={t('Save Successful')} />
          )}
          {submitError && !modifiedSinceLastSubmit && (
            <Message error header={t('Save Failed')} />
          )}
          <div className={baseStyles['base-main__body__box']}>
            <div className={baseStyles['base-main__body__box__body']}>
              <Field name="name">
                {({ input }) => <Input label={t('Name')} {...input} />}
              </Field>
              <Field name="disableIncludingMapLinkInCustomerEmails">
                {({ input }) => (
                  <Box mt={2}>
                    <ToggleButton
                      label={t(
                        'Include map link in customer reservation emails'
                      )}
                      checked={!input.value}
                      onChange={() => input.onChange(!input.value)}
                    />
                  </Box>
                )}
              </Field>
              {!values.disableIncludingMapLinkInCustomerEmails && (
                <Field name="productIds">
                  {({ input }) => (
                    <Box mt={2}>
                      <MultiSelect
                        label={t(
                          'Linked Products (include map link in reservation emails for these products)'
                        )}
                        selectedValues={input.value}
                        onChange={({ value }) => input.onChange(value)}
                        options={productOptions}
                        search
                      />
                    </Box>
                  )}
                </Field>
              )}
              {stampRallies.length > 0 && (
                <Box mt={2}>
                  <Field name="stampRallyId">
                    {({ input }) => (
                      <FieldWrapper label={t('Stamp rally')}>
                        <Select
                          options={[
                            {
                              value: '',
                              text: t('None'),
                            },
                            ...(stampRallies.map((stampRally) => ({
                              value: stampRally.id ?? '',
                              text: stampRally.title ?? '',
                            })) ?? []),
                          ]}
                          value={input.value ?? ''}
                          onChange={(e, { value }) => input.onChange(value)}
                        />
                      </FieldWrapper>
                    )}
                  </Field>
                </Box>
              )}
              <Field name="ga4AnalyticsTag">
                {({ input }) => (
                  <Box mt={2}>
                    <Input label={t('GA4 Analytics Tag')} {...input} />
                  </Box>
                )}
              </Field>
              {config.enableMapUberButtonSettings && (
                <Field name="showUberButtonForPins">
                  {({ input }) => (
                    <Box mt={2}>
                      <ToggleButton
                        label={t('Show Uber button for pins')}
                        checked={input.value}
                        onChange={() => input.onChange(!input.value)}
                      />
                    </Box>
                  )}
                </Field>
              )}
              <Field name="showQrCodeButton">
                {({ input }) => (
                  <Box mt={2}>
                    <ToggleButton
                      label={t('Show QR code button')}
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                    />
                  </Box>
                )}
              </Field>
              {config.enableMapHeaderMenuSettings && (
                <Box mt={2} mb={2}>
                  <ToggleButton
                    label={t('Customize Header Menu Items')}
                    checked={customizeMenuItems}
                    onChange={() => {
                      if (customizeMenuItems) {
                        form.change('menuItems', [
                          'MAP',
                          'MY_ACCOUNT',
                          'TICKETS',
                          'SCHEDULE',
                          'STAMP_RALLY',
                        ]);
                      }
                      setCustomizeMenuItems(!customizeMenuItems);
                    }}
                  />
                </Box>
              )}
              {customizeMenuItems && (
                <Box mt={2} mb={2}>
                  <FieldWrapper label={t('Menu Items')}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      {menuItemOptions.map((option) => (
                        <Field key={option.value} name="menuItems">
                          {({ input: { onChange } }) => (
                            <Checkbox
                              label={option.label}
                              checked={values.menuItems.includes(option.value)}
                              onChange={() => {
                                const newMenuItems = values.menuItems.includes(
                                  option.value
                                )
                                  ? values.menuItems.filter(
                                      (item) => item !== option.value
                                    )
                                  : [...values.menuItems, option.value];
                                onChange(newMenuItems);
                              }}
                            />
                          )}
                        </Field>
                      ))}
                    </Box>
                  </FieldWrapper>
                </Box>
              )}
              {config.enableMultifloorMaps && (
                <Field name="floorCount">
                  {({ input }) => (
                    <Box mt={2} mb={2}>
                      <Select
                        label={t('Number of Floors')}
                        options={Array.from({ length: 10 }, (_, i) => ({
                          value: (i + 1).toString(),
                          text: `${i + 1}`,
                        }))}
                        value={input.value.toString()}
                        onChange={(e, { value }) => {
                          const newFloorCount = parseInt(value, 10);
                          input.onChange(newFloorCount);

                          // Ensure we have the correct number of overlays
                          const currentOverlays = values.overlays || [];
                          const newOverlays = [...currentOverlays];

                          // Add overlays if needed
                          while (newOverlays.length < newFloorCount) {
                            newOverlays.push({
                              floorNumber: newOverlays.length + 1,
                              imageUrl: '',
                              topLeft: { latitude: 0, longitude: 0 },
                              widthInMeters: 0,
                              aspectRatio: { width: 0, height: 0 },
                            });
                          }

                          // Remove overlays if needed
                          while (newOverlays.length > newFloorCount) {
                            newOverlays.pop();
                          }

                          form.change('overlays', newOverlays);
                        }}
                      />
                    </Box>
                  )}
                </Field>
              )}
              <Field name="useOverlayImage">
                {({ input }) => (
                  <Box mt={2} mb={2}>
                    <ToggleButton
                      label={t('Use Overlay Image')}
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                    />
                  </Box>
                )}
              </Field>
              {values.useOverlayImage && (
                <>
                  <Field name="useOverlayTiling">
                    {({ input }) => (
                      <Box mb={2} display="flex" alignItems="center">
                        <ToggleButton
                          label={
                            <>
                              {t('Enable overlay tiling')}
                              <Box ml={1}>
                                <Tooltip
                                  text={t(
                                    'When enabled, the overlay image will be split into smaller images for efficient loading rather than loading a single large image.'
                                  )}
                                >
                                  <i className="c-icon-outline-general-info-circle"></i>
                                </Tooltip>
                              </Box>
                            </>
                          }
                          checked={input.value}
                          onChange={() => {
                            input.onChange(!input.value);
                          }}
                        />
                      </Box>
                    )}
                  </Field>
                  {values.useOverlayTiling && (
                    <Field name="enableHighResolutionTiles">
                      {({ input }) => (
                        <Box mt={2}>
                          <ToggleButton
                            label={t('Enable high resolution tiles')}
                            checked={input.value}
                            onChange={() => input.onChange(!input.value)}
                          />
                        </Box>
                      )}
                    </Field>
                  )}

                  <OverlayEditor
                    activeFloor={activeFloor}
                    floorCount={values.floorCount}
                    onFloorChange={setActiveFloor}
                  />
                </>
              )}
              {values.useOverlayImage && (
                <FormSpy<FormValues>
                  subscription={{ values: true }}
                  onChange={({ values }) => {
                    if (isLoaded && values) {
                      const currentMapCenter = values.mapCenter;
                      const calculatedMapCenter =
                        calculateDefaultMapCenter(values);
                      if (!_.isEqual(currentMapCenter, calculatedMapCenter)) {
                        form.change('mapCenter', calculatedMapCenter);
                      }
                    }
                  }}
                />
              )}
              {!values.useOverlayImage && (
                <Field name="mapCenter">
                  {({ input }) => (
                    <Box mb={4}>
                      <FieldWrapper label={t('Default Google Map Center')} />
                      <Box display="flex" alignItems="center">
                        {values.editingMode !== 'CENTER' && (
                          <div>
                            {`(${input.value.latitude}, ${input.value.longitude})`}
                          </div>
                        )}
                        {values.editingMode === 'NORMAL' && (
                          <Box ml={2}>
                            <Edit
                              onClick={() =>
                                form.change('editingMode', 'CENTER')
                              }
                            />
                          </Box>
                        )}
                        {values.editingMode === 'CENTER' && (
                          <Box display="flex" gap={1} alignItems="center">
                            <div>{t('Click on the map to change')}</div>
                            <Box ml={2}>
                              <Button.Create
                                onClick={() => {
                                  form.change('editingMode', 'NORMAL');
                                }}
                              >
                                {t('Done')}
                              </Button.Create>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Field>
              )}
              <Field name="mapZoom">
                {({ input }) => (
                  <Box mb={4}>
                    <FieldWrapper label={t('Default Google Map Zoom Level')} />
                    <Box display="flex" alignItems="center">
                      {values.editingMode !== 'ZOOM' && (
                        <div>{input.value}</div>
                      )}
                      {values.editingMode === 'NORMAL' && (
                        <Box ml={2}>
                          <Edit
                            onClick={() => form.change('editingMode', 'ZOOM')}
                          />
                        </Box>
                      )}
                      {values.editingMode === 'ZOOM' && (
                        <Box display="flex" gap={1} alignItems="center">
                          <div>{t('Adjust zoom on the map to change')}</div>
                          <Box ml={2}>
                            <Button.Create
                              onClick={() => {
                                form.change('editingMode', 'NORMAL');
                              }}
                            >
                              {t('Done')}
                            </Button.Create>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Field>
              <Field name="mapRotation">
                {({ input }) => (
                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    mb={2}
                  >
                    <Box mr={2}>
                      <Button
                        size="middle"
                        style="yellow"
                        onClick={() =>
                          input.onChange(
                            input.value < 5
                              ? 360 - (5 - input.value)
                              : (input.value - 5) % 360
                          )
                        }
                        width={30}
                      >
                        -5°
                      </Button>
                    </Box>
                    <Box width="100px">
                      <Input
                        label={t('Map Rotation')}
                        value={input.value}
                        onChange={(e, { value }) => {
                          try {
                            const newValue = parseInt(value, 10);

                            if (isNaN(newValue)) {
                              input.onChange(0);
                            }

                            if (newValue < 0 || newValue > 359) {
                              return;
                            }

                            input.onChange(newValue);
                          } catch (e) {
                            /* empty */
                          }
                        }}
                      />
                    </Box>
                    <Box ml={1}>
                      <Button
                        size="middle"
                        style="yellow"
                        onClick={() => input.onChange((input.value + 5) % 360)}
                        width={30}
                      >
                        +5°
                      </Button>
                    </Box>
                  </Box>
                )}
              </Field>
              {!values.useOverlayImage && (
                <GoogleMapView
                  disableAutoBounds
                  onClick={
                    values?.editingMode === 'TOP_LEFT'
                      ? (lat, lng) => {
                          form.change(
                            'background.topLeft.latitude' as any,
                            lat
                          );
                          form.change(
                            'background.topLeft.longitude' as any,
                            lng
                          );
                        }
                      : values?.editingMode === 'CENTER'
                      ? (lat, lng) => {
                          form.change('mapCenter.latitude' as any, lat);
                          form.change('mapCenter.longitude' as any, lng);
                        }
                      : undefined
                  }
                  pins={[
                    {
                      lat: values.mapCenter.latitude,
                      lng: values.mapCenter.longitude,
                    },
                  ]}
                  showOverlay={false}
                  heading={values.mapRotation}
                  zoom={values.mapZoom}
                  initialCenter={
                    existingDigitalMap?.default_map_center
                      ? {
                          latitude:
                            existingDigitalMap.default_map_center.latitude ?? 0,
                          longitude:
                            existingDigitalMap.default_map_center.longitude ??
                            0,
                        }
                      : undefined
                  }
                  onZoomChange={(zoom) => {
                    if (values.editingMode === 'ZOOM') {
                      form.change('mapZoom', zoom);
                    }
                  }}
                />
              )}

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  size="small"
                  style="green"
                  loading={submitting}
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};
