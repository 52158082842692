import * as React from 'react';
import querystring from 'query-string';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import type { ReduxState } from 'client/reducers';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { fetchProducts } from 'client/actions/products';
import { batchFetchEquipmentAssignmentsByProductInstanceIds } from 'client/actions/equipmentAssignments';
import { fetchReservations } from 'client/actions/reservations';
import {
  setSeatAssignmentDate,
  setSeatAssignmentEquipmentId,
  setSeatAssignmentStartTimeKey,
} from 'client/actions/seatAssignmentControls';
import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { fetchProductInstances } from 'client/actions/productInstances';
import { fetchEquipmentInstances } from 'client/actions/equipmentInstances';
import { ProductInstance } from 'shared/models/swagger';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import { fetchEquipments } from 'client/actions/equipments';
import { SeatContext } from 'client/contexts/SeatContext';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';

import {
  SeatAssignmentContext,
  SeatAssignmentModeType,
} from './SeatAssignmentContext';
import { SeatAssignmentSelector } from './SeatAssignmentSelector';
import { SeatAssignmentReservations } from './SeatAssignmentReservations';
import { SeatAssignmentMap } from './SeatAssignmentMap';
import { SeatAssignmentNotificationList } from './SeatAssignmentNotificationList';
import styles from './SeatAssignment.module.css';
import {
  getEquipmentStartTimeMapping,
  filterProductInstancesByProductMapping,
} from './utils';
import { MessageModal } from './MessageModal';
import { InternalMemoModal } from './InternalMemoModal';

export const SeatAssignment = () => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const dispatch = useDispatch();

  const equipments = useSelector(equipmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);

  const productInstanceById = useSelector(
    (state: ReduxState) => state.productInstances.byID
  );

  const participationDate = useSelector(
    (state: ReduxState) => state.seatAssignmentControls.date
  );
  const selectedEquipmentId = useSelector(
    (state: ReduxState) => state.seatAssignmentControls.equipmentId
  );

  const selectedStartTimeKey = useSelector(
    (state: ReduxState) => state.seatAssignmentControls.startTimeKey
  );

  const equipmentInstanceUpdateLoading = useSelector(
    (state: ReduxState) => state.equipmentInstances.updateLoading
  );

  const equipmentAssignmentLoading = useSelector(
    (state: ReduxState) => state.equipmentAssignments.loading
  );

  const setParticipationDate = (date: string) =>
    dispatch(setSeatAssignmentDate(date));

  const setSelectedEquipmentId = (equipmentId: string) =>
    dispatch(setSeatAssignmentEquipmentId(equipmentId));
  const setSelectedStartTimeKey = (startTimeKey: string) =>
    dispatch(setSeatAssignmentStartTimeKey(startTimeKey));

  const [selectedReservationId, setSelectedReservationId] =
    React.useState<string>('');
  const [selectedEquipmentInstanceId, setSelectedEquipmentInstanceId] =
    React.useState<string>('');
  const [
    selectedAssignableEquipmentInstanceId,
    setSelectedAssignableEquipmentInstanceId,
  ] = React.useState<string>('');
  const [draggingReservationId, setDraggingReservationId] =
    React.useState<string>('');
  const [
    selectedEquipmentBlockInstanceKeys,
    setSelectedEquipmentBlockInstanceKeys,
  ] = React.useState<
    {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    }[]
  >([]);
  const [
    replacedEquipmentBlockInstanceKeyMap,
    setReplacedEquipmentBlockInstanceKeyMap,
  ] = React.useState<
    {
      from: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
      to: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
    }[]
  >([]);
  const [editMode, setEditMode] = React.useState<SeatAssignmentModeType>(null);

  const [hoveredReservationId, setHoveredReservationId] = React.useState('');

  const [reservationNumber, setReservationNumber] = React.useState<{
    [key: string]: string;
  } | null>(null);

  const [selectedGuestTypeKey, setSelectedGuestTypeKey] = React.useState('');

  const [messageModalProps, setMessageModalProps] = React.useState<{
    onClose: () => void;
    error?: boolean;
    message: string;
    title?: string;
  } | null>(null);

  const [memoModalReservationId, setMemoModalReservationId] =
    React.useState<string>('');

  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );

  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );

  const reservationLoading = useSelector(
    (state: ReduxState) => state.reservations.loading
  );

  const reservationCreating = useSelector(
    (state: ReduxState) => state.reservations.creating
  );

  const assignmentLoading = useSelector(
    (state: ReduxState) => state.equipmentAssignments.loading
  );

  const assignmentUploadLoading = useSelector(
    (state: ReduxState) => state.equipmentAssignments.uploadLoading
  );

  const assignmentError = useSelector(
    (state: ReduxState) => state.equipmentAssignments.error
  );

  const productInstanceLoading = useSelector(
    (state: ReduxState) => state.productInstances.loading
  );

  const selectedEquipmentStartTimeMapping = React.useMemo(() => {
    const equipment = (equipments ?? []).find(
      (equipment) => equipment.id === selectedEquipmentId
    );
    return getEquipmentStartTimeMapping(
      participationDate,
      selectedStartTimeKey,
      equipment
    );
  }, [participationDate, selectedEquipmentId, selectedStartTimeKey]);

  const connectedProductIds = React.useMemo(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return [] as string[];
    }
    return (selectedEquipmentStartTimeMapping?.product_start_times ?? []).map(
      (time) => time.product_id ?? ''
    );
  }, [selectedEquipmentStartTimeMapping]);

  React.useEffect(() => {
    dispatch(fetchEquipments());
    dispatch(fetchProducts());
  }, [activeUserOrganization]);

  React.useEffect(() => {
    const startDate = moment.tz(
      participationDate,
      activeUserOrganization?.default_timezone ?? 'UTC'
    );
    const endDate = startDate.clone().add(1, 'day');
    (connectedProductIds || []).forEach((productId) => {
      dispatch(fetchProductInstances(productId, startDate, endDate));
    });
  }, [connectedProductIds, participationDate]);

  React.useEffect(() => {
    if (!assignmentError) {
      return;
    }
    setMessageModalProps({
      onClose: () => {
        fetchEquipmentAssignmentsHandler();
        setEditMode(null);
      },
      title: t('Failed to change seat assignments'),
      message: t(
        'Failed to change seat assignments. The designated seat may already be in use or seat changes may be prohibited depending on the reservation status."'
      ),
      error: true,
    });
  }, [assignmentError]);

  const selectedProductInstances = React.useMemo(() => {
    if (!selectedEquipmentStartTimeMapping) {
      return [] as ProductInstance[];
    }
    if (!productInstanceById) {
      return [] as ProductInstance[];
    }
    return (
      selectedEquipmentStartTimeMapping?.product_start_times ?? []
    ).reduce((acc: ProductInstance[], time) => {
      const filteredProductInstances = filterProductInstancesByProductMapping(
        time.product_id ?? '',
        time.recurrence_key ?? '',
        time.time_slot_key ?? '',
        participationDate,
        activeUserOrganization?.default_timezone ?? 'UTC',
        Object.values(productInstanceById)
      );
      acc.push(...filteredProductInstances);
      return acc;
    }, [] as ProductInstance[]);
  }, [productInstanceById, selectedEquipmentStartTimeMapping]);

  const filteredReservations = React.useMemo(() => {
    return allReservations
      .filter((reservation) => {
        if (!reservation.product_instance_id) {
          return false;
        }

        const reservationRelatedProductInstances = [
          reservation.product_instance_id,
          ...(reservation.package_component_reservation_summaries?.map(
            (component) => component.product_instance_id ?? ''
          ) ?? []),
        ];

        if (
          selectedProductInstances.some((instance) =>
            reservationRelatedProductInstances.includes(instance.id)
          )
        ) {
          return true;
        }

        return false;
      })
      .filter((reservation) => {
        if (
          ['CONFIRMED', 'REQUESTED', 'STANDBY'].includes(reservation.status)
        ) {
          return true;
        }
        return false;
      });
  }, [allReservations, selectedProductInstances]);

  const fetchEquipmentAssignmentsHandler = async () => {
    if (selectedProductInstances.length === 0) {
      return;
    }
    const productInstanceIds = [
      ...new Set(selectedProductInstances.map((instance) => instance.id)),
    ];
    await dispatch(
      batchFetchEquipmentAssignmentsByProductInstanceIds(productInstanceIds)
    );
  };

  React.useEffect(() => {
    if (connectedProductIds.length === 0) {
      return;
    }

    if (reservationCreating) {
      return;
    }

    dispatch(
      fetchReservations({
        start_date_local_from: participationDate,
        start_date_local_to: moment(participationDate)
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        filter: querystring.stringify({
          product_id: connectedProductIds,
          status: ['CONFIRMED', 'REQUESTED', 'STANDBY'],
        }),
        should_return_equipment_block_references: 'false',
      })
    );
    setSelectedEquipmentBlockInstanceKeys([]);
  }, [connectedProductIds, participationDate, reservationCreating]);

  React.useEffect(() => {
    if (assignmentUploadLoading) {
      return;
    }
    if (equipmentInstanceUpdateLoading) {
      return;
    }
    if (reservationCreating) {
      return;
    }
    if (!selectedEquipmentId) {
      return;
    }
    if (!participationDate) {
      return;
    }
    if (!activeUserOrganization) {
      return;
    }

    const startDate = participationDate;
    const endDate = moment
      .tz(participationDate, activeUserOrganization?.default_timezone ?? 'UTC')
      .add(1, 'day')
      .format('YYYY-MM-DD');

    dispatch(
      fetchEquipmentInstances('', selectedEquipmentId, startDate, endDate)
    );
  }, [
    selectedEquipmentId,
    participationDate,
    activeUserOrganization,
    reservationCreating,
    assignmentUploadLoading,
    equipmentInstanceUpdateLoading,
  ]);

  React.useEffect(() => {
    const equipmentInstance = (equipmentInstances ?? []).find(
      (instance) =>
        instance.equipment_id === selectedEquipmentId &&
        instance.date === participationDate &&
        instance.start_time_key === selectedStartTimeKey
    );
    if (!equipmentInstance) {
      return;
    }
    setSelectedEquipmentInstanceId(equipmentInstance.id ?? '');
  }, [
    equipmentInstances,
    selectedEquipmentId,
    participationDate,
    selectedStartTimeKey,
  ]);

  React.useEffect(() => {
    if (equipmentAssignmentLoading) {
      return;
    }
    if (reservationCreating) {
      return;
    }
    if (selectedProductInstances.length === 0) {
      return;
    }
    fetchEquipmentAssignmentsHandler();
  }, [reservationCreating, selectedProductInstances]);

  const filteredReservationShapes = React.useMemo(() => {
    return filteredReservations
      .map((filteredReservations) =>
        toReservationSummaryShape(filteredReservations, locale, t)
      )
      .sort((a, b) => {
        if (a.booked_at.isSameOrAfter(b.booked_at)) {
          return 1;
        }
        return -1;
      });
  }, [filteredReservations, locale]);

  React.useEffect(() => {
    const newReservationNumber: { [key: string]: string } = {};
    filteredReservationShapes.forEach((reservation, idx) => {
      newReservationNumber[reservation.id] = (idx + 1).toString();
    });
    setReservationNumber(newReservationNumber);
  }, [filteredReservationShapes]);

  const selectedReservation = React.useMemo(() => {
    return allReservations.find(
      (reservation) => reservation.id === selectedReservationId
    );
  }, [allReservations, selectedReservationId]);

  React.useEffect(() => {
    const assignedMap: { [key: string]: number } = {};
    equipmentAssignments.forEach((assignment) => {
      if (assignment.reservation_id === selectedReservation?.id) {
        assignment.occupation?.guest_type_counts?.forEach((count) => {
          assignedMap[count.guest_type_key ?? ''] =
            (assignedMap[count.guest_type_key ?? ''] ?? 0) + (count.count ?? 0);
        });
      }
    });

    const unitMap: {
      [key: string]: { title: string; count: number; assignedCount: number };
    } = {};
    selectedReservation?.guests.forEach((guest) => {
      const unit = guest.guest_type_key || '';
      if (unit) {
        unitMap[unit] = {
          title: guest.guest_type_title || '',
          count: (unitMap[unit]?.count || 0) + 1,
          assignedCount: assignedMap[unit] ?? 0,
        };
      }
    });

    if (unitMap && Object.keys(unitMap).length > 0) {
      if (!selectedGuestTypeKey) {
        const firstKey = Object.keys(unitMap)[0];
        setSelectedGuestTypeKey(firstKey);
      } else if (
        unitMap[selectedGuestTypeKey]?.count <=
        unitMap[selectedGuestTypeKey]?.assignedCount
      ) {
        const firstKey = Object.keys(unitMap).find(
          (key) => unitMap[key]?.count > unitMap[key]?.assignedCount
        );
        if (firstKey) {
          setSelectedGuestTypeKey(firstKey);
        }
      }
    }
  }, [selectedReservation, equipmentAssignments]);

  const resetHandler = () => {
    setEditMode(null);
    setSelectedEquipmentBlockInstanceKeys([]);
    setReplacedEquipmentBlockInstanceKeyMap([]);
    setSelectedReservationId('');
    setDraggingReservationId('');
  };

  return (
    <SeatContext.Provider
      value={{
        selectedEquipmentBlockInstanceKeys,
        setSelectedEquipmentBlockInstanceKeys,
        selectedAssignableEquipmentInstanceId,
        setSelectedAssignableEquipmentInstanceId,
      }}
    >
      <SeatAssignmentContext.Provider value={{ editMode, setEditMode }}>
        <div className={styles['seatsBody']}>
          <div className={styles['seatsBody__sub']}>
            <SeatAssignmentSelector
              participationDate={participationDate}
              onParticipationDateChange={(date: string) => {
                setParticipationDate(date);
                resetHandler();
              }}
              selectedEquipmentId={selectedEquipmentId}
              onSelectedEquipmentIdChange={(equipmentId: string) => {
                setSelectedEquipmentId(equipmentId);
                resetHandler();
              }}
              selectedStartTimeKey={selectedStartTimeKey}
              onSelectedStartTimeKeyChange={(startTimeKey: string) => {
                setSelectedStartTimeKey(startTimeKey);
                resetHandler();
              }}
            />
            <SeatAssignmentReservations
              filteredReservations={filteredReservations ?? []}
              selectedReservationId={selectedReservationId}
              selectedEquipmentInstanceId={selectedEquipmentInstanceId}
              onSelectedReservationIdChange={(reservationId: string) => {
                resetHandler();
                if (reservationId) {
                  setEditMode('ASSIGN');
                } else {
                  setEditMode(null);
                }
                setSelectedReservationId(reservationId);
                setSelectedEquipmentBlockInstanceKeys([]);
              }}
              onReservationDragStart={(reservationId: string) => {
                resetHandler();
                setEditMode('ASSIGN');
                setDraggingReservationId(reservationId);
                setSelectedEquipmentBlockInstanceKeys([]);
              }}
              hoveredReservationId={hoveredReservationId}
              reservationNumber={reservationNumber}
              filteredReservationShapes={filteredReservationShapes}
              onHoveredReservationIdChange={setHoveredReservationId}
              setMemoReservationId={setMemoModalReservationId}
            />
          </div>

          <div className={styles['seatsBody__main']}>
            <SeatAssignmentMap
              filteredReservations={filteredReservations}
              selectedEquipmentInstanceId={selectedEquipmentInstanceId}
              selectedReservationId={selectedReservationId}
              draggingReservationId={draggingReservationId}
              hoveredReservationId={hoveredReservationId}
              replacedEquipmentBlockInstanceKeyMap={
                replacedEquipmentBlockInstanceKeyMap
              }
              onReplacedEquipmentBlockInstanceKeyMapChange={
                setReplacedEquipmentBlockInstanceKeyMap
              }
              onHoveredReservationIdChange={setHoveredReservationId}
              onReset={resetHandler}
              reservationNumber={reservationNumber}
              onReservationDragEnd={setDraggingReservationId}
              selectedGuestTypeKey={selectedGuestTypeKey}
              onFetchReservationClick={async () => {
                if (reservationCreating) {
                  return;
                }
                const startDate = participationDate;
                const endDate = moment(participationDate)
                  .add(1, 'day')
                  .format('YYYY-MM-DD');
                await Promise.all([
                  fetchEquipmentAssignmentsHandler(),
                  dispatch(
                    fetchReservations({
                      start_date_local_from: startDate,
                      start_date_local_to: endDate,
                      filter: querystring.stringify({
                        product_id: (
                          selectedEquipmentStartTimeMapping?.product_start_times ??
                          []
                        ).map((time) => time.product_id),
                      }),
                      should_return_equipment_block_references: 'false',
                    })
                  ),
                  dispatch(
                    fetchEquipmentInstances(
                      '',
                      selectedEquipmentId,
                      startDate,
                      endDate
                    )
                  ),
                ]);
              }}
              fetchReservationLoading={reservationLoading || assignmentLoading}
              selectedEquipmentId={selectedEquipmentId}
              selectedProductInstances={selectedProductInstances}
              fetchEquipmentAssignments={fetchEquipmentAssignmentsHandler}
            />
          </div>
          <Loader
            active={
              reservationLoading ||
              assignmentLoading ||
              productInstanceLoading ||
              equipmentInstanceUpdateLoading
            }
          />
        </div>
        <SeatAssignmentNotificationList />
        {messageModalProps && (
          <MessageModal
            open={true}
            onClose={() => {
              messageModalProps?.onClose();
              setMessageModalProps(null);
            }}
            title={messageModalProps?.title}
            message={messageModalProps?.message}
            error={messageModalProps?.error}
          />
        )}
        {memoModalReservationId !== '' && (
          <InternalMemoModal
            open={true}
            onClose={() => {
              setMemoModalReservationId('');
            }}
            message={
              filteredReservations.find((r) => r?.id === memoModalReservationId)
                ?.supplier_internal_notes_for_dispatch ?? ''
            }
          />
        )}
      </SeatAssignmentContext.Provider>
    </SeatContext.Provider>
  );
};
