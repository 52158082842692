import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Button } from 'client/components/v3/Common/Button';
import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { Checkbox } from 'client/components/v3/Form/Checkbox';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { productOptionsSelector } from 'client/reducers/products';
import { getBookingStatusOptions } from 'client/libraries/util/getBookingStatusOptions';
import { ReservationStatus } from 'shared/models/swagger';

import styles from './SystemFeeInvoiceDetails.module.css';
import { FilterReservationsRequest, getPaymentTypeOptions } from './util';

type Props = {
  onReset: () => void;
  onSearch: () => void;
  filterCondition: FilterReservationsRequest;
  setFilterCondition: (arg0: FilterReservationsRequest) => void;
};
export const FilterBlock = ({
  onReset,
  onSearch,
  filterCondition,
  setFilterCondition,
}: Props) => {
  const { t } = useTranslation();

  const org = useSelector(activeUserOrganizationSelector);

  const showDepositDate = org?.stripe_accounts?.some(
    (acct) => acct.account_type === 'JAPAN_EXPRESS'
  );

  const productOptions = useSelector(productOptionsSelector).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const statusOptions = getBookingStatusOptions(t).map((option) => {
    return {
      value: option.value,
      text: option.text,
    };
  });
  const paymentTypeOptions = getPaymentTypeOptions(t);

  const changeProductId = (value: string[]) => {
    setFilterCondition({ ...filterCondition, productIds: value });
  };

  const changeStatus = (value: string[]) => {
    setFilterCondition({
      ...filterCondition,
      reservationStatuses: value as ReservationStatus[],
    });
  };

  const changePaymentTypes = (value: string[]) => {
    setFilterCondition({
      ...filterCondition,
      paymentTypes: value as string[],
    });
  };

  const transferDateChangeHandler = (dates: [string | null, string | null]) => {
    const [startDate, endDate] = dates;
    setFilterCondition({
      ...filterCondition,
      transferDateFrom: startDate || '',
      transferDateTo: endDate || '',
    });
  };

  return (
    <div className={styles['p-box']} style={{ alignItems: 'center' }}>
      <div className={styles['p-box__input']}>
        <MultiDropdown
          searchable={true}
          options={productOptions}
          selectedOptions={filterCondition.productIds}
          onChange={changeProductId as any}
          placeholder={t('Product')}
        />
      </div>
      <div className={styles['p-box__input']}>
        <MultiDropdown
          searchable={true}
          options={statusOptions}
          selectedOptions={filterCondition.reservationStatuses}
          onChange={changeStatus as any}
          placeholder={t('Booking Status')}
        />
      </div>
      <div style={{ display: 'flex' }}>
        {paymentTypeOptions.map((option) => (
          <Checkbox
            key={option.value}
            label={option.text}
            checked={filterCondition.paymentTypes.includes(option.value)}
            onChange={() => {
              filterCondition.paymentTypes.includes(option.value)
                ? changePaymentTypes(
                    filterCondition.paymentTypes.filter(
                      (p) => p !== option.value
                    )
                  )
                : changePaymentTypes([
                    ...filterCondition.paymentTypes,
                    option.value,
                  ]);
            }}
          />
        ))}
      </div>
      {showDepositDate && (
        <div>
          <DateRangeInput
            label={t('Deposit Date')}
            dateFrom={filterCondition.transferDateFrom}
            dateTo={filterCondition.transferDateTo}
            onChange={transferDateChangeHandler}
          />
        </div>
      )}
      <Button
        text={t('Clear')}
        color="white"
        size="sm"
        onClick={() => onReset()}
      />
      <Button
        text={t('Filter')}
        color="primary"
        size="sm"
        onClick={() => onSearch()}
      />
    </div>
  );
};
