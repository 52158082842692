import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { config } from 'client/config';
import { Box } from 'client/components/Box/Box';
import { equipmentInstancesSelector } from 'client/reducers/equipmentInstances';
import { equipmentsSelector } from 'client/reducers/equipments';
import { equipmentAssignmentsSelector } from 'client/reducers/equipmentAssignments';
import { ZoomablePane } from 'client/components/ZoomablePane/ZoomablePane';
import type { ReduxState } from 'client/reducers';
import {
  Equipment,
  Reservation,
  EquipmentAssignment,
} from 'shared/models/swagger';
import reloadIcon from 'client/images/ic_reload.svg';
import pencilIcon from 'client/images/ic_pencil.svg';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { printETicketByEpsonThermalPrinter } from 'client/libraries/util/printETicketByEpsonThermalPrinter';
import {
  markReservationEquipmentTicketPrinted,
  undoCheckinReservation,
  undoEquipmentTicketPrint,
} from 'client/actions/reservations';
import { deleteEquipmentAssignment } from 'client/actions/equipmentAssignments';
import { useTicketNetworkPrinterIpAddress } from 'client/hooks/useTicketNetworkPrinterIpAddress';
import { updateEquipmentInstance } from 'client/actions/equipmentInstances';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLogoBase64 } from 'client/libraries/util/getLogoBase64';
import { SeatContext } from 'client/contexts/SeatContext';

import styles from './SeatAssignment.module.css';
import {
  getCellBlockMapping,
  getEquipmentCellReservationIdMapping,
  getEquipmentInstanceGuestCount,
  getAssignableEquipmentInstances,
  getAssignableEquipmentInstancesTotalCapacity,
  getAssignableEquipmentInstancesConsumedCapacity,
  getConsumedDirectWebEquipmentBlockCount,
} from './utils';
import { EditEquipmentInstanceModal } from './EditEquipmentInstanceModal';
import { AssignedSeatHoverPopupWindow } from './AssignedSeatHoverPopupWindow';
import { AssignedSeatSelectPopupWindow } from './AssignedSeatSelectPopupWindow';
import { UnAssignedSeatSelectPopupWindow } from './UnAssignedSeatSelectPopupWindow';
import { UnAssignedSeatHoverPopupWindow } from './UnAssignedSeatHoverPopupWindow';
import { SeatAssignmentMapCell } from './SeatAssignmentMapCell';
import { SeatMapHeader } from './SeatMapHeader';
import { EditEquipmentInstanceChannelModal } from './EditEquipmentInstanceChannelModal';
import { EquipmentInstanceMemoModal } from './EquipmentInstanceMemoModal';

interface Props {
  selectedEquipmentInstanceId: string;
  hoveredReservationId?: string;
  onHoveredReservationIdChange?: (reservationId: string) => void;
  replacedEquipmentBlockInstanceKeyMap?: {
    from: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
    to: {
      equipmentInstanceId: string;
      equipmentBlockInstanceKey: string;
    };
  }[];
  onReplacedEquipmentBlockInstanceKeyMapChange?: (
    arg: {
      from: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
      to: {
        equipmentInstanceId: string;
        equipmentBlockInstanceKey: string;
      };
    }[]
  ) => void;
  onBulkOperationClick?: () => void;
  onReset?: () => void;

  onNewReservationClick?: () => void;
  onEditPropertiesClick?: () => void;
  selectedReservationId?: string;
  draggingReservationId?: string;
  onReservationDragEnd?: (reservationId: string) => void;
  selectedGuestTypeKey?: string;
  reservationNumber?: { [key: string]: string } | null;
  filteredReservations?: Reservation[];
  showOnlySelectedEquipmentInstanceInSelector?: boolean;
  filteredEquipmentAssignments?: EquipmentAssignment[];
  participationDate?: string;
  selectedEquipmentId?: string;
  onFetchReservationClick?: () => void;
  fetchReservationLoading?: boolean;
  positionOffset?: { top: number; left: number };
  keepShowingEquipmentInstanceSelector?: boolean;
  hideEquipmentInstanceCloseButton?: boolean;
  hideEquipmentInstanceEditButton?: boolean;
  showInModal?: boolean;
}

export const SeatMap = ({
  selectedEquipmentInstanceId,
  hoveredReservationId,
  onHoveredReservationIdChange,
  replacedEquipmentBlockInstanceKeyMap,
  onReplacedEquipmentBlockInstanceKeyMapChange,
  onBulkOperationClick,
  onReset,
  onNewReservationClick,
  onEditPropertiesClick,
  selectedReservationId,
  draggingReservationId,
  onReservationDragEnd,
  selectedGuestTypeKey,
  reservationNumber,
  filteredReservations,
  showOnlySelectedEquipmentInstanceInSelector,
  filteredEquipmentAssignments,
  onFetchReservationClick,
  positionOffset,
  keepShowingEquipmentInstanceSelector,
  hideEquipmentInstanceCloseButton,
  hideEquipmentInstanceEditButton,
  showInModal,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const equipments = useSelector(equipmentsSelector);
  const equipmentAssignments = useSelector(equipmentAssignmentsSelector);
  const equipmentInstances = useSelector(equipmentInstancesSelector);
  const { networkPrinterIpAddress } = useTicketNetworkPrinterIpAddress();

  const {
    selectedEquipmentBlockInstanceKeys,
    selectedAssignableEquipmentInstanceId,
    setSelectedAssignableEquipmentInstanceId,
  } = React.useContext(SeatContext);

  const equipmentLoading = useSelector(
    (state: ReduxState) => state.equipments.loading
  );
  const equipmentInstanceLoading = useSelector(
    (state: ReduxState) => state.equipmentInstances.loading
  );

  const allReservations = useSelector(
    (state: ReduxState) => state.reservations.summaries
  );
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  const [showEditEquipmentInstanceModal, setShowEditEquipmentInstanceModal] =
    React.useState(false);
  const [popupPosition, setPopupPosition] = React.useState<{
    left: number;
    top: number;
  } | null>(null);
  const [
    showAssignedSeatHoverPopupWindow,
    setShowAssignedSeatHoverPopupWindow,
  ] = React.useState(false);
  const [
    showUnAssignedSeatHoverPopupWindow,
    setShowUnAssignedSeatHoverPopupWindow,
  ] = React.useState(false);
  const [
    showAssignedSeatSelectPopupWindow,
    setShowAssignedSeatSelectPopupWindow,
  ] = React.useState(false);
  const [
    showUnAssignedSeatSelectPopupWindow,
    setShowUnAssignedSeatSelectPopupWindow,
  ] = React.useState(false);
  const [
    hoveredEquipmentBlockInstanceKey,
    setHoveredEquipmentBlockInstanceKey,
  ] = React.useState<{
    equipmentInstanceId: string;
    equipmentBlockInstanceKey: string;
  } | null>(null);

  const [selectedReservationIdOnSeatMap, setSelectedReservationIdOnSeatMap] =
    React.useState<string | null>(null);
  const [
    showEditEquipmentInstanceChannelModal,
    setShowEditEquipmentInstanceChannelModal,
  ] = React.useState(false);
  const [showEquipmentInstanceMemoModal, setShowEquipmentInstanceMemoModal] =
    React.useState(false);

  const ticketingCountRef = React.useRef<HTMLDivElement | null>(null);

  const selectedReservationEquipmentInstanceMemo = React.useMemo(() => {
    const targetEquipmentAssignments = equipmentAssignments?.filter(
      (e) => e.reservation_id === selectedReservationIdOnSeatMap
    );
    if (targetEquipmentAssignments?.length) {
      return (
        equipmentInstances?.find(
          (e) => e.id === targetEquipmentAssignments[0].equipment_instance_id
        )?.memo?.value ?? ''
      );
    } else {
      return '';
    }
  }, [
    selectedReservationIdOnSeatMap,
    equipmentAssignments,
    equipmentInstances,
  ]);

  const selectedEquipmentInstance = React.useMemo(() => {
    return equipmentInstances.find(
      (equipmentInstance) =>
        equipmentInstance.id === selectedEquipmentInstanceId
    );
  }, [equipmentInstances, selectedEquipmentInstanceId]);

  const selectedAssignableEquipmentInstance = React.useMemo(() => {
    if (!selectedAssignableEquipmentInstanceId) {
      return undefined;
    }
    return equipmentInstances.find(
      (equipmentInstance) =>
        equipmentInstance.id === selectedAssignableEquipmentInstanceId
    );
  }, [equipmentInstances, selectedAssignableEquipmentInstanceId]);

  const selectedAssignableEquipment = React.useMemo(() => {
    if (!selectedAssignableEquipmentInstance) {
      return undefined;
    }
    return equipments.find(
      (equipment) =>
        equipment.id === selectedAssignableEquipmentInstance.equipment_id
    );
  }, [equipments, selectedAssignableEquipmentInstance]);

  const assignableEquipmentInstances = React.useMemo(() => {
    return getAssignableEquipmentInstances(
      selectedEquipmentInstanceId,
      equipmentInstances
    );
  }, [selectedEquipmentInstanceId, equipmentInstances]);

  const totalCapacity = getAssignableEquipmentInstancesTotalCapacity(
    selectedEquipmentInstanceId,
    equipmentInstances
  );

  const consumedCapacity = getAssignableEquipmentInstancesConsumedCapacity(
    selectedEquipmentInstanceId,
    equipmentInstances
  );

  const webTotalCapacity = assignableEquipmentInstances.reduce((acc, cur) => {
    if (cur?.additional_equipment_settings?.close_original_equipment) {
      return acc;
    }
    return (
      acc +
      (cur?.total_booking_source_equipment_block_instance_counts?.reduce(
        (acc, cur) => {
          if (cur?.booking_source?.source_type === 'DIRECT_WEB') {
            return acc + (cur?.count ?? 0);
          }
          return acc;
        },
        0
      ) ?? 0)
    );
  }, 0);

  const webConsumedCapacity = assignableEquipmentInstances.reduce(
    (acc, cur) => {
      return (
        acc +
        getConsumedDirectWebEquipmentBlockCount(cur, activeUserOrganization)
      );
    },
    0
  );

  const columns = selectedAssignableEquipment?.column_count || 0;
  const rows = selectedAssignableEquipment?.row_count || 0;

  const cellWidth = 60;
  const cellHeight = 60;

  const layoutWidth = columns * cellWidth;
  const layoutHeight = rows * cellHeight;

  const seatOpenCloseHandler = (close: boolean) => {
    const equipmentBlockInstanceKeyPerEquipmentInstanceId: {
      [key: string]: string[];
    } = {};

    selectedEquipmentBlockInstanceKeys.forEach((key) => {
      if (
        !equipmentBlockInstanceKeyPerEquipmentInstanceId[
          key.equipmentInstanceId
        ]
      ) {
        equipmentBlockInstanceKeyPerEquipmentInstanceId[
          key.equipmentInstanceId
        ] = [];
      }
      equipmentBlockInstanceKeyPerEquipmentInstanceId[
        key.equipmentInstanceId
      ].push(key.equipmentBlockInstanceKey);
    });

    Object.keys(equipmentBlockInstanceKeyPerEquipmentInstanceId).forEach(
      (equipmentInstanceId) => {
        const selectedEquipmentInstance = equipmentInstances.find(
          (instance) => instance.id === equipmentInstanceId
        );
        if (!selectedEquipmentInstance) {
          return;
        }
        const selectedEquipmentBlockInstanceKeys =
          equipmentBlockInstanceKeyPerEquipmentInstanceId[equipmentInstanceId];

        const newProperties =
          selectedEquipmentInstance.equipment_block_instance_properties?.map(
            (property) => {
              if (
                !selectedEquipmentBlockInstanceKeys.includes(
                  property.equipment_block_instance_key ?? ''
                )
              ) {
                return property;
              }
              return {
                ...property,
                override_is_closed: true,
                is_closed: close,
              };
            }
          ) ?? [];

        selectedEquipmentBlockInstanceKeys.forEach((key) => {
          if (
            newProperties
              .map((p) => p.equipment_block_instance_key)
              .includes(key)
          ) {
            return;
          }
          newProperties.push({
            equipment_block_instance_key: key,
            override_is_closed: true,
            is_closed: close,
          });
        });

        dispatch(
          updateEquipmentInstance({
            id: equipmentInstanceId,
            equipment_block_instance_properties: newProperties,
          })
        );
      }
    );
  };

  const renderLayout = (equipment: Equipment) => {
    const cellReservationIdMapping = getEquipmentCellReservationIdMapping(
      equipment,
      filteredEquipmentAssignments
    );

    const placedEquipmentBlockInstanceKeys: string[] = [];
    const layout: any[] = [];
    for (let i = 0; i < (equipment?.row_count ?? 0); i++) {
      for (let j = 0; j < (equipment?.column_count ?? 0); j++) {
        const cellProps = getCellBlockMapping(i, j, equipment);

        let columnWidth = 1;
        let rowWidth = 1;
        if (cellProps && cellProps?.equipment_block_instance_key) {
          if (
            placedEquipmentBlockInstanceKeys.includes(
              cellProps?.equipment_block_instance_key ?? ''
            )
          ) {
            continue;
          }
          placedEquipmentBlockInstanceKeys.push(
            cellProps?.equipment_block_instance_key ?? ''
          );

          for (
            ;
            columnWidth + j < (equipment.column_count ?? 0);
            columnWidth++
          ) {
            const nextCellProps = getCellBlockMapping(
              i,
              j + columnWidth,
              equipment
            );

            if (
              nextCellProps &&
              nextCellProps?.equipment_block_instance_key ===
                cellProps?.equipment_block_instance_key
            ) {
              continue;
            } else {
              break;
            }
          }

          for (; rowWidth + i < (equipment.row_count ?? 0); rowWidth++) {
            const nextCellProps = getCellBlockMapping(
              i + rowWidth,
              j,
              equipment
            );

            if (
              nextCellProps &&
              nextCellProps.equipment_block_instance_key ===
                cellProps?.equipment_block_instance_key
            ) {
              continue;
            } else {
              break;
            }
          }
        }

        layout.push(
          <SeatAssignmentMapCell
            column={j}
            row={i}
            equipmentBlockInstanceKey={
              cellProps?.equipment_block_instance_key ?? ''
            }
            filteredEquipmentAssignments={filteredEquipmentAssignments}
            cellReservationIdMapping={cellReservationIdMapping}
            selectedEquipment={selectedAssignableEquipment}
            hoveredReservationId={hoveredReservationId ?? ''}
            setHoveredReservationId={onHoveredReservationIdChange}
            selectedReservationId={selectedReservationId ?? ''}
            draggingReservationId={draggingReservationId ?? ''}
            onReservationDragEnd={onReservationDragEnd}
            selectedGuestTypeKey={selectedGuestTypeKey ?? ''}
            participationDate={selectedEquipmentInstance?.date ?? ''}
            replacedEquipmentBlockInstanceKeyMap={
              replacedEquipmentBlockInstanceKeyMap
            }
            onReplacedEquipmentBlockInstanceKeyMapChange={
              onReplacedEquipmentBlockInstanceKeyMapChange
            }
            selectedEquipmentInstance={selectedAssignableEquipmentInstance}
            reservationNumber={reservationNumber ?? {}}
            filteredReservations={filteredReservations}
            setPopupPosition={setPopupPosition}
            setShowAssignedSeatHoverPopupWindow={
              setShowAssignedSeatHoverPopupWindow
            }
            setShowAssignedSeatSelectPopupWindow={
              setShowAssignedSeatSelectPopupWindow
            }
            setShowUnAssignedSeatSelectPopupWindow={
              setShowUnAssignedSeatSelectPopupWindow
            }
            setShowUnAssignedSeatHoverPopupWindow={
              setShowUnAssignedSeatHoverPopupWindow
            }
            columnWidth={columnWidth}
            rowWidth={rowWidth}
            onHoveredEquipmentBlockInstanceKeyChange={
              setHoveredEquipmentBlockInstanceKey
            }
            onSelectedReservationIdOnSeatMapChange={
              setSelectedReservationIdOnSeatMap
            }
            key={`${i}-${j}`}
          />
        );
      }
    }
    return layout;
  };

  return (
    <>
      <SeatMapHeader
        selectedEquipmentInstanceId={selectedEquipmentInstanceId}
        selectedAssignableEquipmentInstanceId={
          selectedAssignableEquipmentInstanceId
        }
        filteredEquipmentAssignments={filteredEquipmentAssignments}
        selectedEquipmentBlockInstanceKeys={selectedEquipmentBlockInstanceKeys}
        selectedReservationId={selectedReservationId}
        draggingReservationId={draggingReservationId}
        onEditEquipmentInstanceButtonClick={() =>
          setShowEditEquipmentInstanceModal(true)
        }
        onAssignEquipmentInstanceIdChange={
          setSelectedAssignableEquipmentInstanceId
        }
        onNewReservationClick={onNewReservationClick}
        onReset={onReset}
        equipmentLoading={equipmentLoading}
        equipmentInstanceLoading={equipmentInstanceLoading}
        showOnlySelectedEquipmentInstanceInSelector={
          showOnlySelectedEquipmentInstanceInSelector
        }
        setShowEditEquipmentInstanceChannelModal={
          setShowEditEquipmentInstanceChannelModal
        }
        keepShowingEquipmentInstanceSelector={
          keepShowingEquipmentInstanceSelector
        }
        hideEquipmentInstanceCloseButton={hideEquipmentInstanceCloseButton}
        hideEquipmentInstanceEditButton={hideEquipmentInstanceEditButton}
      />

      {selectedAssignableEquipmentInstance?.additional_equipment_settings
        ?.close_original_equipment ? (
        <Box mb={2} mt={2} ml={2}>
          こちらのリソースは利用できません
        </Box>
      ) : (
        <ZoomablePane>
          {({
            targetRef,
            wrapperRef,
            onZoomInClick,
            onZoomOutClick,
            onReseatScale,
          }) => {
            React.useEffect(() => {
              onReseatScale?.();
            }, [selectedAssignableEquipmentInstanceId]);

            React.useEffect(() => {
              const handleOutsideClick = ({ target }: Event) => {
                if (
                  target instanceof Node &&
                  !targetRef?.current?.contains(target) &&
                  wrapperRef?.current?.contains(target)
                ) {
                  onReset?.();
                }
              };

              window.document.addEventListener(
                'mousedown',
                handleOutsideClick,
                {
                  capture: true,
                }
              );
              window.document.addEventListener(
                'touchstart',
                handleOutsideClick,
                {
                  capture: true,
                }
              );
              return () => {
                window.document.removeEventListener(
                  'mousedown',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
                window.document.removeEventListener(
                  'touchstart',
                  handleOutsideClick,
                  {
                    capture: true,
                  }
                );
              };
            }, []);

            return (
              <div className={styles['seatsAction']}>
                <div className={clsx(styles['seatsAction__info'])}>
                  {assignableEquipmentInstances.map((instance, idx) => {
                    return (
                      <div
                        className={clsx(styles['seatsAction__info__box'])}
                        key={idx}
                        ref={
                          instance.id === selectedEquipmentInstanceId
                            ? ticketingCountRef
                            : null
                        }
                      >
                        <p>
                          {
                            equipments.find(
                              (equipment) =>
                                equipment.id === instance.equipment_id
                            )?.title
                          }
                        </p>
                        <div>
                          <span className={clsx(styles['green'])}>
                            {getEquipmentInstanceGuestCount(
                              instance?.id ?? '',
                              filteredEquipmentAssignments
                            )}
                          </span>
                          /
                          {instance?.total_equipment_block_instance_count
                            ?.value ?? 0}
                        </div>
                      </div>
                    );
                  })}
                  <div className={clsx(styles['seatsAction__info__box'])}>
                    <p>{t('Remaining')}</p>
                    <div>
                      <span className={clsx(styles['red'])}>
                        {totalCapacity - consumedCapacity}
                      </span>
                      /{totalCapacity}
                    </div>
                  </div>
                  <div className={clsx(styles['seatsAction__info__box'])}>
                    <p>{t('Web')}</p>
                    <div>
                      <span className={clsx(styles['red'])}>
                        {webTotalCapacity - webConsumedCapacity}
                      </span>
                      /{webTotalCapacity}
                    </div>
                  </div>
                </div>
                <div
                  style={{ left: '70px' }}
                  className={clsx(styles['seatsAction__info'])}
                >
                  {assignableEquipmentInstances.map((instance, idx) => {
                    if (instance.id === selectedEquipmentInstanceId) {
                      let ticketingCount = 0;
                      filteredReservations?.forEach((r) => {
                        const printed = Boolean(r?.equipment_ticket_printed);
                        const checkedIn = Boolean(
                          r?.checkin_info?.checkin_records?.length ?? 0
                        );

                        const printedGuestCount = printed
                          ? r?.guests.length
                          : 0;
                        const checkedInGuestCount = checkedIn
                          ? r?.checkin_info?.checkin_records?.reduce(
                              (acc, cur) =>
                                (((cur?.guest_type_counts || []).length > 0
                                  ? cur?.guest_type_counts?.reduce(
                                      (acc2, cur2) => (cur2?.count ?? 0) + acc2,
                                      0
                                    )
                                  : cur?.guest_count) || 0) + acc,
                              0
                            )
                          : 0;

                        if (printed) {
                          ticketingCount += printedGuestCount;
                        } else if (checkedIn) {
                          ticketingCount += checkedInGuestCount ?? 0;
                        }
                      });
                      return (
                        <div
                          className={clsx(styles['seatsAction__info__box'])}
                          key={idx}
                          style={{
                            height:
                              ticketingCountRef?.current?.clientHeight ??
                              'auto',
                            wordBreak: 'break-all',
                          }}
                        >
                          <p>{t('Redeemed')}</p>
                          <div>
                            <span className={clsx(styles['green'])}>
                              {ticketingCount}
                            </span>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>

                <div
                  className={clsx(
                    styles['seatsAction__btns'],
                    styles['reload']
                  )}
                >
                  <p
                    onClick={() => {
                      onFetchReservationClick?.();
                    }}
                  >
                    <img src={reloadIcon} alt="reload" />
                  </p>
                  <a
                    onClick={() => {
                      onZoomInClick?.();
                    }}
                  >
                    +
                  </a>
                  <a
                    onClick={() => {
                      onZoomOutClick?.();
                    }}
                  >
                    -
                  </a>
                  <p
                    onClick={() => {
                      setShowEquipmentInstanceMemoModal(true);
                    }}
                  >
                    <img src={pencilIcon} alt="edit" />
                  </p>
                </div>

                <div
                  className={clsx(
                    styles['seatsAction__cell'],
                    showInModal ? styles['modal'] : null
                  )}
                >
                  <AssignedSeatHoverPopupWindow
                    reservation={allReservations.find(
                      (reservation) => reservation.id === hoveredReservationId
                    )}
                    position={{
                      top:
                        (popupPosition?.top ?? 0) - (positionOffset?.top ?? 0),
                      left:
                        (popupPosition?.left ?? 0) -
                        (positionOffset?.left ?? 0),
                    }}
                    onSeatMap={true}
                    open={showAssignedSeatHoverPopupWindow}
                    hoveredEquipmentBlockInstanceKey={
                      hoveredEquipmentBlockInstanceKey
                    }
                  />
                  <UnAssignedSeatHoverPopupWindow
                    position={{
                      top:
                        (popupPosition?.top ?? 0) - (positionOffset?.top ?? 0),
                      left:
                        (popupPosition?.left ?? 0) -
                        (positionOffset?.left ?? 0),
                    }}
                    onSeatMap={true}
                    open={showUnAssignedSeatHoverPopupWindow}
                    hoveredEquipmentBlockInstanceKey={
                      hoveredEquipmentBlockInstanceKey
                    }
                  />
                  {replacedEquipmentBlockInstanceKeyMap &&
                    onReplacedEquipmentBlockInstanceKeyMapChange &&
                    onBulkOperationClick &&
                    onReset && (
                      <AssignedSeatSelectPopupWindow
                        replacedEquipmentBlockInstanceKeyMap={
                          replacedEquipmentBlockInstanceKeyMap
                        }
                        onBulkOperationClick={onBulkOperationClick}
                        position={{
                          top:
                            (popupPosition?.top ?? 0) -
                            (positionOffset?.top ?? 0),
                          left:
                            (popupPosition?.left ?? 0) -
                            (positionOffset?.left ?? 0),
                        }}
                        onReset={onReset}
                        onSeatMap={true}
                        open={showAssignedSeatSelectPopupWindow}
                        onClose={() => {
                          setShowAssignedSeatSelectPopupWindow(false);
                        }}
                        onPrintTicketClick={() => {
                          if (!selectedReservationIdOnSeatMap) {
                            return;
                          }
                          const reservation = filteredReservations?.find(
                            (reservation) =>
                              reservation.id === selectedReservationIdOnSeatMap
                          );
                          if (!reservation) {
                            return;
                          }
                          const blockReference =
                            equipmentAssignments
                              ?.filter(
                                (e) => e.reservation_id === reservation.id
                              )
                              ?.map((e) => e.equipment_block_reference)
                              ?.sort()
                              ?.join('/') ?? '';
                          const reservationSummaryShape =
                            toReservationSummaryShape(
                              reservation,
                              i18n.language,
                              t
                            );
                          printETicketByEpsonThermalPrinter(
                            reservationSummaryShape,
                            networkPrinterIpAddress,
                            activeUserOrganization?.booking_widget_api_key,
                            getLogoBase64(activeUserOrganization?.id ?? ''),
                            blockReference,
                            selectedReservationEquipmentInstanceMemo
                          );
                          dispatch(
                            markReservationEquipmentTicketPrinted(
                              selectedReservationIdOnSeatMap
                            )
                          );
                        }}
                        onClearRedemptionRecordClick={async () => {
                          if (!selectedReservationIdOnSeatMap) {
                            return;
                          }
                          await dispatch(
                            undoCheckinReservation(
                              selectedReservationIdOnSeatMap,
                              {}
                            )
                          );
                          if (config.enableUndoEqauipmentTicketPrint) {
                            await dispatch(
                              undoEquipmentTicketPrint(
                                selectedReservationIdOnSeatMap
                              )
                            );
                          }
                        }}
                        onClearSeatClick={() => {
                          if (!selectedReservationIdOnSeatMap) {
                            return;
                          }
                          filteredEquipmentAssignments
                            ?.filter(
                              (equipmentAssignment) =>
                                equipmentAssignment.reservation_id ===
                                selectedReservationIdOnSeatMap
                            )
                            .forEach((equipmentAssignment) => {
                              dispatch(
                                deleteEquipmentAssignment(
                                  equipmentAssignment.id ?? ''
                                )
                              );
                            });
                        }}
                      />
                    )}
                  {onNewReservationClick && onEditPropertiesClick && (
                    <UnAssignedSeatSelectPopupWindow
                      selectedEquipmentBlockInstanceKeys={
                        selectedEquipmentBlockInstanceKeys
                      }
                      onNewReservationClick={onNewReservationClick}
                      onEditPropertiesClick={onEditPropertiesClick}
                      position={{
                        top:
                          (popupPosition?.top ?? 0) -
                          (positionOffset?.top ?? 0),
                        left:
                          (popupPosition?.left ?? 0) -
                          (positionOffset?.left ?? 0),
                      }}
                      onReset={onReset}
                      onSeatMap={true}
                      open={
                        showUnAssignedSeatSelectPopupWindow &&
                        selectedEquipmentBlockInstanceKeys.length !== 0
                      }
                      onClose={() => {
                        setShowUnAssignedSeatSelectPopupWindow(false);
                      }}
                      onSeatCloseClick={async () => {
                        seatOpenCloseHandler(true);
                      }}
                      onSeatOpenClick={async () => {
                        seatOpenCloseHandler(false);
                      }}
                    />
                  )}
                  <div className={styles['content']} ref={wrapperRef}>
                    <ul
                      ref={targetRef}
                      style={{
                        gridTemplateColumns: `repeat(${columns}, ${cellWidth}px)`,
                        gridTemplateRows: `repeat(${rows}, ${cellHeight}px)`,
                        width: layoutWidth,
                        height: layoutHeight,
                      }}
                      onMouseLeave={() => {
                        setShowUnAssignedSeatHoverPopupWindow(false);
                        setShowAssignedSeatHoverPopupWindow(false);
                      }}
                    >
                      {renderLayout(selectedAssignableEquipment ?? {})}
                    </ul>
                  </div>
                </div>
              </div>
            );
          }}
        </ZoomablePane>
      )}
      <EditEquipmentInstanceChannelModal
        open={showEditEquipmentInstanceChannelModal}
        onClose={() => {
          setShowEditEquipmentInstanceChannelModal(false);
        }}
        equipmentInstance={selectedEquipmentInstance}
      />
      <EditEquipmentInstanceModal
        open={showEditEquipmentInstanceModal}
        onClose={() => {
          setShowEditEquipmentInstanceModal(false);
        }}
        equipmentInstance={selectedEquipmentInstance}
      />
      <EquipmentInstanceMemoModal
        open={showEquipmentInstanceMemoModal}
        onClose={() => {
          setShowEquipmentInstanceMemoModal(false);
        }}
        equipmentInstance={selectedAssignableEquipmentInstance}
      />
    </>
  );
};
