import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { activeUserOrganizationSelector } from 'client/reducers/user';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { Editor } from 'client/components/Editor/Editor';
import styles from 'client/pages/v3/BookingWidget/BookingWidgetTopPage/BookingWidgetTopPage.module.css';

import { TopPageFormValues } from '../FormValues';
import type { CustomIntroductionSection } from '../FormValues';

type Props = {
  name: string;
};
export const EditCustomIntroductionSection = ({ name }: Props) => {
  const { t } = useTranslation();
  const activeUserOrganization = useSelector(activeUserOrganizationSelector);
  const form = useForm<TopPageFormValues>();
  const formState = useFormState<TopPageFormValues>();
  const initialValues: CustomIntroductionSection = _.get(
    formState.values,
    name
  );

  return (
    <>
      <div className={styles['p-topPage-products__section__body__item']}>
        <Toggle
          label={t('Show custom introduction on booking website top page')}
          checked={initialValues.isVisible}
          onChange={() => {
            form.change(`${name}.isVisible` as any, !initialValues.isVisible);
          }}
        />
      </div>
      <div className={styles['p-topPage-products__section__body__item']}>
        <Editor
          imageS3Prefix={`editor/supplier/${activeUserOrganization?.id || ''}`}
          data={initialValues.customIntroductionEditorJSObject}
          onChange={(data: any) => {
            form.change(
              `${name}.customIntroductionEditorJSObject` as any,
              data
            );
          }}
          style={{
            borderRadius: 'var(--radius-xl)',
            border: '1px solid var(--gray200)',
            marginLeft: '40px',
            minHeight: '450px',
          }}
        />
      </div>
    </>
  );
};
