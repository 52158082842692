import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { config } from 'client/config';
import { ImageVideoAudioInput } from 'client/components/v3/ImageVideoAudioInput/ImageVideoAudioInput';
import { Box } from 'client/components/Box/Box';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Toggle } from 'client/components/v3/Form/Toggle';
import { ReduxState } from 'client/reducers';
import { Message } from 'client/components/Message/Message';
import baseStyles from 'client/base.module.css';
import { getArrayMutators } from 'client/libraries/util/form';
import { updateDigitalMap } from 'client/actions/digitalMaps';
import { FieldWrapper } from 'client/components/v3/Form/FieldWrapper';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { getBookingWidgetPmpSupportedLanguageOptions } from 'client/libraries/util/getBookingWidgetPmpSupportLanguages';
import { activeUserOrganizationSelector } from 'client/reducers/user';
import { getLanguageName } from 'client/libraries/i18n';
import { Checkbox } from 'client/components/v3/Form/Checkbox';

import styles from './DigitalMapSEOEditor.module.css';
import {
  convertSwaggerToFormValues,
  convertFormValuesToSwagger,
  FormValues,
  defaultInitialValues,
} from './formValues';

export const DigitalMapSEOEditor = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const digitalMaps = useSelector((state: ReduxState) => state.digitalMaps.all);
  const org = useSelector(activeUserOrganizationSelector);

  const languageOptions = getBookingWidgetPmpSupportedLanguageOptions(org);

  const existingDigitalMap = useSelector((state: ReduxState) =>
    state.digitalMaps.all.find((n) => n.id === id)
  );

  const initialValues = React.useMemo(() => {
    return existingDigitalMap
      ? convertSwaggerToFormValues(existingDigitalMap)
      : defaultInitialValues;
  }, [existingDigitalMap]);

  return (
    <Form
      onSubmit={async (values: FormValues) => {
        await dispatch(
          updateDigitalMap(id, convertFormValuesToSwagger(values))
        );
      }}
      initialValues={initialValues}
      debug={console.log}
      mutators={getArrayMutators()}
    >
      {({
        handleSubmit,
        submitting,
        submitSucceeded,
        submitError,
        modifiedSinceLastSubmit,
        values,
        form,
      }) => (
        <div className={baseStyles['base-main__body__box']}>
          <div className={baseStyles['base-main__body__box__body']}>
            <form onSubmit={handleSubmit}>
              {submitSucceeded && !modifiedSinceLastSubmit && (
                <Message success header={t('Save Successful')} />
              )}
              {submitError && !modifiedSinceLastSubmit && (
                <Message error header={t('Save Failed')} />
              )}

              <div className={styles['fields']}>
                <Field name="seoParams.title">
                  {({ input }) => (
                    <TextField {...input} label={t('Website Title')} />
                  )}
                </Field>
                <Field name="seoParams.metaDescription">
                  {({ input }) => (
                    <TextField {...input} label={t('Meta Description')} />
                  )}
                </Field>
                <Field name="seoParams.thumbnailUrl">
                  {({ input }) => (
                    <>
                      <FieldWrapper label={t('Thumbnail URL (og:image)')} />
                      <ImageVideoAudioInput
                        fileUrls={input.value ? [input.value] : []}
                        onChange={(newValue) =>
                          newValue?.length
                            ? input.onChange(newValue[0])
                            : input.onChange('')
                        }
                        maxFileCount={1}
                        disableYoutubeVideos
                      />
                    </>
                  )}
                </Field>
                <div className={styles['dns-instructions']}>
                  <div>
                    {t(
                      'Set up an A record with your DNS provider. If using a root domain:'
                    )}
                  </div>
                  <div>
                    <p>{`@    A    ${config.bookingWidgetIpAddress}`}</p>
                  </div>
                  <div>
                    <div>{t('If using a subdomain:')}</div>
                  </div>
                  <div>
                    <div>{`<subdomain>    A    ${config.bookingWidgetIpAddress}`}</div>
                  </div>
                  <div>
                    <a
                      className="c-button-link-sm-color"
                      href="https://moz.com/learn/seo/domain"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <p style={{ fontWeight: 'var(--text-semibold)' }}>
                        {t('What are subdomains and root domains?')}
                      </p>
                      <i className="c-icon-outline-general-link-external-02"></i>
                    </a>
                  </div>
                </div>
                <Field name="seoParams.customDomain">
                  {({ input }) => (
                    <TextField {...input} label={t('Custom Domain')} />
                  )}
                </Field>
                <Field name="seoParams.language">
                  {({ input }) => (
                    <SingleDropdown
                      selectedOption={input.value}
                      options={languageOptions}
                      onChange={(newValue) => input.onChange(newValue)}
                      label={t('Language')}
                    />
                  )}
                </Field>
                {config.enableMapLanguageMenuSettings &&
                  values.seoParams?.language && (
                    <Field name="showLanguageMenu">
                      {({ input }) => (
                        <Box mt={2}>
                          <Toggle
                            checked={input.value}
                            onChange={(newValue) => {
                              if (
                                newValue &&
                                !values.languageMenuItems.length
                              ) {
                                form.change('languageMenuItems', [
                                  {
                                    language: values.seoParams.language,
                                    mapId: id,
                                  },
                                ]);
                              }
                              input.onChange(newValue);
                            }}
                            label={t('Show Language Menu')}
                          />
                        </Box>
                      )}
                    </Field>
                  )}

                {values.showLanguageMenu && (
                  <>
                    <div className={styles['language-menu-items-header']}>
                      {t('Language Menu Items')}
                    </div>
                    <table>
                      <tbody>
                        {values.showLanguageMenu &&
                          languageOptions.map((option) => (
                            <tr key={option.value}>
                              <td>
                                <Checkbox
                                  label={getLanguageName(option.value, t)}
                                  disabled={
                                    option.value === values.seoParams.language
                                  }
                                  checked={Boolean(
                                    values.languageMenuItems.find(
                                      (item) => item.language === option.value
                                    )
                                  )}
                                  onChange={() => {
                                    if (
                                      values.languageMenuItems.find(
                                        (item) => item.language === option.value
                                      )
                                    ) {
                                      form.change(
                                        'languageMenuItems',
                                        values.languageMenuItems.filter(
                                          (item) =>
                                            item.language !== option.value
                                        )
                                      );
                                    } else {
                                      form.change('languageMenuItems', [
                                        ...values.languageMenuItems,
                                        {
                                          language: option.value,
                                          mapId: '',
                                        },
                                      ]);
                                    }
                                  }}
                                />
                              </td>
                              <td>
                                {values.languageMenuItems.find(
                                  (item) => item.language === option.value
                                ) && (
                                  <SingleDropdown
                                    selectedOption={
                                      values.languageMenuItems.find(
                                        (item) => item.language === option.value
                                      )?.mapId ?? ''
                                    }
                                    options={digitalMaps.map((item) => ({
                                      value: item.id ?? '',
                                      text: item.name ?? '',
                                    }))}
                                    onChange={(newValue) =>
                                      form.change(
                                        'languageMenuItems',
                                        values.languageMenuItems.map((item) =>
                                          item.language === option.value
                                            ? {
                                                ...item,
                                                mapId: newValue,
                                              }
                                            : item
                                        )
                                      )
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>

              <div className={baseStyles['base-main__box__body__bottomBtns']}>
                <Button
                  type="submit"
                  color="primary"
                  loading={submitting}
                  text={t('Save')}
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </Form>
  );
};
