import { useTranslation } from 'react-i18next';
import { Field, useFormState } from 'react-final-form';
import { range } from 'lodash';
import { FieldArray } from 'react-final-form-arrays';
import { useContext } from 'react';
import clsx from 'clsx';

import styles from 'client/pages/v3/Product/ProductEdit/ProductEdit.module.css';
import { Toggle } from 'client/components/v3/Form/Toggle';
import baseStyles from 'client/v3-base.module.css';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { FormValues } from 'client/pages/ProductDetails/ProductContentsHeader/BookingWidgetSettingsFormValues';
import { DraggableList } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/DraggableList/DraggableList';
import type { Option } from 'client/pages/v3/Product/ProductEdit/ProductEditContents/DraggableList/DraggableList';
import { PARTICIPANT_UNLIMITED } from 'client/constants/participantUnlimited';
import { getGuestTypeKeysUsedInProduct } from 'client/libraries/util/util';
import { EditingProductContext } from 'client/contexts/EditingProductContext';
import { Button } from 'client/components/v3/Common/Button';
import tableStyles from 'client/components/v3/Table/TableSmall.module.css';

export const BookingWidgetSelectableParticipantRulesEditor = () => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const values = formState.values;
  const product = useContext(EditingProductContext);

  let units: string[] = [];
  if (product) {
    units = getGuestTypeKeysUsedInProduct(product);
  }

  const unitOptions = units.map((unit) => {
    return {
      value: unit,
      text: unit,
    };
  });

  const isFormDisabled = (values: FormValues, unit: string): boolean => {
    if (values?.isSelectableParticipantRuleActive) {
      const found = (values?.selectableParticipantRules || []).find((rule) => {
        return (rule?.restrictedUnits || []).find(
          (restrictedUnit) => restrictedUnit === unit
        );
      });

      if (found) {
        return true;
      }
    }

    return false;
  };

  const getDefaultUnit = (values: any): string => {
    if (
      values &&
      values.minimumMaximumParticipantRules &&
      values.minimumMaximumParticipantRules.length > 0
    ) {
      return values.minimumMaximumParticipantRules[0].unit;
    }

    return '';
  };

  return (
    <>
      <div className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Minimum and maximum participants per booking')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name="isPerBookingParticipantRuleActive">
            {({ input }) => (
              <>
                <Toggle
                  label={t(
                    'Set minimum and maximum participants for one booking'
                  )}
                  checked={input.value}
                  onChange={() => input.onChange(!input.value)}
                />
                {input.value && (
                  <div
                    className={styles['c-tableChild']}
                    style={{ marginTop: '16px' }}
                  >
                    <ul>
                      <li className={baseStyles['u-width-160']}>
                        {t('Minimum Selectable Participants')}
                      </li>
                      <li className={baseStyles['u-width-160']}>
                        {t('Maximum Selectable Participants')}
                      </li>
                    </ul>
                    <ul>
                      <li data-title={t('Minimum Selectable Participants')}>
                        <Field name="perBookingParticipantRule.minimumParticipants">
                          {({ input }) => (
                            <SingleDropdown
                              options={range(1, 100).map((x) => ({
                                value: x as any,
                                text: `${x}`,
                              }))}
                              selectedOption={input.value}
                              onChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </li>
                      <li data-title={t('Maximum Selectable Participants')}>
                        <Field name="perBookingParticipantRule.maximumParticipants">
                          {({ input }) => (
                            <SingleDropdown
                              options={range(0, 100).map((x) => ({
                                value: x === 0 ? t('No limit') : (x as any),
                                text: x === 0 ? t('No limit') : `${x}`,
                              }))}
                              selectedOption={
                                input.value === 0 ? t('No limit') : input.value
                              }
                              onChange={(value) => {
                                input.onChange(value);
                              }}
                            />
                          )}
                        </Field>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            )}
          </Field>
        </div>
      </div>
      <div className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Number of selectable participants per unit')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <div>
            <div className={styles['c-tableChild']}>
              <ul>
                <li className={baseStyles['u-width-112']}>{t('Unit')}</li>
                <li className={baseStyles['u-width-160']}>
                  {t('Minimum Selectable Participants')}
                </li>
                <li className={baseStyles['u-width-160']}>
                  {t('Maximum Selectable Participants')}
                </li>
              </ul>
              <FieldArray name="minimumMaximumParticipantRules">
                {({ fields }) =>
                  fields.map((name) => (
                    <Field key={name} name={name}>
                      {({ input: unitInput }) => (
                        <ul>
                          <li data-title={t('Unit')}>
                            {unitInput.value.unit === 'guest'
                              ? t('Guest')
                              : unitInput.value.unit}
                          </li>
                          <li data-title={t('Minimum Selectable Participants')}>
                            <Field name={`${name}.minimumParticipants`}>
                              {({ input }) => (
                                <SingleDropdown
                                  options={range(0, 100).map((x) => ({
                                    value: x as any,
                                    text: `${x}`,
                                  }))}
                                  selectedOption={input.value}
                                  onChange={(value) => {
                                    input.onChange(value);
                                  }}
                                  disabled={isFormDisabled(
                                    values as any,
                                    unitInput.value.unit
                                  )}
                                />
                              )}
                            </Field>
                          </li>
                          <li data-title={t('Maximum Selectable Participants')}>
                            <Field name={`${name}.maximumParticipants`}>
                              {({ input }) => (
                                <SingleDropdown
                                  options={[
                                    {
                                      value: PARTICIPANT_UNLIMITED,
                                      text: t('No limit'),
                                    },
                                    ...range(1, 100).map((x) => ({
                                      value: x as any,
                                      text: `${x}`,
                                    })),
                                  ]}
                                  selectedOption={
                                    input.value === PARTICIPANT_UNLIMITED
                                      ? t('No limit')
                                      : input.value
                                  }
                                  onChange={(value) => {
                                    input.onChange(value);
                                  }}
                                  disabled={isFormDisabled(
                                    values as any,
                                    unitInput.value.unit
                                  )}
                                />
                              )}
                            </Field>
                          </li>
                        </ul>
                      )}
                    </Field>
                  ))
                }
              </FieldArray>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            {t('Participant Rule')}
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name={'isSelectableParticipantRuleActive'}>
            {({ input }) => (
              <>
                <div className={baseStyles['inline-block']}>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                  >
                    <Toggle
                      label={
                        <>
                          {t(
                            'Set maximum selectable participants based on other units'
                          )}
                          <br />
                          {t('Example: maximum of 1 infant per 1 adult')}
                        </>
                      }
                      checked={input.value}
                      onChange={() => input.onChange(!input.value)}
                    />
                  </div>
                </div>
                {input.value && (
                  <FieldArray name="selectableParticipantRules">
                    {({ fields }) => {
                      return (
                        <>
                          <div
                            className={styles['p-selectableParticipantRule']}
                          >
                            <table
                              className={clsx(
                                tableStyles['c-tableSmall'],
                                tableStyles['row']
                              )}
                            >
                              <thead>
                                <tr>
                                  <th className={baseStyles['u-width-208']}>
                                    {t('Required unit')}
                                  </th>
                                  <th>
                                    {t('Restricted unit maximum participants')}{' '}
                                  </th>
                                  <th className={baseStyles['u-width-80']}></th>
                                </tr>
                              </thead>
                              <tbody>
                                {fields.map((name, idx) => (
                                  <tr key={idx}>
                                    <td style={{ verticalAlign: 'top' }}>
                                      <FieldArray
                                        name={`${name}.requiredUnits`}
                                      >
                                        {({ fields }) => {
                                          return (
                                            <>
                                              {(fields || ['']).map(
                                                (name, idx) => (
                                                  <Field name={name} key={idx}>
                                                    {({ input }) => (
                                                      <div
                                                        className={
                                                          styles[
                                                            'p-selectableParticipantRule__requiredUnit'
                                                          ]
                                                        }
                                                      >
                                                        <SingleDropdown
                                                          options={unitOptions}
                                                          selectedOption={
                                                            input.value
                                                          }
                                                          onChange={(value) =>
                                                            input.onChange(
                                                              value
                                                            )
                                                          }
                                                        />
                                                        {idx !== 0 ? (
                                                          <Button
                                                            size="icon"
                                                            color="tertiarygray"
                                                            onClick={() =>
                                                              fields.remove(idx)
                                                            }
                                                            iconBeforeText={
                                                              <i className="c-icon-outline-general-trash-03"></i>
                                                            }
                                                          />
                                                        ) : (
                                                          <Button
                                                            size="icon"
                                                            iconBeforeText={
                                                              <i className="c-icon-outline-general-trash-03"></i>
                                                            }
                                                            style={{
                                                              opacity: 0,
                                                            }}
                                                            disabled={true}
                                                          />
                                                        )}
                                                      </div>
                                                    )}
                                                  </Field>
                                                )
                                              )}
                                              <a
                                                className={
                                                  styles[
                                                    'p-selectableParticipantRule__add'
                                                  ]
                                                }
                                                onClick={() =>
                                                  (fields as any).insertAt(
                                                    idx + 1,
                                                    getDefaultUnit(
                                                      values as any
                                                    )
                                                  )
                                                }
                                              >
                                                <i className="c-icon-outline-general-plus-circle"></i>
                                                {t('Add Required Unit')}
                                              </a>
                                            </>
                                          );
                                        }}
                                      </FieldArray>
                                    </td>
                                    <td style={{ verticalAlign: 'top' }}>
                                      <div
                                        className={
                                          styles[
                                            'p-selectableParticipantRule__restrictedUnit'
                                          ]
                                        }
                                      >
                                        <div>
                                          <FieldArray
                                            name={`${name}.restrictedUnits`}
                                          >
                                            {({ fields }) => {
                                              return (
                                                <>
                                                  {(fields || ['']).map(
                                                    (name, idx) => (
                                                      <Field
                                                        name={name}
                                                        key={idx}
                                                      >
                                                        {({ input }) => (
                                                          <div
                                                            className={clsx(
                                                              styles[
                                                                'p-selectableParticipantRule__requiredUnit'
                                                              ],
                                                              baseStyles[
                                                                'u-width-208'
                                                              ]
                                                            )}
                                                          >
                                                            <SingleDropdown
                                                              options={
                                                                unitOptions
                                                              }
                                                              selectedOption={
                                                                input.value
                                                              }
                                                              onChange={(
                                                                value
                                                              ) => {
                                                                input.onChange(
                                                                  value
                                                                );
                                                              }}
                                                            />

                                                            {idx !== 0 ? (
                                                              <Button
                                                                size="icon"
                                                                color="tertiarygray"
                                                                onClick={() =>
                                                                  fields.remove(
                                                                    idx
                                                                  )
                                                                }
                                                                iconBeforeText={
                                                                  <i className="c-icon-outline-general-trash-03"></i>
                                                                }
                                                              />
                                                            ) : (
                                                              <Button
                                                                size="icon"
                                                                iconBeforeText={
                                                                  <i className="c-icon-outline-general-trash-03"></i>
                                                                }
                                                                style={{
                                                                  opacity: 0,
                                                                }}
                                                                disabled={true}
                                                              />
                                                            )}
                                                          </div>
                                                        )}
                                                      </Field>
                                                    )
                                                  )}
                                                  <a
                                                    className={
                                                      styles[
                                                        'p-selectableParticipantRule__add'
                                                      ]
                                                    }
                                                    onClick={() =>
                                                      (fields as any).insertAt(
                                                        idx + 1,
                                                        getDefaultUnit(
                                                          values as any
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="c-icon-outline-general-plus-circle"></i>
                                                    {t('Add Restricted Unit')}
                                                  </a>
                                                </>
                                              );
                                            }}
                                          </FieldArray>
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: 'auto',
                                          }}
                                        >
                                          <Field
                                            name={`${name}.maximumParticipants`}
                                          >
                                            {({ input }) => (
                                              <SingleDropdown
                                                options={range(0, 100).map(
                                                  (x) => ({
                                                    value: x as any,
                                                    text: `${x}`,
                                                  })
                                                )}
                                                selectedOption={input.value}
                                                onChange={(value) => {
                                                  input.onChange(value);
                                                }}
                                                width={120}
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      {idx !== 0 && (
                                        <Button
                                          size="icon"
                                          color="tertiarygray"
                                          onClick={() => fields.remove(idx)}
                                          iconBeforeText={
                                            <i className="c-icon-outline-general-trash-03"></i>
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <a
                            className={
                              styles['p-selectableParticipantRule__add']
                            }
                            onClick={() => {
                              if (fields.length && fields.length > 0) {
                                (fields as any).insertAt(fields.length + 1, {
                                  requiredUnits: [
                                    getDefaultUnit(values as any),
                                  ],
                                  restrictedUnits: [
                                    getDefaultUnit(values as any),
                                  ],
                                  maximumParticipants: 1,
                                });
                              } else {
                                (fields as any).insertAt(0, {
                                  requiredUnits: [
                                    getDefaultUnit(values as any),
                                  ],
                                  restrictedUnits: [
                                    getDefaultUnit(values as any),
                                  ],
                                  maximumParticipants: 1,
                                });
                              }
                            }}
                          >
                            <i className="c-icon-outline-general-plus-circle"></i>
                            {t('Add Rule')}
                          </a>
                          <div style={{ marginTop: '20px' }}>
                            <ul>
                              {fields.value.map((value, idx) => {
                                return (
                                  <li key={idx} style={{ marginTop: '4px' }}>
                                    {t(
                                      'Rule {{idx}} Maximum of {{max}} {{restrictedUnits}} per 1 {{requiredUnits}}',
                                      {
                                        idx: idx + 1,
                                        max: value.maximumParticipants,
                                        restrictedUnits:
                                          value.restrictedUnits.join('/'),
                                        requiredUnits:
                                          value.requiredUnits.join('/'),
                                      }
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      );
                    }}
                  </FieldArray>
                )}
              </>
            )}
          </Field>
        </div>
      </div>
      <div className={styles['p-list__item']}>
        <div className={styles['p-list__item__ttl']}>
          <div className={styles['p-list__item__ttl__txt']}>
            <div>{t('Default calendar unit')}</div>
          </div>
        </div>
        <div className={styles['p-list__item__body']}>
          <Field name={'calendarUnitOrder'}>
            {({ input }) => (
              <DraggableList
                options={input.value}
                moveItem={(newItem: Option[]) => {
                  input.onChange(newItem);
                }}
              />
            )}
          </Field>
        </div>
      </div>
    </>
  );
};
