import { useTranslation } from 'react-i18next';
import { Field, useForm, useFormState } from 'react-final-form';
import { useState } from 'react';
import _ from 'lodash';

import { Modal } from 'client/components/v3/Form/Modal';
import { Button } from 'client/components/v3/Common/Button';
import { TextField } from 'client/components/v3/Form/TextField';
import { Badge } from 'client/components/v3/Common/Badge';

import styles from './EditTargetModal.module.css';
import { QueryListEditor } from './QueryListEditor';
import { FormValues } from './formValues';

type Props = {
  name: string;
  onClose: () => void;
};

export const EditTargetModal = ({ name, onClose }: Props) => {
  const { t } = useTranslation();
  const formState = useFormState<FormValues>();
  const path = _.get(formState.values, `${name}.path`) as unknown as string;
  const form = useForm();
  const [disableSubmit, setDisableSubmit] = useState(false);

  const noEmptyPathOrLeadingSlash = (
    value: string | null | undefined
  ): string => {
    if (!value) {
      setDisableSubmit(true);
      return '';
    }
    if (value.startsWith('/')) {
      setDisableSubmit(true);
      return t("Please remove the leading '/'");
    }
    setDisableSubmit(false);
    return '';
  };

  return (
    <Modal
      title={t('Edit Target')}
      open={true}
      onClose={onClose}
      rightActionChildren={
        <Button text={t('Set')} onClick={onClose} disabled={disableSubmit} />
      }
      insertAtRoot={true}
      style={{ width: '600px', height: '800px' }}
      onClickOutside={() => {
        form.change(`${name}.path`, path ? path.replace(/^\/+/, '') : path);
      }}
    >
      <div className={styles['p-targetModal']}>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Base URL')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <TextField
              value={formState.values.baseUrl}
              onChange={() => {
                // For display only
              }}
              disabled={true}
            />
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Path')}</p>
            <div>
              <Badge
                size="sm"
                label={t('Required')}
                color="warning-contained"
              />
            </div>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <Field name={`${name}.path`} validate={noEmptyPathOrLeadingSlash}>
              {({ input, meta: { error } }) => {
                return (
                  <TextField
                    value={input.value ?? ''}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                    error={error}
                  />
                );
              }}
            </Field>
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Query')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <QueryListEditor
              name={`${name}.queries`}
              addButtonText={t('Add Query')}
            />
          </div>
        </div>
        <div className={styles['p-targetModal__item']}>
          <div className={styles['p-targetModal__item__ttl']}>
            <p>{t('Hash')}</p>
          </div>
          <div className={styles['p-targetModal__item__body']}>
            <Field name={`${name}.hash`}>
              {({ input, meta: { error, touched } }) => {
                return (
                  <TextField
                    value={input.value}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                    error={touched && error ? error : undefined}
                  />
                );
              }}
            </Field>
          </div>
        </div>
      </div>
    </Modal>
  );
};
