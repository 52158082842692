import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Field,
  FieldRenderProps,
  useForm,
  useFormState,
} from 'react-final-form';

import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { Badge } from 'client/components/v3/Common/Badge';
import { EnumRadioButtonGroup } from 'client/components/v3/EnumRadioButtonGroup/EnumRadioButtonGroup';

import styles from '../SiteControlWidgetEditor.module.css';
import { TargetListEditor } from '../TargetListEditor';
import { FormValues } from '../formValues';

export const WidgetSettings = () => {
  const { t } = useTranslation();
  const { required } = getValidators(t);
  const form = useForm();
  const { values } = useFormState<FormValues>();

  const timingOptions = [
    {
      text: t('Immediately'),
      value: 'IMMEDIATELY',
    },
    {
      text: t('Scroll depth'),
      value: 'SCROLL_DEPTH',
    },
    {
      text: t('Time on page'),
      value: 'TIME_ON_PAGE',
    },
    {
      text: t('Time on website'),
      value: 'TIME_ON_WEBSITE',
    },
  ];

  const mql = window.matchMedia('(max-width: 768px)');

  return (
    <section className={styles['p-section']}>
      <ul className={styles['p-list']}>
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <p>{t('Name')}</p>
            <div>
              <Badge
                size="sm"
                label={t('Required')}
                color="warning-contained"
              />
            </div>
          </div>
          <div className={styles['p-list__item__body']}>
            <Field name="name" validate={required}>
              {({
                input,
                meta: { error, touched },
              }: FieldRenderProps<string>) => (
                <TextField
                  {...input}
                  type="text"
                  error={touched && error ? error : undefined}
                />
              )}
            </Field>
          </div>
        </li>
        <li className={styles['p-list__item']}>
          <div className={styles['p-list__item__ttl']}>
            <p>{t('Status')}</p>
          </div>
          <div className={styles['p-list__item__body']}>
            <EnumRadioButtonGroup
              name="status"
              options={[
                {
                  value: 'DRAFT',
                  label: t('Draft'),
                },
                {
                  value: 'ACTIVE',
                  label: t('Active'),
                },
              ]}
              onChange={(newValue) => {
                form.change('status', newValue as 'DRAFT' | 'ACTIVE');
              }}
            />
          </div>
        </li>
        <li className={clsx(styles['p-list__item'], styles['column'])}>
          <div style={{ marginBottom: '16px' }}>
            <p>{t('Display widget on')}</p>
          </div>
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '16px' }}
          >
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Base URL')}</p>
              <div>
                <Badge
                  size="sm"
                  label={t('Required')}
                  color="warning-contained"
                />
              </div>
            </div>
            <div className={styles['p-list__item__body']}>
              <Field name="baseUrl" validate={required}>
                {({
                  input,
                  meta: { error, touched },
                }: FieldRenderProps<string>) => (
                  <TextField
                    {...input}
                    type="text"
                    error={touched && error ? error : undefined}
                  />
                )}
              </Field>
            </div>
          </div>
          <div
            className={styles['p-list__item__group']}
            style={{ marginBottom: '16px' }}
          >
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Include')}</p>
            </div>
            <div className={styles['p-list__item__body']}>
              <TargetListEditor
                name="includes"
                addButtonText={t('Add URL')}
                fieldDisabled={true}
              />
            </div>
          </div>
          <div className={styles['p-list__item__group']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Exclude')}</p>
            </div>
            <div className={styles['p-list__item__body']}>
              <TargetListEditor
                name="excludes"
                addButtonText={t('Add URL')}
                fieldDisabled={true}
              />
            </div>
          </div>
        </li>
        <li className={clsx(styles['p-list__item'], styles['column'])}>
          <div style={{ marginBottom: '16px' }}>
            <p>{t('Start displaying widget')}</p>
          </div>
          <div className={styles['p-list__item__group']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Timing')}</p>
            </div>
            <div className={styles['p-list__item__body']}>
              <Field name="timing.type">
                {({ input }: FieldRenderProps<string>) => (
                  <SingleDropdown
                    options={timingOptions}
                    selectedOption={input.value ?? 'IMMEDIATELY'}
                    onChange={(value) => {
                      input.onChange(value);
                    }}
                  />
                )}
              </Field>
            </div>
          </div>
        </li>
        {values.timing.type === 'SCROLL_DEPTH' && (
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Scroll Depth')}</p>
            </div>
            <div
              className={styles['p-list__item__body']}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Field name="timing.scroll_percentage">
                {({ input }: FieldRenderProps<string>) => (
                  <div style={{ width: mql.matches ? '50%' : '15%' }}>
                    <TextField {...input} type="number" />
                  </div>
                )}
              </Field>
              <p style={{ marginLeft: '8px' }}>%</p>
            </div>
          </li>
        )}
        {(values.timing.type === 'TIME_ON_PAGE' ||
          values.timing.type === 'TIME_ON_WEBSITE') && (
          <li className={styles['p-list__item']}>
            <div className={styles['p-list__item__ttl']}>
              <p>{t('Delay Time')}</p>
            </div>
            <div
              className={styles['p-list__item__body']}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Field name="timing.delay_time">
                {({ input }: FieldRenderProps<string>) => (
                  <div style={{ width: mql.matches ? '50%' : '15%' }}>
                    <TextField {...input} type="number" />
                  </div>
                )}
              </Field>
              <p style={{ marginLeft: '8px' }}>{t('seconds')}</p>
            </div>
          </li>
        )}
      </ul>
    </section>
  );
};
