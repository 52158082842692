import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Checkbox } from 'client/components/v3/Form/Checkbox';
import type { Channel, OrganizationSummary } from 'shared/models/swagger';

type Props = {
  channels: Channel[];
  onChange: (arg0: Channel[]) => void;
  allAgents: OrganizationSummary[];
};
export const ChannelListInput = ({ channels, onChange, allAgents }: Props) => {
  const { t } = useTranslation();
  const directOnlineChecked = channels.some(
    (channel) => channel.channel_category === 'DIRECT_ONLINE'
  );
  const directOfflineChecked = channels.some(
    (channel) => channel.channel_category === 'DIRECT_OFFLINE'
  );
  const allAgentsChecked = allAgents.every((agent) =>
    channels.some(
      (channel) =>
        channel.channel_category === 'AGENT' && channel.agent_id === agent.id
    )
  );
  return (
    <>
      <div style={{ display: 'flex', marginTop: '10px', marginBottom: '10px' }}>
        <Checkbox
          label={t('Direct (Online)')}
          checked={directOnlineChecked}
          onChange={() =>
            onChange(
              directOnlineChecked
                ? channels.filter(
                    (channel) => channel.channel_category !== 'DIRECT_ONLINE'
                  )
                : [
                    ...channels,
                    {
                      channel_category: 'DIRECT_ONLINE',
                    },
                  ]
            )
          }
          size="sm"
        />
        <Checkbox
          label={t('Direct (Offline)')}
          checked={directOfflineChecked}
          onChange={() =>
            onChange(
              directOfflineChecked
                ? channels.filter(
                    (channel) => channel.channel_category !== 'DIRECT_OFFLINE'
                  )
                : [
                    ...channels,
                    {
                      channel_category: 'DIRECT_OFFLINE',
                    },
                  ]
            )
          }
          size="sm"
        />
      </div>
      <div
        style={{
          borderRadius: 'var(--radius-xl)',
          backgroundColor: 'rgb(255, 255, 255)',
          padding: '10px',
        }}
      >
        <Checkbox
          label={t('All Agents')}
          checked={allAgentsChecked}
          onChange={() => {
            let newChannels = channels.filter(
              (channel) => channel.channel_category !== 'AGENT'
            );

            if (!allAgentsChecked) {
              newChannels = [
                ...newChannels,
                ...allAgents.map((agent) => ({
                  channel_category: 'AGENT',
                  agent_id: agent.id || '',
                })),
              ] as any;
            }

            onChange(newChannels);
          }}
          size="sm"
        />
        <div
          style={{
            width: '100%',
            height: '0px',
            border: '1px solid #E4E4E7',
            flex: 'none',
            order: '1',
            alignSelf: 'stretch',
            flexGrow: '0',
            margin: '10px 0',
          }}
        ></div>
        <div style={{ columnCount: 2 }}>
          {allAgents.map((agent, idx) => {
            const checked = channels.some(
              (channel) =>
                channel.channel_category === 'AGENT' &&
                channel.agent_id === agent.id
            );
            return (
              <div key={idx} style={{ marginBottom: '7px' }}>
                <Checkbox
                  key={agent.id}
                  label={agent.name || ''}
                  checked={checked}
                  onChange={() =>
                    onChange(
                      checked
                        ? channels.filter(
                            (channel) => channel.agent_id !== agent.id
                          )
                        : [
                            ...channels,
                            {
                              channel_category: 'AGENT',
                              agent_id: agent.id,
                            },
                          ]
                    )
                  }
                  size="sm"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
