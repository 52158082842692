import * as React from 'react';
import { useTranslation } from 'react-i18next';

import * as Swagger from 'shared/models/swagger';
import { formattedDuration } from 'client/libraries/util/formattedDuration';

import styles from './Summary.module.css';

interface Props {
  customer: Swagger.AnalyticsCustomer | undefined;
}

export const Summary = ({ customer }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['container-inner']}>
          <div className={styles['stats-section']}>
            <div className={styles['stats-title']}>{t('Total Sessions')}</div>
            <div className={styles['div-5']}>
              {t('{{sessionCount}}', {
                sessionCount: customer?.session_count ?? 0,
              })}
            </div>
          </div>
          <div className={styles['stats-section']}>
            <div className={styles['stats-title']}>{t('Total Page Views')}</div>
            <div className={styles['div-9']}>
              {t('{{pvCount}}', {
                pvCount: customer?.page_view_count ?? 0,
              })}
            </div>
          </div>
          <div className={styles['stats-section']}>
            <div className={styles['stats-title']}>
              {t('Total Time Spent on Site')}
            </div>
            <div className={styles['div-13']}>
              {formattedDuration(customer?.total_time_seconds ?? 0, t)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
