import * as Swagger from 'shared/models/swagger';

export interface FormValues {
  dateRange?: {
    fromDate?: string;
    toDate?: string;
  };
  language: Swagger.SourceLanguage;
  title: string;
  description: string;
  body?: string;
  status: Swagger.InstantWinStatus;
  thumbnailUrl: string;
  drawTriggers: InstantWinDrawTriggerFormValues[];
  distributionType: Swagger.InstantWinDistributionType;
  tiers?: InstantWinTierFormValue[];

  editorJsIsLoading?: boolean;
}

export interface InstantWinDrawTriggerFormValues {
  key: string;
  triggerType: Swagger.InstantWinTriggerType;
  productIds: string[];
  surveyIds: string[];
}

export interface InstantWinTierFormValue {
  key: string;
  title: string;
  description: string;
  body: string;
  thumbnailUrl: string;
  type: Swagger.InstantWinTierType;
  stock: number;
  probability: number;
  productIdForETicket: string;
  promotionId: string;
  giftThumbnailUrl?: string;
  downloadItems?: DownloadItem[];
}

export type DownloadItem = {
  key: string;
  url: string;
  mediaType: string;
  videoDurationSeconds?: number;
  videoFilesizeBytes?: number;
};

export const getInitialValues = (
  instantWin?: Swagger.InstantWin
): FormValues => {
  return {
    dateRange: {
      fromDate: instantWin?.available_date_from || '',
      toDate: instantWin?.available_date_to || '',
    },
    language: instantWin?.language || 'EN_US',
    title: instantWin?.page_design_contents?.title ?? '',
    description: instantWin?.page_design_contents?.description ?? '',
    status: instantWin?.status || 'INSTANT_WIN_INACTIVE',
    thumbnailUrl: instantWin?.page_design_contents?.thumbnail_url ?? '',

    drawTriggers:
      instantWin?.draw_triggers?.map((drawTrigger) => ({
        key: drawTrigger.key || '',
        triggerType:
          drawTrigger.trigger_type ||
          'INSTANT_WIN_TRIGGER_TYPE_RESERVATION_CREATED',
        productIds: drawTrigger.product_ids || [],
        surveyIds: drawTrigger.survey_ids || [],
      })) || [],

    distributionType:
      instantWin?.distribution_type || 'INSTANT_WIN_DISTRIBUTION_TYPE_QUANTITY',

    tiers:
      instantWin?.tiers?.map((tier) => ({
        key: tier.key || '',
        title: tier.title || '',
        description: tier.description || '',
        body: tier.body || '',
        thumbnailUrl: tier.thumbnail_url || '',
        stock: tier.stock || 0,
        type: tier.type || 'INSTANT_WIN_TIER_TYPE_E_TICKET',
        probability: tier.probability || 0,
        productIdForETicket: tier.product_id_for_e_ticket || '',
        promotionId: tier.promotion_id || '',
        giftThumbnailUrl: tier.gift_thumbnail_url || '',
        downloadItems:
          tier?.download_items?.map((downloadItem) => ({
            key: downloadItem?.key ?? '',
            url: downloadItem?.content?.url ?? '',
            mediaType: downloadItem?.content?.type ?? '',
            videoDurationSeconds:
              downloadItem?.content?.video_duration_seconds ?? 0,
            videoFilesizeBytes:
              downloadItem?.content?.video_filesize_bytes ?? 0,
          })) ?? [],
      })) || [],

    editorJsIsLoading: false,
  };
};

export const convertFormValuesToSwagger = (
  values: FormValues
): Swagger.InstantWinParams => {
  return {
    language: values.language,
    page_design_contents: {
      title: values.title,
      description: values.description,
      thumbnail_url: values.thumbnailUrl,
    },
    status: values.status,
    available_date_from: values.dateRange?.fromDate,
    available_date_to: values.dateRange?.toDate,
    distribution_type: values.distributionType,
    draw_triggers: values.drawTriggers?.map((drawTrigger) => ({
      key: drawTrigger.key,
      trigger_type: drawTrigger.triggerType,
      product_ids: drawTrigger.productIds,
      survey_ids: drawTrigger.surveyIds,
    })),
    tiers: values.tiers?.map((tier) => ({
      key: tier.key,
      title: tier.title,
      description: tier.description,
      body: tier.body,
      thumbnail_url: tier.thumbnailUrl,
      stock: Number(tier.stock),
      type: tier.type,
      probability: tier.probability,
      product_id_for_e_ticket: tier.productIdForETicket,
      promotion_id: tier.promotionId,
      gift_thumbnail_url: tier.giftThumbnailUrl,
      download_items: (tier.downloadItems || []).map((downloadItem) => ({
        key: downloadItem.key,
        content: {
          type: downloadItem.url?.endsWith('mp4') ? 'VIDEO' : 'IMAGE',
          url: downloadItem.url,
          video_duration_seconds: downloadItem.videoDurationSeconds,
          video_filesize_bytes: downloadItem.videoFilesizeBytes,
        },
      })),
    })),
  };
};
