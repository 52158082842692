import * as React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import _ from 'lodash';

import {
  searchReservations,
  setLastAnnualPassReservationSearchQuery,
} from 'client/actions/reservationSearch';
import { fetchProducts } from 'client/actions/products';
import { fetchContractedOrganizations } from 'client/actions/organizations';
import {
  annualPassReservationVisibleColumnsSelector,
  annualPassReservationColumnCandidatesSelector,
  annualPassCsvInitialSelectedColumnsSelector,
} from 'client/reducers/annualPassReservationTableControls';
import {
  activeUserIsNutmegAdminSelector,
  activeUserSelector,
  activeUserOrganizationSelector,
} from 'client/reducers/user';
import { toReservationSummaryShape } from 'client/libraries/util/reservationSummaryShape';
import { getReservationTableColumns } from 'client/libraries/util/getReservationTableColumns';
import type { ReduxState } from 'client/reducers';
import type { ReservationListColumn } from 'client/libraries/util/getReservationTableColumns';
import { Button } from 'client/components/Form';
import { operationAllowed } from 'shared/models/access';
import { ReservationSearchQueryModal } from 'client/pages/ReservationSearch/ReservationSearchQueryModal/ReservationSearchQueryModal';
import { ReservationSearchQueryDisplayBox } from 'client/pages/ReservationSearch/ReservationSearchQueryDisplayBox/ReservationSearchQueryDisplayBox';
import { ReservationSearchDownloadCSVModal } from 'client/pages/ReservationSearch/ReservationSearchDownloadCSVModal/ReservationSearchDownloadCSVModal';
import {
  buildSearchReservationsRequest,
  SearchReservationsRequest,
} from 'client/pages/ReservationSearch/util';
import {
  setAnnualPassReservationCurrentPage,
  setAnnualPassReservationRowCount,
  setAnnualPassReservationDefaultVisibleColumns,
  setAnnualPassReservationVisibleColumns,
} from 'client/actions/annualPassReservationTableControls';
import { ReservationSuggestForm } from 'client/components/ReservationSuggestForm/ReservationSuggestForm';
import 'react-table/react-table.css';
import searchIcon from 'client/images/ic_search.svg';
import anotherIcon from 'client/images/ic_another.svg';
import thIcon from 'client/images/ic_th.svg';
import baseStyles from 'client/base.module.css';
import { CommonReservationSearchCustomTable } from 'client/components/CommonReservationSearch/CommonReservationSearchCustomTable/CommonReservationSearchCustomTable';
import { CommonReservationSearchTableSettingsModal } from 'client/components/CommonReservationSearch/CommonReservationSearchTableSettingsModal/CommonReservationSearchTableSettingsModal';

import styles from '../ReservationSearchTable/ReservationSearchTable.module.css';

export const AnnualPassReservationSearchTable = () => {
  const { t } = useTranslation();
  const loading = useSelector(
    (state: ReduxState) => state.reservationSearch.loading
  );
  const csvLoading = useSelector(
    (state: ReduxState) => state.reservationSearch.csvLoading
  );
  const defaultVisibleColumnsLoaded = useSelector(
    (state: ReduxState) =>
      state.annualPassReservationTableControls.defaultVisibleColumnsLoaded
  );

  const dispatch = useDispatch();
  const allReservations = useSelector(
    (state: ReduxState) => state.reservationSearch.all
  );
  const totalSearchHits = useSelector(
    (state: ReduxState) => state.reservationSearch.totalHits
  );
  const productNameOptions = useSelector((state: ReduxState) => [
    ...new Set(state.reservations.summaries.map((r) => r.product_name || '')),
  ]);

  const rowCount = useSelector(
    (state: ReduxState) => state.annualPassReservationTableControls.rowCount
  );
  const currentPage = useSelector(
    (state: ReduxState) => state.annualPassReservationTableControls.currentPage
  );

  const [runSearch, setRunSearch] = React.useState<boolean>(false);

  const [showSearchSettingsModal, setShowSearchSettingsModal] =
    React.useState<boolean>(false);

  const bookingSourceOptions = [
    ...new Set(
      allReservations.map(
        (r) =>
          (r.booking_source &&
            (r.booking_source.agent_name ||
              t(r.booking_source.source_type ?? ''))) ||
          ''
      )
    ),
  ];
  const visibleColumns = useSelector(
    annualPassReservationVisibleColumnsSelector
  );
  const columnCandidates = useSelector(
    annualPassReservationColumnCandidatesSelector
  );
  const isNutmegAdmin = useSelector(activeUserIsNutmegAdminSelector);
  const activeUser = useSelector(activeUserSelector);
  const locale = useSelector(
    (state: ReduxState) => state.language.selected.iso
  );
  const invalidated = useSelector(
    (state: ReduxState) => state.userDataInvalidated
  );
  const initialSelectedColumns = useSelector(
    annualPassCsvInitialSelectedColumnsSelector
  );
  const reservationSummaries = React.useMemo(
    () => allReservations.map((r) => toReservationSummaryShape(r, locale, t)),
    [allReservations, locale, t]
  );

  const activeUserOrganization = useSelector(activeUserOrganizationSelector);

  React.useEffect(() => {
    if (!defaultVisibleColumnsLoaded && activeUserOrganization) {
      const defaultColumns =
        activeUserOrganization?.account_reservation_search_settings
          ?.default_columns ?? [];
      if (defaultColumns.length > 0) {
        dispatch(
          setAnnualPassReservationDefaultVisibleColumns(
            defaultColumns.map((c) => c.toLowerCase())
          )
        );
      }
    }
  }, [dispatch, defaultVisibleColumnsLoaded, activeUserOrganization]);

  const annualPassMode = true;
  const getAllColumns = (): ReservationListColumn[] => {
    return getReservationTableColumns(t, locale, annualPassMode);
  };

  const getColumns = (columnMask: string[]): ReservationListColumn[] => {
    const columns = [
      'edit',
      ...(columnMask.filter((c) => c !== 'edit') as any),
    ].map((c) => allColumns.find((col) => col.id === c) as any);

    return columns;
  };

  const [allColumns, setAllColumns] = React.useState(getAllColumns());
  const [showColumns, setShowColumns] = React.useState(
    getColumns(visibleColumns)
  );

  React.useEffect(() => {
    if (invalidated) {
      setAllColumns(getAllColumns());
    }
  }, [invalidated, locale, productNameOptions, bookingSourceOptions]);
  React.useEffect(() => {
    setShowColumns(getColumns(visibleColumns));
  }, [visibleColumns, allReservations]);
  const lastQuery = useSelector(
    (state: ReduxState) => state.reservationSearch.annualPassLastQuery
  );
  const [searchCondition, setSearchCondition] =
    React.useState<SearchReservationsRequest>(lastQuery);
  // Fetch products for search modal and search display
  React.useEffect(() => {
    dispatch(fetchProducts());
  }, [t, activeUser]);
  React.useEffect(() => {
    search();
  }, [activeUser, rowCount, currentPage]);
  React.useEffect(() => {
    // If user is a supplier, fetch contracted agents for search modal and search display
    if (operationAllowed(activeUser, 'write', 'reservationConfirmation')) {
      dispatch(fetchContractedOrganizations());
    }
  }, [activeUser]);

  React.useEffect(() => {
    if (runSearch) {
      search();
      setRunSearch(false);
    }
  }, [runSearch]);

  const search = () => {
    if (!isNutmegAdmin) {
      let pageToFetch = currentPage;
      if (!_.isEqual(searchCondition, lastQuery)) {
        dispatch(
          setLastAnnualPassReservationSearchQuery(searchCondition as any)
        );
        pageToFetch = 1;
        dispatch(setAnnualPassReservationCurrentPage(pageToFetch));
      }

      dispatch(
        searchReservations(
          buildSearchReservationsRequest({
            ...searchCondition,
            annualPassOnly: true,
          }),
          rowCount,
          rowCount * (pageToFetch - 1)
        )
      );
    }
  };

  const reset = () => {
    setSearchCondition({
      agentReference: '',
      agentIds: [],
      presetKey: '',
      supplierReference: '',
      id: '',
      statuses: [],
      customerGivenName: '',
      customerFamilyName: '',
      customerPhone: '',
      customerEmail: '',
      bookingSourceTypes: [],
      supplierIds: [],
      productIds: [],
      bookedDateFrom: '',
      bookedDateTo: '',
      participationDateFrom: '',
      participationDateTo: '',
      lastUpdatedDateFrom: '',
      lastUpdatedDateTo: '',
      dateFilterPreset: null,
      orderBy: 'last_updated_desc',
      supplierOrAgentReference: '',
      reservationLanguages: [],
      mostRecentEmailBounced: false,
      pickupCheckinLocationName: '',
      waiverCompletionStatuses: [],
      checkinStatuses: [],
      annualPassOnly: true,
      expirationPresetKey: '',
      expirationDateFrom: '',
      expirationDateTo: '',
      expirationDateFilterPreset: null,
      groupIds: [],
      automaticContinuingStatus: null,
      partnershipReservationOnly: false,
    });
  };

  return (
    <>
      <>
        <Dimmer active={loading} inverted>
          <Loader>{t('Loading')}</Loader>
        </Dimmer>
        <div className={clsx(baseStyles['base-main__body__header'])}>
          <div
            className={clsx(
              baseStyles['base-main__body__header__left'],
              baseStyles['spOrder-1']
            )}
          >
            <ReservationSearchQueryModal
              onReset={reset}
              onSearch={() => {
                dispatch(setAnnualPassReservationCurrentPage(1));
                search();
              }}
              searchCondition={searchCondition}
              setSearchCondition={(condition) => setSearchCondition(condition)}
              trigger={
                <Button.Transition
                  content={
                    <>
                      <img src={searchIcon} />
                      {t('Search')}
                    </>
                  }
                />
              }
              title={t('Search Annual Pass')}
              annualPassOnly={true}
            />
            <ReservationSuggestForm />
          </div>
          <div
            className={clsx(
              baseStyles['base-main__body__header__right'],
              baseStyles['spOrder-2'],
              styles['mobile__right__header']
            )}
          >
            <div className={clsx(styles['header_right_other_block'])}>
              <div
                className={clsx(
                  baseStyles['base-main__body__header__right__another'],
                  baseStyles['is-close']
                )}
              >
                <ReservationSearchDownloadCSVModal
                  searchRequest={searchCondition}
                  initialSelectedColumns={initialSelectedColumns}
                  columnCandidates={columnCandidates}
                  trigger={
                    <a
                      className={clsx(
                        baseStyles['base-btn'],
                        csvLoading && baseStyles['loading']
                      )}
                    >
                      <img src={anotherIcon} />
                    </a>
                  }
                />

                <ul>
                  <li>
                    <ReservationSearchDownloadCSVModal
                      searchRequest={searchCondition}
                      initialSelectedColumns={initialSelectedColumns}
                      columnCandidates={columnCandidates}
                      trigger={
                        <Button.Sub
                          loading={csvLoading}
                          content={<>{t('Download CSV')}</>}
                        />
                      }
                    />
                  </li>
                </ul>
              </div>

              <a
                className={clsx(
                  baseStyles['base-btn'],
                  baseStyles['square'],
                  baseStyles['gray']
                )}
                onClick={() => setShowSearchSettingsModal(true)}
              >
                <img src={thIcon} />
              </a>
            </div>
          </div>
        </div>
        <ReservationSearchQueryDisplayBox searchFilters={lastQuery} />

        <CommonReservationSearchCustomTable
          reservations={reservationSummaries}
          totalHits={totalSearchHits}
          visibleColumns={showColumns}
          rowCount={rowCount}
          currentPage={currentPage}
          onRowCountChange={(rowCount) => {
            dispatch(setAnnualPassReservationRowCount(rowCount));
            dispatch(setAnnualPassReservationCurrentPage(1));
          }}
          onCurrentPageChange={(currentPage) => {
            dispatch(setAnnualPassReservationCurrentPage(currentPage));
          }}
        />

        <CommonReservationSearchTableSettingsModal
          open={showSearchSettingsModal}
          onClose={() => setShowSearchSettingsModal(false)}
          columnCandidates={columnCandidates}
          visibleColumns={visibleColumns}
          onVisibleColumnsChange={(columns) => {
            dispatch(setAnnualPassReservationVisibleColumns(columns));
          }}
        />
      </>
    </>
  );
};
