import { TFunction, useTranslation } from 'react-i18next';
import React from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';

import rightArrowIcon from 'client/images/journeyanalytics/ic_right_arrow.svg';
import { ReduxState } from 'client/reducers';
import { ProductSummary } from 'shared/models/swagger';

import styles from './ConversionPathView.module.css';

type Props = {
  path: string | undefined;
};

export const ConversionPathView = ({ path }: Props) => {
  const { t } = useTranslation();
  const allProducts = useSelector(
    (state: ReduxState) => state.products.summaries
  );

  if (!path) {
    return <></>;
  }
  const separatedPath = path.split(',');

  return (
    <div className={styles['c-view']}>
      {separatedPath.map((item, index) => (
        <React.Fragment key={index}>
          {getBadge(item, t, allProducts)}
          {index < separatedPath.length - 1 && (
            <div>
              <img src={rightArrowIcon} />
            </div>
          )}{' '}
          {/* Render arrow if not the last item */}
        </React.Fragment>
      ))}
    </div>
  );
};

const getBadge = (
  page: string,
  t: TFunction,
  allProducts: ProductSummary[]
) => {
  let label = '';
  let color = '';
  switch (page) {
    case 'TOP':
      label = t('TOP');
      color = 'blue';
      break;
    case 'PRODUCT_LIST':
      label = t('Product List');
      color = 'yellow';
      break;
    case 'CHECKOUT':
      label = t('Reservation Form');
      color = 'red';
      break;
    case 'THANKS':
      label = t('Thanks');
      color = 'purple';
      break;
    case 'LP':
      label = t('LP');
      color = 'pink';
      break;
    case 'POPULAR':
      label = t('Popularity ranking');
      color = 'sky';
      break;
    case 'RECOMMENDED':
      label = t('Recommended');
      color = 'sky';
      break;
    case 'VISITED':
      label = t('Visited');
      color = 'orange';
      break;
    case 'E_TICKET':
      label = t('E-ticket');
      color = 'gray';
      break;
    case 'MAPS':
      label = t('Maps');
      color = 'gray';
      break;
    case 'GUIDANCE':
      label = t('Digital Guidance');
      color = 'gray';
      break;
    case 'RESERVATION_DETAILS':
      label = t('Reservation Details');
      color = 'gray';
      break;
    case 'SIGN_IN':
      label = t('Login / My Page');
      color = 'gray';
      break;
    case 'MYPAGE':
      label = t('Login / My Page');
      color = 'gray';
      break;
    case 'OTHER':
      label = t('Other', { context: 'conversionPath' });
      color = 'gray';
      break;
    default:
      if (/^PRODUCT_/.test(page)) {
        const productId = page.split('_')[1];
        const product = allProducts.find((product) => product.id === productId);
        if (!product) {
          label = productId;
        } else {
          // label = product?.product_name ?? '';
          label =
            product?.product_name && product?.product_name?.length > 10
              ? `${product.product_name.substring(0, 10)}...`
              : product?.product_name ?? '';
        }
        color = 'green';
      } else {
        label = t(page);
        color = 'gray';
      }
  }

  return <div className={clsx(styles['c-badge'], styles[color])}>{label}</div>;
};
