import _ from 'lodash';
import { Field, useForm } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { MultiDropdown } from 'client/components/v3/Form/Dropdown/MultiDropdown';
import { SingleDropdown } from 'client/components/v3/Form/Dropdown/SingleDropdown';
import { DateRangeInput } from 'client/components/v3/Form/Calendar/DateRangeInput';
import { TextField } from 'client/components/v3/Form/TextField';
import { getValidators } from 'shared/libraries/validate/validator';
import { ReduxState } from 'client/reducers';

import { AnalyticsCustomerSearchConditionGroup } from './formValues';
import styles from './ConditionGroupEditor.module.css';

interface Props {
  name: string;
}

export const ConditionGroupEditor = ({ name }: Props) => {
  const { t } = useTranslation();

  const settings = useSelector(
    (reduxState: ReduxState) => reduxState.supplierSettings.journeyAnalytics
  );

  const autotagOptions =
    settings?.autotags?.map((tag) => ({
      value: tag.tag_name ?? '',
      text: tag.tag_name ?? '',
    })) ?? [];

  const { required } = getValidators(t);

  const form = useForm();
  const values: AnalyticsCustomerSearchConditionGroup = _.get(
    form.getState().values,
    name
  );

  const referralPathOptions = [
    {
      text: 'Google',
      value: 'google',
    },
    {
      text: 'Facebook',
      value: 'facebook',
    },
    {
      text: 'Instagram',
      value: 'instagram',
    },
    {
      text: 'X(Twitter)',
      value: 'twitter',
    },
    {
      text: 'Bing',
      value: 'bing',
    },
    {
      text: 'Yahoo',
      value: 'yahoo',
    },
  ];
  const eventTypeOptions = [
    {
      text: t('Page View'),
      value: 'page-view',
    },
    {
      text: t('Session Start'),
      value: 'session-start',
    },
    {
      text: t('Check Availability'),
      value: 'check-availability',
    },
    {
      text: t('Add-to-Cart'),
      value: 'begin-checkout',
    },
    {
      text: t('Conversion'),
      value: 'reservation-thank-you',
    },
  ];
  const dateRangePresetOptions = [
    {
      text: t(''),
      value: '',
    },
    {
      text: t('Last 24 Hours'),
      value: 'LAST_24_HOURS',
    },
    {
      text: t('Last 1 Week'),
      value: 'LAST_1_WEEK',
    },
    {
      text: t('Last 4 Weeks'),
      value: 'LAST_4_WEEKS',
    },
    {
      text: t('Custom'),
      value: 'CUSTOM',
    },
  ];
  const dimensionTypeOptions = [
    {
      text: t('None'),
      value: 'NONE',
    },
    {
      text: t('Page Views: X or more '),
      value: 'PAGE_VIEW_COUNT_GTE',
    },
    {
      text: t('Page Views: Less than X'),
      value: 'PAGE_VIEW_COUNT_LT',
    },
    {
      text: t('Page Views: Between X and Y'),
      value: 'PAGE_VIEW_COUNT_BETWEEN',
    },
    {
      text: t('Sessions: X or more'),
      value: 'SESSION_COUNT_GTE',
    },
    {
      text: t('Sessions: Less than X'),
      value: 'SESSION_COUNT_LT',
    },
    {
      text: t('Sessions: Between X and Y'),
      value: 'SESSION_COUNT_BETWEEN',
    },
    {
      text: t('Time Spent: X or more'),
      value: 'TOTAL_TIME_SECONDS_GTE',
    },
    {
      text: t('Time Spent: Less than X'),
      value: 'TOTAL_TIME_SECONDS_LT',
    },
    {
      text: t('Time Spent: Between X and Y'),
      value: 'TOTAL_TIME_SECONDS_BETWEEN',
    },
  ];

  return (
    <div className={styles['params-container']}>
      <Field name={`${name}.dateRangePreset`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={dateRangePresetOptions}
            label={t('Time Period')}
          />
        )}
      </Field>
      {values.dateRangePreset === 'CUSTOM' && (
        <Field name={`${name}.dateRange`}>
          {({ input }) => (
            <DateRangeInput
              label={t('Custom Time Period')}
              dateFrom={input.value.startDate}
              dateTo={input.value.endDate}
              onChange={(newValue) => {
                console.log('new value', newValue);

                input.onChange({
                  startDate: newValue[0],
                  endDate: newValue[1],
                });
              }}
            />
          )}
        </Field>
      )}
      <Field name={`${name}.referralPaths`}>
        {({ input }) => (
          <MultiDropdown
            label={t('Landing Source')}
            selectedOptions={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={referralPathOptions}
          />
        )}
      </Field>
      <Field name={`${name}.eventTypes`}>
        {({ input }) => (
          <MultiDropdown
            label={t('Log Events')}
            selectedOptions={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={eventTypeOptions}
          />
        )}
      </Field>
      <Field name={`${name}.url`}>
        {({ input }) => (
          <TextField
            label={t('URL')}
            value={input.value}
            onChange={(newValue) => input.onChange(newValue)}
          />
        )}
      </Field>
      <Field name={`${name}.tags`}>
        {({ input }) => (
          <MultiDropdown
            label={t('Tags')}
            selectedOptions={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={autotagOptions}
          />
        )}
      </Field>
      <Field name={`${name}.dimensionType`}>
        {({ input }) => (
          <SingleDropdown
            selectedOption={input.value}
            onChange={(newValue) => input.onChange(newValue)}
            options={dimensionTypeOptions}
            label={t('Dimension')}
            renderOption={(option) => {
              return (
                <>
                  {[
                    'PAGE_VIEW_COUNT_GTE',
                    'PAGE_VIEW_COUNT_LT',
                    'PAGE_VIEW_COUNT_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Page Views')}
                    </div>
                  )}
                  {[
                    'SESSION_COUNT_GTE',
                    'SESSION_COUNT_LT',
                    'SESSION_COUNT_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Sessions')}
                    </div>
                  )}
                  {[
                    'TOTAL_TIME_SECONDS_GTE',
                    'TOTAL_TIME_SECONDS_LT',
                    'TOTAL_TIME_SECONDS_BETWEEN',
                  ].includes(option.value) && (
                    <div className={styles['dimension-option-category']}>
                      {t('Total Time Spent')}
                    </div>
                  )}
                  {[
                    'PAGE_VIEW_COUNT_GTE',
                    'SESSION_COUNT_GTE',
                    'TOTAL_TIME_SECONDS_GTE',
                  ].includes(option.value) && <div>{t('X or more')}</div>}
                  {[
                    'PAGE_VIEW_COUNT_LT',
                    'SESSION_COUNT_LT',
                    'TOTAL_TIME_SECONDS_LT',
                  ].includes(option.value) && <div>{t('Less than X')}</div>}
                  {[
                    'PAGE_VIEW_COUNT_BETWEEN',
                    'SESSION_COUNT_BETWEEN',
                    'TOTAL_TIME_SECONDS_BETWEEN',
                  ].includes(option.value) && <div>{t('Between X and Y')}</div>}

                  {option.value === 'NONE' && <div>{t('None')}</div>}
                </>
              );
            }}
          />
        )}
      </Field>
      {values.dimensionType === 'PAGE_VIEW_COUNT_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'PAGE_VIEW_COUNT_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'PAGE_VIEW_COUNT_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of page views is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'SESSION_COUNT_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Number of sessions is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_GTE' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('or more')}</div>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_LT' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is less than')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
      {values.dimensionType === 'TOTAL_TIME_SECONDS_BETWEEN' && (
        <div className={styles['dimension-container']}>
          <div>{t('Total time spent in seconds is between')}</div>
          <div className={styles['between-inputs-container']}>
            <Field
              name={`${name}.dimensionComparisonValue.lowerBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
            <div>{t('and')}</div>
            <Field
              name={`${name}.dimensionComparisonValue.upperBound`}
              validate={required}
            >
              {({ input, meta: { touched, error } }) => (
                <TextField
                  value={input.value}
                  onChange={input.onChange}
                  type="number"
                  error={touched && error ? error : null}
                />
              )}
            </Field>
          </div>
        </div>
      )}
    </div>
  );
};
